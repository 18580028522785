import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IStore } from 'types';
import {
  AP_FRAN_MODAL_NAMES,
  AP_CONTENT_ACTION_TYPE,
  AP_ACTION_TYPES,
  RIPPLE_COLOR,
  ONE_MINUTE_INTERVAL_DELAY,
  VALID_BRAND_CATEGORY_REGEX,
  SETTINGS_CUSTOMER_PERSONA_USAGE_LEVELS,
  USER_OWNERSHIP,
  ComplianceType
} from 'utils/constants';
import {
  getReqPayloadForUpdateContent,
  getLinkDataReqPayload,
  getPreviewBrandHubCreatorActions,
  getHumanReadableTime,
  getCurrentDate,
  atLeastApproved,
  splitTextFromHashAndEmoji,
  generateCustomerPersonaFieldData
} from 'utils/helpers';
import { useAIFeatureAccess, useAccountSwitcherData, useAllowAccess, useSiteRestriction } from 'utils/hooks';
import {
  addPostUpdateLinkRequest,
  addPostUpdateContentRequest,
  addPostMassUpdateForScheduledPostRequest,
  addPostSetContentSavedDate,
  setCreatorModalName,
  aiValidateCaptionRequest,
  setAIPostContentOpen,
  setAITextContent,
  getAIContentCreatorRequest
} from 'actions';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { AddOrSearchCampaignModal } from 'components/Common';

export const FranchisorCreatePostFilter = () => {
  const dispatch = useDispatch();

  const { id, userOwnership } = useAccountSwitcherData();
  const { franchisorFullAccess } = useAllowAccess();
  const { creatorRestriction } = useSiteRestriction();
  const { aiCaptionAccess } = useAIFeatureAccess();

  const selectedTags = useSelector((state: IStore) => state.addPostAccount.selectedTags);
  const instaType = useSelector((state: IStore) => state.addPostAccount.instaPhotoPreviewObj.actionType);
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const contentData = useSelector((state: IStore) => state.addPostFranchisor.contentData);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const isSaveDraftFetching = useSelector((state: IStore) => state.addPostFranchisor.isSaveDraftFetching);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const pageType = useSelector((state: IStore) => state.addPostFranchisor.pageType);
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);
  const isApproveAndSaveToDBFetching = useSelector((state: IStore) => state.addPostFranchisor.isApproveAndSaveToDBFetching);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const { isThumbnailShow, linkIndex, postStatus } = contentObj;
  const currentDate = useSelector((state: IStore) => state.addPostFranchisor.contentSavedDate?.currentDate);
  const savedDate = useSelector((state: IStore) => state.addPostFranchisor.contentSavedDate?.savedDate || null);
  const campaignData = useSelector((state: IStore) => state.addPostFranchisorAction.campaignData);
  const creatorModalName = useSelector((state: IStore) => state.addPostFranchisorAction.creatorModalName);
  const selectedLocData = useSelector((state: IStore) => state.addPostFranchisorAction.selectedLocData);
  const isCampaign = useSelector((state: IStore) => state.accounts.accountDetails?.account.campaign_feature || state.franchisors.franchisorDetails?.campaign_feature || false);
  const isAiPost = useSelector((state: IStore) => state.aiContentCreator.isAiPost);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);
  // const complianceDocuments = useSelector((state: IStore) => state.aiPlayBook.complianceDocuments);
  const aiPlaybook = useSelector((state: IStore) => state.aiPlayBook.ai_playbook);
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);
  const isAiContentOpen = useSelector((state: IStore) => state.aiContentCreator.isAiContentOpen);
  const aiTextContent = useSelector((state: IStore) => state.aiContentCreator.aiTextContent);
  const customerPersonaText = useSelector((state: IStore) =>
    userOwnership === USER_OWNERSHIP.ACCOUNT ? state.accounts.accountDetails?.account?.customer_persona_text : state.aiPlayBook.ai_playbook.customer_persona_text
  );
  const reducerTextEditorState = useSelector((state: IStore) => state.addPostFranchisorAction.textEditorState);
  const franchisorDetails = useSelector((state: IStore) => state.franchisors.franchisorDetails);
  const aiComplianceSummary = useSelector((state: IStore) => state.aiPlayBook.ai_playbook.ai_compliance_summary);
  const isAICaptionValidationEnabled = useSelector((state: IStore) => state.aiContentCreator.isAICaptionValidationEnabled);
  const isHashtagsAndEmojis = useSelector((state: IStore) => state.aiContentCreator.isHashtagsAndEmojis);
  const location = useSelector((state: IStore) => state.accounts.accountDetails?.account?.city || '');
  const translationConfig = useSelector((state: IStore) => state.addPostFranchisorAction.translationConfig);
  const { generalDocuments, verticals } = useSelector((state: IStore) => state.aiPlayBook);

  const isEnableAICaptionValidation = Boolean(aiCaptionAccess && isAISubscribed && aiComplianceSummary && isAICaptionValidationEnabled);

  const humanReadableDate = useMemo(() => {
    return currentDate && savedDate ? getHumanReadableTime(currentDate, savedDate) : '';
  }, [currentDate, savedDate]);

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(addPostSetContentSavedDate({ currentDate: getCurrentDate() }));
    }, ONE_MINUTE_INTERVAL_DELAY);
    return () => {
      clearInterval(timer);
    };
  }, []); // eslint-disable-line

  const handleUpdateContentData = (actionName: string) => {
    handleUpdateLink();
    if (contentData?.id && activeUserId && actionName) {
      const filteredTranslationConfig = Object.keys(translationConfig).reduce((acc: any, curr) => {
        if (translationConfig[curr]?.isSocialMediaTranslated) {
          acc[curr] = {
            from_language: translationConfig[curr].from_language,
            to_language: translationConfig[curr].to_language,
            original_text: translationConfig[curr].original_text
          };
        }
        return acc;
      }, {});

      const saveDraftReqPayload = getReqPayloadForUpdateContent(
        id,
        activeUserId,
        contentData?.created_at,
        contentObj,
        selectedMediaList,
        selectedTags,
        selectedFBTagList,
        actionName,
        selectedLocData,
        linkData,
        instaType,
        campaignData,
        contentData.content_origins,
        reducerTextEditorState?.facebook,
        null,
        contentData?.ai_post || isAiPost,
        Object.keys(filteredTranslationConfig)?.length ? filteredTranslationConfig : null
      );

      dispatch(addPostUpdateContentRequest({ actionType: actionName, postId: contentData.id, reqPayload: saveDraftReqPayload }));
      if (actionName !== AP_CONTENT_ACTION_TYPE.SAVE_DRAFT) {
        handleContentMassUpdate();
      }
    }
  };

  const handleUpdateLink = () => {
    if (linkData?.id && linkData?.link_preview_image_url_choices?.length > 1) {
      dispatch(addPostUpdateLinkRequest({ linkId: linkData.id, reqPayload: getLinkDataReqPayload(linkData, linkIndex, isThumbnailShow) }));
    }
  };

  const handleApproveAndSchedule = () => {
    if (getPreviewBrandHubCreatorActions(postStatus).length) {
      dispatch(addPostSetContentSavedDate({ currentDate: getCurrentDate(), savedDate: getCurrentDate() }));
      handleUpdateContentData(AP_CONTENT_ACTION_TYPE.APPROVE_AND_SCHEDULE);
      setModalName(AP_FRAN_MODAL_NAMES.SCHEDULE);
    }
  };

  const handleContentMassUpdate = () => {
    if (contentData?.id && atLeastApproved(contentData?.status_id)) {
      dispatch(addPostMassUpdateForScheduledPostRequest(contentData?.id));
    }
  };

  const disableSaveAsDraftAction = () => {
    if (!getPreviewBrandHubCreatorActions(postStatus).length) return true;
    else if (isSaveDraftFetching) return true;
    else if (pageType === AP_ACTION_TYPES.EDIT && contentObj.isPostLock && contentData?.content_origins) return true;
    else return false;
  };

  const disableSaveAsReadyAction = () => {
    if (!getPreviewBrandHubCreatorActions(postStatus).length) return true;
    else if (isApproveAndSaveToDBFetching) return true;
    else if (pageType === AP_ACTION_TYPES.EDIT && contentObj.isPostLock && contentData?.content_origins) return true;
    else return false;
  };

  const disableSyndicateAction = () => {
    if (!getPreviewBrandHubCreatorActions(postStatus).length) {
      return true;
    }
    return false;
  };

  const actionButtonClassname = (disabled: boolean) => {
    return disabled ? ` events-none pointer-events-none` : '';
  };

  const renderSaveAsDraft = () => {
    return (
      <CustomRippleButton rippleClass={`flex-fill ac-primary-box${actionButtonClassname(disableSaveAsDraftAction())}`} custColor={RIPPLE_COLOR.primary}>
        <button className={'ac-btn ac-primary ac-block r-flx r-flx-jc'} onClick={() => validateAICaption(() => handleUpdateContentData(AP_CONTENT_ACTION_TYPE.SAVE_DRAFT))}>
          Save as Draft
        </button>
      </CustomRippleButton>
    );
  };

  const setModalName = (data: string | null) => dispatch(setCreatorModalName(data));

  const validateAICaption = (onSuccess: () => void) => {
    if (!isEnableAICaptionValidation) {
      onSuccess();
    } else {
      const validationText = Object.values(contentObj.customText).find((value) => Boolean(value)) || '';
      const customerPersonaFieldData = generateCustomerPersonaFieldData(customerPersonaText || '');

      dispatch(
        aiValidateCaptionRequest({
          text: validationText,
          type: ComplianceType.CAPTION,
          // compliance_document_urls: complianceDocuments.map((it: any) => it.url) ?? [],
          compliance_summary: aiPlaybook.ai_compliance_summary || '',
          // ai_compliance_description: aiPlaybook.ai_compliance_description || '',
          onSuccess: (warning: string) => {
            if (!warning) {
              onSuccess();
            } else {
              dispatch(setAIPostContentOpen(!isAiContentOpen));
              dispatch(setAITextContent({ ...aiTextContent, content: contentObj.customText[contentObj.currentTab] || '' }));
              dispatch(
                getAIContentCreatorRequest({
                  id,
                  userOwnership,
                  text: splitTextFromHashAndEmoji(validationText).trim(),
                  brandName: aiPlaybook.name,
                  customerAlias: aiPlaybook.questions.customer_alias,
                  businessSearch: aiPlaybook.questions.business_search,
                  brandCategory: verticals
                    .find((it) => it.id === aiPlaybook.vertical_id)
                    ?.value.replace(VALID_BRAND_CATEGORY_REGEX, '')
                    .trim(),
                  documentUrls: generalDocuments.map((it: any) => it.url) ?? [],
                  // compliance_document_urls: complianceDocuments.map((it: any) => it.url) ?? [],
                  // ai_compliance_description: aiPlaybook.ai_compliance_description || '',
                  isHashtags: isHashtagsAndEmojis?.isHashtags,
                  isEmojis: isHashtagsAndEmojis?.isEmojis,
                  location,
                  websiteUrl: '',
                  customer_persona_description: customerPersonaText || '',
                  customer_persona_usage_level: SETTINGS_CUSTOMER_PERSONA_USAGE_LEVELS[0].value,
                  customer_persona_name: customerPersonaFieldData.name,
                  customer_persona_occupation: customerPersonaFieldData.occupation,
                  compliance_summary: aiPlaybook.ai_compliance_summary || '',
                  preferred_language: franchisorDetails?.preferred_language || ''
                })
              );
            }
          }
        })
      );
    }
  };

  return (
    <>
      <section className="item-g filter coupon-filter creator-filter__main">
        {contentData?.id ? (
          franchisorFullAccess ? (
            <div className="createpost-right-section-new-section">
              <div className="action-btn-holder">
                <div className="grid-btn-section d-flex">
                  {contentData?.status_id === 1 ? (
                    renderSaveAsDraft()
                  ) : (
                    <CustomRippleButton rippleClass={` ac-primary-box${actionButtonClassname(true)}`} custColor={RIPPLE_COLOR.primary}>
                      <button className={`ac-btn ac-primary ac-block disabled`}>Approved</button>
                    </CustomRippleButton>
                  )}
                  <CustomRippleButton rippleClass={`flex-fill ac-primary-box${actionButtonClassname(disableSaveAsReadyAction())}`} custColor={RIPPLE_COLOR.primary}>
                    <button
                      className={`ac-btn ac-primary ac-block r-flx r-flx-jc ${disableSaveAsReadyAction() ? 'disabled' : ''}`}
                      onClick={() => {
                        validateAICaption(() => handleUpdateContentData(AP_CONTENT_ACTION_TYPE.APPROVE_AND_SAVE_TO_DB));
                      }}
                    >
                      Save as Ready
                    </button>
                  </CustomRippleButton>
                </div>
                {!creatorRestriction?.schedule && !isContentSupplier && (
                  <CustomRippleButton rippleClass={`ac-primary-box${actionButtonClassname(!getPreviewBrandHubCreatorActions(postStatus).length)}`} custColor={RIPPLE_COLOR.skyBlue}>
                    <button
                      className={'ac-btn ac-secondary ac-block'}
                      onClick={() => {
                        validateAICaption(() => handleApproveAndSchedule());
                      }}
                    >
                      Schedule
                    </button>
                  </CustomRippleButton>
                )}
                <CustomRippleButton rippleClass={`ac-primary-box${actionButtonClassname(disableSyndicateAction())}`} custColor={RIPPLE_COLOR.primary}>
                  <button
                    className={'ac-btn ac-primary ac-block'}
                    onClick={() => {
                      validateAICaption(() => setModalName(AP_FRAN_MODAL_NAMES.SYNDICATE));
                    }}
                  >
                    Syndicate
                  </button>
                </CustomRippleButton>
                {humanReadableDate ? (
                  <div className="current-time-stamp">
                    <span>(Saved {humanReadableDate} ago)</span>
                  </div>
                ) : null}
                {!creatorRestriction?.assign_to_campaign && isCampaign && !isContentSupplier && (
                  <div className="filter-item atc-btn">
                    <CustomRippleButton rippleClass={`ac-primary-box${actionButtonClassname(!getPreviewBrandHubCreatorActions(postStatus).length)}`} custColor={RIPPLE_COLOR.primary}>
                      <button className={'ac-btn ac-primary ac-block'} onClick={() => setModalName(AP_FRAN_MODAL_NAMES.CAMPAIGN)}>
                        Assign to Campaign
                      </button>
                    </CustomRippleButton>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="createpost-right-section-new-section">
              <div className="action-btn-holder">
                <div className="grid-btn-section">{renderSaveAsDraft()}</div>
              </div>
            </div>
          )
        ) : null}
      </section>
      {creatorModalName === AP_FRAN_MODAL_NAMES.CAMPAIGN ? (
        <AddOrSearchCampaignModal
          isShowModal
          onModalClose={() => setModalName(null)}
          onSaveChanges={() => handleUpdateContentData(campaignData.length > 1 ? AP_CONTENT_ACTION_TYPE.MULTIPLE_CAMPAIGN_POSTS : AP_CONTENT_ACTION_TYPE.SINGLE_CAMPAIGN_POST)}
        />
      ) : null}
    </>
  );
};
