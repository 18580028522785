import { franchisorApiTypes } from 'types';
import { compareTwoObjects } from 'utils';
import { BRAND_PROFILE_SETTINGS_SECTIONS } from 'utils/constants';

export const compareBrandProfileObj = (updatedBrandData: Partial<franchisorApiTypes.IFranchisorData>, initialBrandData: Partial<franchisorApiTypes.IFranchisorData>, type: string) => {
  const getComparisonData = () => {
    switch (type) {
      case BRAND_PROFILE_SETTINGS_SECTIONS.PREFERRED_LANGUAGE: {
        return {
          initialValue: { [BRAND_PROFILE_SETTINGS_SECTIONS.PREFERRED_LANGUAGE]: initialBrandData.preferred_language },
          updatedValue: { [BRAND_PROFILE_SETTINGS_SECTIONS.PREFERRED_LANGUAGE]: updatedBrandData.preferred_language }
        };
      }
      case BRAND_PROFILE_SETTINGS_SECTIONS.LOGO_URLS: {
        return {
          initialValue: { [BRAND_PROFILE_SETTINGS_SECTIONS.LOGO_URLS]: initialBrandData.logo_urls },
          updatedValue: { [BRAND_PROFILE_SETTINGS_SECTIONS.LOGO_URLS]: updatedBrandData.logo_urls }
        };
      }
      case BRAND_PROFILE_SETTINGS_SECTIONS.FEED_BADGE_URL: {
        return {
          initialValue: { [BRAND_PROFILE_SETTINGS_SECTIONS.FEED_BADGE_URL]: initialBrandData.feed_badge_url },
          updatedValue: { [BRAND_PROFILE_SETTINGS_SECTIONS.FEED_BADGE_URL]: updatedBrandData.feed_badge_url }
        };
      }
      default: {
        return { initialValue: {}, updatedValue: {} };
      }
    }
  };

  const { initialValue, updatedValue } = getComparisonData();

  return compareTwoObjects(initialValue, updatedValue);
};
