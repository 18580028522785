import { settingsRallioProfileReducerTypes } from 'types';

export const RALLIO_PROFILE_INITIAL_VALUE: settingsRallioProfileReducerTypes.IRallioProfileValue = {
  name: '',
  street: '',
  short_name: '', // store_code value
  city: '',
  business_name: '',
  phone_number: '',
  mailing_address: '',
  business_email: '',
  phone: '',
  zip: '',
  url: '',
  state: '',
  country_code: '',
  time_zone: '',
  job_page_url: '',
  custom_fields: {},
  person_to_reach: '', // manager
  required_field: 'No',
  preferred_language: null,
  enable_automatic_post_translations: false
};
