import { commonAccountSwitcher, commonProps, IUserDetailObj } from 'types';
import { INeedHelpUrlTypes } from 'types/common/account-switcher';

import { MAIN_NAV_BAR_NAME, ROUTE_TABS } from 'utils/constants';

export const PAGINATION_PER_PAGE = 12;

export const MEDIA_PAGINATION_PER_PAGE = 16;

export const PAGINATION_PER_PAGE_SMALL_GRID = 30;

export const SAGAS_DELAY_SECONDS = 2000;

export const INBOX_COMMENTS_DELAY_SECONDS = 3000;

export const ONE_MINUTE_INTERVAL_DELAY = 60 * 1000;

export const MAX_CHUNK_SIZE = 20 * 1000000;

export const DATE_PICKER_MIN_YEAR = new Date(`${SAGAS_DELAY_SECONDS}`);

export const DEBOUNCE_TIMEOUT = 500;

export const SCROLL_THRESHOLD = 0.7;

export const POST_STATUS = {
  PUBLISHED: 'Published',
  REJECTED: 'Rejected',
  APPROVED: 'Approved',
  AWAITING_APPROVAL: 'Awaiting approval',
  DRAFT: 'Draft'
};

export const ENGAGEMENT_STATS = {
  LIKES: 'Likes',
  COMMENTS: 'Comments',
  SHARES: 'Shares',
  CLICKS: 'Clicks',
  REACTIONS: 'Reactions',
  RETWEETS: 'Retweets',
  FAVORITES: 'Favorites'
};

export const HEADER_TITLE_SPLITTER = ' | ';
export const COMMA_SPLITTER = ',';

export const NEXT_LINE_SPLITTER = '\n';
export const PERSONALIZED_TAG_REQEX = /\[(.*?)\]/g;
export const LINE_BREAK_REGEX = /(?:\r\n|\r|\n)/g;
export const SEPERATE_TEXT_TO_HASH_REGEX = /#[\w]+(?=\s|$)/g;
export const SEPERATE_EMOJI_FROM_TEXT = /(?![*#0-9]+)[\p{Emoji}\p{Emoji_Modifier}\p{Emoji_Component}\p{Emoji_Modifier_Base}\p{Emoji_Presentation}]/gu;
export const SPLIT_HASHTAGS = /(^|(\W))((#|[A-Za-z0-9]).*?(?=( #|,)|$))/gi;
export const REMOVE_SPACE_FROM_TEXT = /(\s|,)+/g;

export const VALID_URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const URL_REGEX_WITH_OPTIONAL_PROTOCAL = /^(?:(?:https?:\/\/)?(?:www\.)?)?[a-z0-9\-\.]+\.[a-z]{2,}(?:\/[^\s]*)?$/i; // eslint-disable-line
export const ALPHABET_REGEX = /[A-Za-z]+/;
export const NUMBER_REGEX = /[0-9]+/;
export const SPECIAL_CHARS_REGEX = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/; // eslint-disable-line
export const WORD_AT_BEGINNING_REGEX = /\b(\w)/g; //  character a-z, A-Z, 0-9, including underscore
export const UNICODE_REGEX = /([\u02C2-\uDE6D])/g;
export const IMAGE_TO_THUMBNAIL_URL_REGEX = /image\/upload\/v\d+\//;

export const NOT_APPLICABLE = 'N/A';
export const NOT_AVAILABLE = 'Not Available';
export const THUMBNAIL_URL_SUFFIX = 'q_auto,c_limit,h_1500,w_1500/';
export const BRAND_LOGO_URL_SUFFIX = 'q_auto,c_pad,h_500,w_500/'; // if remove c_limit image resized and shrink into 500*500, else image resized without quality loss but dimensions varies
export const AWS_REK_TAGGING_CATEGORY = 'aws_rek_tagging';

export const EXPORT_XL_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

export const ALERT_BOX_TYPES = {
  SUCCESS: 'success',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
  DISMISS: 'dismiss'
};

export const ALERT_BOX_TYPES_INIT_SINGLE_TOAST = {
  success: true,
  info: true,
  warn: true,
  error: true
};

export enum FeedValue {
  OUTBOX = 'outbox',
  INBOX = 'inbox',
  SANDBOX = 'sandbox'
}

export const SUPER_ADMIN = 'super_admin';

export const MODAL_BOX_TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  WARN: 'warn',
  ERROR: 'error',
  DANGER: 'danger'
};

export const API_TYPES = {
  RAILS: 'rails',
  NODE: 'node',
  PROMOTION: 'promotion',
  STRIPE: 'stripe',
  MAP_BOX_GEOCODER: 'mapBoxGeocoder',
  CLOUDINARY: 'cloudinary',
  FACEBOOK: 'facebook',
  REVV: 'revv',
  REVV_SSO: 'revv_sso',
  OAUTH_API: 'oauth_api'
};

export const US_COUNTRY_NAME = 'United States';
export const US_TIME_ZONE = 'Pacific Time (US & Canada)';

export const US_NUMBER_FORMAT = 'en-US';

export const ICON_COLORS = ['pink', 'blue', 'grey', 'green', 'orange', 'blue', 'green', 'skyblue', 'purple', 'green'];

// account ownership
export const USER_OWNERSHIP = {
  FRANCHISOR: 'franchisor',
  ACCOUNT: 'account'
};

export const TREE_NODE_TYPE = {
  FRANCHISORS: 'franchisors',
  ACCOUNTS: 'accounts'
};

export const FEATURES = {
  EMPLOYEE_ADVOCACY: 'employee advocacy'
};

// No data found props
export enum NoDataFoundProps {
  FULL = 'Full',
  MEDIUM = 'Medium',
  SMALL = 'Small'
}

export const SIDE_NAV_DROPDOWN_DATA = {
  ADMIN: 'admin',
  LOCAL: 'local',
  LOCATION: 'location'
};

export const BREAK_POINT_COLUMNS_OBJ = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1
};

export const BREAK_POINT_COLUMNS_OBJ_MEDIA = {
  default: 4,
  1199: 3,
  900: 2,
  575: 1
};

export const BREAK_POINT_ADD_IMAGE = {
  default: 4,
  1279: 3,
  992: 2,
  767: 1
  // 575: 1
};

export const BREAK_POINT_COLUMNS_AI_OBJ = {
  default: 2,
  1100: 1,
  700: 1,
  500: 1
};

export const BREAK_POINT_PROFILE_IMAGERY = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1
};

export enum ICommonDateTypes {
  FROM_DATE = 'fromDate',
  TO_DATE = 'toDate',
  DATE_ONLY = 'dateOnly',
  TIME_ONLY = 'timeOnly',
  START_TIME = 'start',
  END_TIME = 'end',
  DATE_AND_TIME = 'dateAndTime'
}

export const COMMON_DATE_RANGE_INIT_VALUE: commonProps.ICommonDateRange = {
  [ICommonDateTypes.FROM_DATE]: { selected: null, timeStamp: null },
  [ICommonDateTypes.TO_DATE]: { selected: null, timeStamp: null }
};

export const COMMON_DATE_AND_UTC_FORMAT_RANGE_INIT_VALUE: commonProps.ICommonDateAndUTCFormatRange = {
  [ICommonDateTypes.FROM_DATE]: { selected: null, utcFormat: null },
  [ICommonDateTypes.TO_DATE]: { selected: null, utcFormat: null }
};

export const CREATE_POST_TYPE = {
  POST: 'post',
  COMMENT: 'comment',
  MESSAGE: 'message',
  LIKE: 'like'
};

/** TOOLTIP CONSTANT DATA */
export const TOOLTIP_MOUSE_LEAVE_DELAY = 0.1;
export const TOOLTIP_TRIGGER = 'hover';

export const TOOLTIP_PLACEMENT = {
  TOP: 'top',
  BOTTOM: 'bottom',
  RIGHT: 'right',
  LEFT: 'left'
};

export const WEEK_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const WEEK_DAYS_SHORT_NAME: commonProps.IMainPageRouteData[] = WEEK_DAYS.map((it) => {
  return { name: it, label: it.slice(0, 3).toLocaleUpperCase() };
});

export const VALID_PHONE_NUMBER_REGEX = '^[0-9- ()-+]';

export const VALID_PHONE_NUMBER_REGEX_PROFILE = '^[0-9-. ()-+]';

export const STRING_WITH_PHONE_NUMBER = /(?:[-+() ]*\d){10,13}/gm;

export const VALID_ONLY_NUMBERS_REGEX = '^[0-9]';

export const ALLOW_ONLY_NUMBERS_REGEX = /[^0-9]/g;

export const GET_NUMBERS_ONLY_REGEX = /[0-9]+/g;

export const GET_HASHTAG_ONLY_REGEX = /#[A-Za-z0-9_]+/g;

export enum SENTIMENTS {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL'
}

export enum SocialContentActionType {
  POST_COMMENT = 'Post Comment',
  POST_REPLY = 'Post Reply',
  POST_INTERNAL_NOTE = 'Post Internal Note',
  SEND_FOR_APPROVAL = 'Send For Approval',
  APPROVE = 'Approve',
  REJECT = 'Reject',
  EDIT = 'Edit',
  SAVE = 'Save'
}

export const CREATOR_SAVE_CHANGES_IN_NAV_OBJ = { isShow: false, mainPageName: '', subPageIndex: 0 };

export const CREATOR_SAVE_CHANGES_IN_HEADER_OBJ = { isShow: false, subPageName: '' };

export enum CalendarViewTypes {
  MONTH_VIEW = 'dayGridMonth',
  WEEK_VIEW = 'dayGridWeek',
  DAY_VIEW = 'timeGridDay',
  TIME_GRID_WEEK = 'timeGridWeek'
}

export const INITIAL_LOCATION_FILTER_DATA = { id: 0, value: 'All', label: 'All Locations', type: 'All' };

export const CREATOR_SAVE_CHANGES_IN_HEADER_DD_OBJ: commonAccountSwitcher.ICreatorSaveChangesObj = {
  isShow: false,
  targetObj: {
    label: '',
    type: '',
    value: 0,
    name: '',
    custom: false
  }
};

export const HEADER_TOAST_ALERT_TYPES = {
  RED_ALERT: 'RedAlert',
  YELLOW_ALERT: 'YellowAlert',
  GREEN_ALERT: 'GreenAlert'
};

export const HEADER_TOAST_RECONNECT_TYPES = {
  FACEBOOK: 'FacebookReconnectAlert',
  LINKEDIN: 'LinkedinReconnectAlert',
  TWITTER: 'TwitterReconnectAlert',
  INSTAGRAM: 'InstagramReconnectAlert',
  GOOGLE: 'GooglePlusReconnectAlert'
};

export const COMMON_PAGE_TYPE = {
  DETAILS: 'preview',
  FAVORITE_TAGS: 'favorite_tags',
  NEW: 'new',
  EDIT: 'edit',
  DETAIL_VIEW: 'detail_view',
  ACHIEVERS: 'acheivers',
  CLONE: 'clone',
  NON_CLONE: 'non_clone',
  SUBSCRIPTION: 'susbcriptionTable'
};

export const RIPPLE_COLOR = {
  primary: '#134A7C',
  secondary: 'rgba(19, 74, 124,0.4)',
  danger: '#FF7373',
  whiteBlue: '#EDF3F9',
  whiteGrey: '#F5F6F7',
  skyBlue: '#4e80ff',
  aiprompt: 'rgba(25, 98, 165, 0.1)'
};

export const VALID_APP_URL_TITLES = ['RenderSEO', 'Advantix'];

export const getAppDataObj = () => {
  const hostName = new URL(window.location as any)?.hostname;
  switch (hostName) {
    case 'social.renderseo.com':
      return {
        title: 'RenderSEO',
        url: 'https://www.renderseo.com/',
        rallioProfileText: 'Profile',
        helpUrl: 'https://intercom.help/ralliohelpcenter/en',
        cdnSource: 'renderseo',
        source: 'renderseo',
        themeSourcePath: 'renderseo',
        logoUrl: 'https://cdn.rall.io/img/renderseo/ax-00-rallio-logo.png'
      };
    case 'reputation.avxdigital.com':
      return {
        title: 'Advantix',
        url: 'https://www.reputation.avxdigital.com/',
        rallioProfileText: 'Profile',
        helpUrl: 'https://intercom.help/ralliohelpcenter/en',
        cdnSource: 'avxdigital',
        themeSourcePath: 'avxdigital',
        source: 'avxdigital',
        logoUrl: 'https://cdn.rall.io/img/avxdigital/ax-00-rallio-logo.png'
      };
    case 'app.mylocalcmo.com':
      return {
        title: 'MyLocalCMO',
        url: 'https://app.mylocalcmo.com/',
        rallioProfileText: 'Profile',
        helpUrl: 'https://intercom.help/ralliohelpcenter/en',
        cdnSource: 'mylocalcmo',
        themeSourcePath: 'mylocalcmo',
        source: 'mylocalcmo',
        logoUrl: 'https://cdn.rall.io/img/mylocalcmo/ax-00-rallio-logo.png'
      };
    case 'app.painlessposting.com':
      return {
        title: 'Painless Posting',
        url: 'https://app.painlessposting.com/',
        rallioProfileText: 'Profile',
        helpUrl: 'https://intercom.help/ralliohelpcenter/en',
        cdnSource: 'painlessposting',
        themeSourcePath: 'painlessposting',
        source: 'painlessposting',
        logoUrl: 'https://cdn.rall.io/img/painlessposting/ax-00-rallio-logo.png'
      };
    case 'reputation.digitalkairoi.com':
      return {
        title: 'Digital Kairoi',
        url: 'https://www.reputation.digitalkairoi.com/',
        rallioProfileText: 'Profile',
        helpUrl: 'https://intercom.help/ralliohelpcenter/en',
        cdnSource: 'digitalkairoi',
        themeSourcePath: 'digitalkairoi',
        source: 'digitalkairoi',
        logoUrl: 'https://cdn.rall.io/img/digitalkairoi/ax-00-rallio-logo.png'
      };
    case 'social.sparqmarketing.com':
      return {
        title: 'Sparq',
        url: 'https://www.social.sparqmarketing.com/',
        rallioProfileText: 'Profile',
        helpUrl: 'https://intercom.help/ralliohelpcenter/en',
        cdnSource: 'sparq',
        themeSourcePath: 'sparq',
        source: 'sparq',
        logoUrl: 'https://cdn.rall.io/img/sparq/ax-00-rallio-logo.png'
      };
    case 'brightfully.app':
      return {
        title: 'Bright Pink Agency',
        url: 'https://www.brightfully.app/',
        rallioProfileText: 'Profile',
        helpUrl: 'https://intercom.help/ralliohelpcenter/en',
        cdnSource: 'brightpinkagency',
        themeSourcePath: 'brightpinkagency',
        source: 'brightpinkagency',
        logoUrl: 'https://cdn.rall.io/img/brightpinkagency/ax-00-rallio-logo.png'
      };
    case 'social.thetravelnetworkgroup.co.uk':
      return {
        title: 'The Travel Network Group',
        url: 'https://social.thetravelnetworkgroup.co.uk/',
        rallioProfileText: 'Profile',
        helpUrl: 'https://intercom.help/ralliohelpcenter/en',
        cdnSource: 'socialcentral',
        themeSourcePath: 'socialcentral',
        source: 'socialcentral',
        logoUrl: 'https://cdn.rall.io/img/socialcentral/ax-00-rallio-logo.png'
      };
    case 'social.seniornicity.com':
      return {
        title: 'Seniornicity',
        url: 'https://social.seniornicity.com/',
        rallioProfileText: 'Profile',
        helpUrl: 'https://intercom.help/ralliohelpcenter/en',
        cdnSource: 'seniornicity',
        themeSourcePath: 'seniornicity',
        source: 'seniornicity',
        logoUrl: 'https://cdn.rall.io/img/seniornicity/ax-00-rallio-logo.png'
      };
    default:
      return {
        title: 'Rallio',
        url: 'https://www.rallio.com/',
        rallioProfileText: 'Rallio Profile',
        helpUrl: 'https://intercom.help/ralliohelpcenter/en',
        cdnSource: 'activate',
        themeSourcePath: 'rallio',
        source: 'rallio',
        logoUrl: 'https://cdn.rall.io/img/activate-v3/ax-00-rallio-logo.png'
      };
  }
};

const data = getAppDataObj();

export const APP_TITLE = data.title;
export const APP_URL = data.url;
export const RALLIO_PROFILE_TEXT = data.rallioProfileText;
export const HELP_URL = data.helpUrl;
export const CDN_SOURCE = data.cdnSource;
export const PRIMARY_LOGO_URL = data.logoUrl;
export const PRIMARY_APP_SOURCE = data.source;
export const THEME_SOURCE_PATH = data.themeSourcePath;

export const contentSupplierHelpUrl = 'https://intercom.help/content-supplier-support-articles/en';
export const csLocationHelpUrl = 'https://intercom.help/agent-support-articles/en';

export const BUTTON_PROPS = {
  CLEAR_FILTER: {
    rippleColor: RIPPLE_COLOR.whiteBlue,
    value: 'clearFilter',
    label: 'Clear Filter',
    customImage: '',
    buttonVariant: `ac-btn ac-primary-light ac-outline ac-block`,
    isDisable: false,
    parentVariant: 'filter-item clear-filter',
    isBorder: false
  },
  PRIMARY_EXPORT_CSV: {
    rippleColor: RIPPLE_COLOR.skyBlue,
    value: 'exportCsv',
    label: 'Download CSV',
    customImage: 'csv-export',
    buttonVariant: `ac-btn ac-icon-btn ac-primary ac-block csv`,
    isDisable: false,
    parentVariant: 'filter-item',
    isBorder: true
  },
  SECONDARY_EXPORT_CSV: {
    rippleColor: RIPPLE_COLOR.skyBlue,
    value: 'exportCsv',
    label: 'Download CSV',
    customImage: 'export-blue',
    buttonVariant: `ac-btn ac-primary-white ac-outline ac-icon-btn ac-block csv`,
    isDisable: false,
    parentVariant: 'filter-item connection-report',
    isBorder: true
  },

  PRIMARY_BUTTON: {
    rippleColor: RIPPLE_COLOR.primary,
    value: '',
    label: '',
    customImage: '',
    buttonVariant: `ac-btn ac-primary ac-block r-flx r-flx-jc`,
    isDisable: false,
    parentVariant: '',
    isBorder: true
  },
  SECONDARY_BUTTON: {
    rippleColor: RIPPLE_COLOR.primary,
    value: '',
    label: '',
    customImage: '',
    buttonVariant: `ac-btn ac-secondary ac-block`,
    isDisable: false,
    parentVariant: '',
    isBorder: true
  },
  RELOAD_BUTTON: {
    rippleColor: RIPPLE_COLOR.whiteBlue,
    value: 'reload',
    label: 'Reload',
    customImage: 'pie-reload',
    buttonVariant: `ac-btn ac-primary-white ac-outline ac-icon-btn ac-block csv `,
    isDisable: false,
    parentVariant: 'ac-primary-box r-mt2',
    isBorder: false
  }
};

export const PLATFORM_STATS = [
  {
    label: 'Facebook',
    value: 'facebook',
    image: 'fb-lv.svg',
    parentVariant: 'fb-sc'
  },
  {
    label: 'Instagram',
    value: 'instagram',
    image: `instagram-lv.svg`,
    parentVariant: 'insta-sc'
  },
  {
    label: 'X',
    value: 'twitter',
    image: 'twitter-lv.svg',
    parentVariant: 'ttr-sc',
    captionText: '(Formerly Twitter)'
  },
  {
    label: 'LinkedIn',
    value: 'linkedin',
    image: 'linkedin-lv.svg',
    parentVariant: 'lkdin-sc'
  },
  {
    label: 'TikTok',
    value: 'tiktok',
    image: 'tiktok-lv.svg',
    parentVariant: 'tiktok-sc'
  }
];

export const REVIEWS_PLATFORM_STATS = [
  PLATFORM_STATS[0],
  {
    label: 'Google',
    value: 'google',
    image: 'google-Iv.svg',
    parentVariant: 'googleplus-sc'
  },
  {
    label: 'Yelp',
    value: 'yelp',
    image: `yelp-Iv.svg`,
    parentVariant: 'yelp-sc'
  }
];

export const FRAN_SCHEDULE_POST_TYPE = {
  ALL_LOCATIONS: 'all_locations',
  CORPORATE_ONLY: 'corporate_only',
  SPECIFIC_LOCS_OR_LISTS: 'specific_locations_or_lists',
  SPECIFIC_LOCATIONS: 'specific_locations'
};

export const CORPORATE_POST_SCHEDULE_TYPE = [
  { name: FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS, label: 'All Locations' },
  { name: FRAN_SCHEDULE_POST_TYPE.CORPORATE_ONLY, label: 'Corporate Only' },
  { name: FRAN_SCHEDULE_POST_TYPE.SPECIFIC_LOCS_OR_LISTS, label: 'Specific Locations/Lists' }
];

export const RECOMMEND_PROGRAM_LIST_TYPES = [
  { name: FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS, label: 'All Locations' },
  { name: FRAN_SCHEDULE_POST_TYPE.SPECIFIC_LOCATIONS, label: 'Specific Locations' }
];

export const REWARD_PROGRAM_LIST_TYPE = [
  { name: FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS, label: 'All Locations' },
  { name: FRAN_SCHEDULE_POST_TYPE.SPECIFIC_LOCS_OR_LISTS, label: 'Specific Locations/Lists' }
];

export const COMMUNICATION_SCHEDULE_TYPE = [
  { name: FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS, label: 'All Hubs/Lists/Locations' },
  { name: FRAN_SCHEDULE_POST_TYPE.SPECIFIC_LOCATIONS, label: 'Specific Hubs/Lists/Locations' }
];

export enum FranchisorAccessType {
  ADMIN = 'admin',
  CONTENT_WRITER = 'contentWriter',
  FRANCHISOR = 'franchisor'
}

export enum Month {
  JANUARY = 'JANUARY',
  FEBRUARY = 'FEBRUARY',
  MARCH = 'MARCH',
  APRIL = 'APRIL',
  MAY = 'MAY',
  JUNE = 'JUNE',
  JULY = 'JULY',
  AUGUST = 'AUGUST',
  SEPTEMBER = 'SEPTEMBER',
  OCTOBER = 'OCTOBER',
  NOVEMBER = 'NOVEMBER',
  DECEMBER = 'DECEMBER'
}

export enum CommonAction {
  GET = 'get',
  ADD = 'add',
  EDIT = 'edit'
}

export const COMMON_SOCIAL_PROVIDER_TYPE = {
  INSTAGRAM_PUBLIC_CONTENT: 'instagram_public_content',
  INSTAGRAM_BUSINESS_PAGE: 'instagram_business_page',
  INSTAGRAM: 'instagram',
  FACEBOOK: 'facebook',
  LINKEDIN: 'linkedin',
  TIKTOK: 'tiktok',
  TWITTER: 'twitter',
  GOOGLE: 'google',
  GOOGLE_ACCOUNT: 'google_oauth2',
  GOOGLE_PLACES: 'google_places',
  YELP: 'yelp',
  ALL: 'all'
};

export const COMMON_SOCIAL_MEDIA_NAME: commonProps.ICommonObj<string> = {
  INSTAGRAM_PUBLIC_CONTENT: 'Instagram public content',
  INSTAGRAM_BUSINESS_PAGE: 'Instagram business page',
  INSTAGRAM: 'Instagram',
  FACEBOOK: 'Facebook',
  LINKEDIN: 'Linkedin',
  TIKTOK: 'Tiktok',
  TWITTER: 'Twitter',
  GOOGLE: 'Google',
  YELP: 'Yelp',
  GOOGLE_MY_BUSINESS: 'Google My Business',
  INSTAGRAM_BUSINESS: 'Instagram Business',
  GOOGLE_BUSINESS: 'Google Business',
  LINKEDIN_PAGE: 'LinkedIn',
  X: 'X (Formerly Twitter)'
};

export const POST_IMAGES_LIMITATION = {
  INSTAGRAM: 10,
  TWITTER: 4,
  LINKEDIN: 9,
  GOOGLE: 1
};

export enum CommonValidQueryParams {
  NOTIFICATION_SETTINGS = 'notification_settings',
  CONNECT = 'connect',
  USER_ID = 'user_id',
  ACTIVE_USER_ID = 'active_user_id'
}

export const COMMON_VALID_QUERY_PARAMS_OBJ: commonProps.ICommonObj<string[]> = {
  notification_settings: ['1'],
  connect: ['instagram']
};

export const IMAGE_SUPPORTED_FORMAT = [
  // 'image/*',
  '.ai',
  '.png',
  '.webp',
  '.avif',
  '.bmp',
  '.bw',
  '.djvu',
  '.ps',
  '.ept',
  '.eps',
  '.eps3',
  '.fbx',
  '.flif',
  '.glb',
  '.gltf',
  '.heif',
  '.heic',
  '.ico',
  '.indd',
  '.jpg',
  '.jpe',
  '.jpeg',
  '.jp2',
  '.wdp',
  '.jxr',
  '.hdp',
  '.obj',
  '.ply',
  '.psd',
  '.arw',
  '.cr2',
  '.svg',
  '.tga',
  '.tif',
  '.tiff',
  '.u3ma',
  '.usdz',
  '.webp',
  '.gif'
];

export const VIDEO_SUPPORTED_FORMAT = ['.3g2', '.3gp', '.avi', '.flv', '.m3u8', '.ts', '.m2ts', '.mts', '.mov', '.mkv', '.mp4', '.mpeg', '.mpd', '.mxf', '.ogv', '.webm', '.wmv'];

export const MG_FILE_SUPPORTED_FORMAT = [
  '.pdf',
  '.doc',
  '.docx',
  '.docm',
  '.dotx',
  '.rtf',
  '.txt',
  '.xls',
  '.xlsx',
  '.xlsm',
  '.pot',
  '.potm',
  '.potx',
  '.pps',
  '.ppsm',
  '.pptx',
  '.ppt',
  '.pptm',
  '.csv',
  '.zip',
  '.xml',
  '.ppt',
  '.stl'
];

export const FILE_SUPPORTED_FORMAT = ['.pdf'];

export enum IReactDNDItemTypes {
  CARD = 'card'
}

export const MAX_FB_ADS_POTENTIAL_REACH = 1000000;

export const VALID_APP_URL = ['sign_on_tokens'];

export const LOADING = 'Loading...';

export enum IAiIconBgColorType {
  GREY = 'grey',
  LIGHT_BLUE = 'light_blue',
  DARK_BLUE = 'dark_blue'
}

export const AI_ICON_BG_IMG = {
  GREY: 'ai-animation-gray.gif',
  LIGHT_BLUE: 'ai-animation-lightblue.gif',
  DARK_BLUE: 'ai-animation-dakblue.gif',
  WHITE: 'ai-animation.gif',
  DARK_GREY: 'ai-loader-grey.gif',
  LIGHT_BLUISHGREY: 'ai-animation-lightbluishgrey.gif'
};

export const ALPHABET = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

export const INPUT_TYPE = {
  BUTTON: 'button',
  CHECKBOX: 'checkbox',
  COLOR: 'color',
  DATE: 'date',
  DATETIME_LOCAL: 'datetime-local',
  EMAIL: 'email',
  FILE: 'file',
  HIDDEN: 'hidden',
  IMAGE: 'image',
  MONTH: 'month',
  NUMBER: 'number',
  PASSWORD: 'password',
  RADIO: 'radio',
  RANGE: 'range',
  RESET: 'reset',
  SEARCH: 'search',
  SUBMIT: 'submit',
  TEL: 'tel',
  TEXT: 'text',
  TIME: 'time',
  URL: 'url',
  WEEK: 'week'
};

export const PROFILE_INFO_MODAL_TYPE = {
  PROFILE_INFO: 'profile_info'
};

export enum IReactResponsiveTypes {
  DESKTOP_OR_LAPTOP = 'desktop_or_laptop',
  TABLET_OR_MOBILE = 'tablet_or_mobile',
  BIG_SCREEN = 'big_screen',
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
  RETINA = 'Retina'
}

export const USER_DETAIL_MODAL_TAB_KEY = {
  PHOTOS: 'photos',
  VIDEOS: 'videos',
  DOCUMENTS: 'documents',
  POSTS: 'posts',
  REWARDS: 'rewards'
};

export const USER_DETAIL_MODAL_TABS = [
  { key: USER_DETAIL_MODAL_TAB_KEY.PHOTOS, title: 'Images' },
  { key: USER_DETAIL_MODAL_TAB_KEY.VIDEOS, title: 'Videos' },
  { key: USER_DETAIL_MODAL_TAB_KEY.DOCUMENTS, title: 'Documents' },
  { key: USER_DETAIL_MODAL_TAB_KEY.POSTS, title: 'Posts' },
  { key: USER_DETAIL_MODAL_TAB_KEY.REWARDS, title: 'Rewards' }
];

export const USER_REWARD_LIST_TABLE_HEADER_KEY = {
  PROGRAM: 'program',
  ACTIONS: 'actions',
  REWARDS: 'rewards'
};

export const USER_REWARD_LIST_TABLE_HEADER = [
  { label: 'Program', key: 'program' },
  { label: 'Actions', key: 'actions' },
  { label: 'Rewards', key: 'rewards' }
];

export const USER_DETAIL_INIT_OBJ: IUserDetailObj = {
  isFetching: true,
  isListFetching: true,
  isProfileImgFetching: false,
  isEditUser: false,
  isUserDataUpdate: false,
  currentPage: 1,
  currentUserDetails: null,
  assetsList: [],
  postsList: [],
  rewardsList: [],
  activeTab: USER_DETAIL_MODAL_TAB_KEY.PHOTOS,
  search: '',
  error: null,
  userProfileObj: {
    cloudinaryId: null,
    photoUrl: null
  },
  updateUserDetailStatus: {
    isUpdated: false,
    isFailed: false
  }
};

export const AI_BANNER_CONTEXT = 'Auto generated AI posts can now be turned on or off from the AI Playbook.';

export const BANNER_CONTEXT =
  'Due the Thanksgiving holiday, all corporate moderators will be offline and, consequently, no content approval will occur until the team is back in the office on Monday, November 28. Thank you for your understanding.';

export const AI_CAPTION_RESULTS_MESSAGE = `Click “AI Prompt Ideas” to suggestions to optimize the AI results or simply click, “Generate Captions!” to see the result`;

export const AI_IMAGE_RESULTS_MESSAGE = `Spark your creativity! Share your image idea, tap 'Generate Image,' and behold mesmerizing AI creations come alive before you.`;

export const FACEBOOK_BOOST_AD_PREVIEW_ACTIONS = [
  { defaultImg: 'post-like', className: 'like', label: 'Like' },
  { defaultImg: 'post-cmd', className: 'comment', label: 'Comment' },
  { defaultImg: 'post-reply', className: 'share', label: 'Share' }
];

export const BACK_TO_TOP_TOOLTIP = 'Back to top';

export const ACCOUNT_API_TYPES = {
  CALENDAR: 'calendar'
};

export const FRANCHISORS_SELECTIONS = 'Hubs';
export const LOCATIONS = 'Locations';

export const LESSTHAN_REGEX = /</g;
export const GREATERTHAN_REGEX = />/g;

export const LIST_HEADER = [
  { labelString: 'Name', identifier: 'name', headerClassName: '' },
  // { labelString: 'Automated Content Scheduler', identifier: 'feed_type', headerClassName: '' },
  { labelString: 'Enrollment Count', identifier: 'locations_count', headerClassName: '' },
  // { labelString: 'Edited on', identifier: 'updated_at', headerClassName: '' },
  // { labelString: 'Edited by', identifier: 'created_user', headerClassName: '' },
  // { labelString: 'Automated Content Scheduler', identifier: 'content_supplier', headerClassName: '' },
  { labelString: 'Make Public', identifier: 'make_public', headerClassName: '' },
  { labelString: 'Enroll', identifier: 'enroll', headerClassName: '' },
  { labelString: '', identifier: 'button', headerClassName: 'tml-action' }
];

export const helpNavBars = (mainNavPageName: string, isShowNeedHelp: boolean, isShowVideoTutorials: boolean, isShowFeatureUpdates: boolean) => {
  return [
    {
      label: ROUTE_TABS.VIDEO_TUTORIALS,
      isShow: mainNavPageName !== MAIN_NAV_BAR_NAME.ADMIN_AREA && !VALID_APP_URL_TITLES.includes(APP_TITLE) && !isShowVideoTutorials,
      image: 'video-tutorials'
    },
    {
      label: ROUTE_TABS.FEATURE_UPDATES,
      isShow: mainNavPageName !== MAIN_NAV_BAR_NAME.ADMIN_AREA && !VALID_APP_URL_TITLES.includes(APP_TITLE) && !isShowFeatureUpdates,
      image: 'feature-updates'
    },
    {
      label: ROUTE_TABS.NEED_HELP,
      title: 'Check out our Knowledge Base!',
      isShow: !VALID_APP_URL_TITLES.includes(APP_TITLE) && !isShowNeedHelp,
      image: 'need-help'
    }
  ];
};

export const siteRestrictionObj: commonProps.ICommonObj<string[]> = {
  posts: ['internal_note', 'copy', 'schedule', 'delete', 'reject', 'edit', 'syndicate', 'create_post', 'approve'],
  calendar: ['approve', 'edit', 'delete', 'schedule', 'create_post'],
  creator: ['schedule', 'assign_to_campaign'],
  ai: ['button']
};

export const SITE_RESTRICTION_OBJ_INITIAL_VALUE: commonProps.ICommonObj<commonProps.ICommonObj<boolean>> = {
  postsRestriction: {
    internal_note: false,
    copy: false,
    schedule: false,
    delete: false,
    reject: false,
    edit: false,
    syndicate: false,
    create_post: false,
    approve: false
  },
  calendarRestriction: {
    approve: false,
    edit: false,
    delete: false,
    schedule: false,
    create_post: false
  },
  creatorRestriction: {
    schedule: false,
    assign_to_campaign: false
  },
  aiRestriction: {
    button: false
  }
};

export const FEED_TYPE = {
  STANDARD: 'standard',
  CONTENT_SUPPLIER: 'content_supplier'
};

export const AI_ASSISTANT_ROLE = {
  USER: 'user',
  ASSISTANT: 'assistant'
};

export const NO_COMPLIANCE_SUGGESTION_MSG = ['no compliance suggestion', 'no need for a compliance suggestion'];

export const NEED_HELP_URL_OBJ: Record<INeedHelpUrlTypes, Record<string, string>> = {
  franchisor: {
    analytics_social: `${HELP_URL}/articles/5992869-social-analytics-overview`,
    analytics_content: `${HELP_URL}/articles/5990018-hub-user-content-analytics`,
    analytics_page: `${HELP_URL}/articles/5990198-page-analytics`,
    analytics_leaderboard: `${HELP_URL}/articles/5990032-understanding-the-leaderboard-analytics`,
    creator: `${HELP_URL}/articles/5992720-creating-a-post`,
    posts: `${HELP_URL}/articles/5992876-navigating-your-posts`,
    media: `${HELP_URL}/articles/5992726-media-gallery`,
    profile_imagery: `${HELP_URL}/articles/5992858-profile-imagery`,
    inbox: `${HELP_URL}/articles/5990201-managing-your-inbox`,
    sandbox: `${HELP_URL}/articles/5990205-understanding-the-sandbox`,
    reviews: `${HELP_URL}/articles/5992870-managing-your-reviews`,
    outbox: `${HELP_URL}/articles/5992862-the-outbox`,
    // ai_review_responder: `${HELP_URL}/articles`,
    advocacy_leaderboard: `${HELP_URL}/articles/5992732-employee-advocacy-leaderboard`,
    overview: `${HELP_URL}/articles/5992736-overview-of-employee-advocacy`,
    connections: `${HELP_URL}/articles/5992742-your-connections`,
    lists: `${HELP_URL}/articles/5992746-account-lists`,
    permissions: `${HELP_URL}/articles/5992866-managing-hub-permissions`,
    fb_ads_marketing: `${HELP_URL}/articles/5992874-facebook-ads`,
    release_form: `${HELP_URL}/articles/5992865-model-release-form`,
    reviews_settings: `${HELP_URL}/articles/5992867-editing-your-auto-filled-review-responses`,
    ai_playbook: `${HELP_URL}`
  },
  contentSupplier: {
    analytics_social: `${contentSupplierHelpUrl}/articles/5992869-social-analytics-overview`,
    analytics_content: `${contentSupplierHelpUrl}/articles/5990018-hub-user-content-analytics`,
    creator: `${contentSupplierHelpUrl}/articles/5992720-creating-a-post`,
    posts: `${contentSupplierHelpUrl}/articles/5992876-navigating-your-posts`,
    media: `${contentSupplierHelpUrl}/articles/6029824-media-gallery`
  },
  csLocation: {
    analytics_social: `${csLocationHelpUrl}/articles/5992869-social-analytics-overview`,
    analytics_content: `${csLocationHelpUrl}/articles/6029817-content-analytics`,
    analytics_page: `${csLocationHelpUrl}/articles/5992880-page-analytics`,
    creator: `${csLocationHelpUrl}/articles/6029831-creating-and-scheduling-a-post`,
    posts: `${csLocationHelpUrl}/articles/6029830-managing-your-posts`,
    media: `${csLocationHelpUrl}/articles/6029824-media-gallery`,
    calendar: `${csLocationHelpUrl}/articles/6029820-managing-content-in-the-calendar`,
    inbox: `${csLocationHelpUrl}/articles/6029766-managing-your-inbox`,
    reviews: `${csLocationHelpUrl}/articles/6029812-managing-your-reviews`,
    outbox: `${csLocationHelpUrl}/articles/6029811-using-the-outbox`,
    // ai_review_responder: `${HELP_URL}/articles`,
    rallio_profile: `${csLocationHelpUrl}/articles/6029815-managing-your-profile-settings`,
    ai_playbook: `${csLocationHelpUrl}`,
    social_profile: `${csLocationHelpUrl}/articles/6029832-connecting-your-social-profiles`,
    scheduler: `${csLocationHelpUrl}/articles/9312507-using-the-scheduler`,
    feed: `${csLocationHelpUrl}/articles/9312689-managing-your-partner-feeds`
  },
  account: {
    analytics_social: `${HELP_URL}/articles/5990034-social-analytics-overview`,
    analytics_content: `${HELP_URL}/articles/6029817-content-analytics`,
    analytics_page: `${HELP_URL}/articles/5992880-page-analytics`,
    creator: `${HELP_URL}/articles/6029831-creating-and-scheduling-a-post`,
    posts: `${HELP_URL}/articles/6029830-managing-your-posts`,
    media: `${HELP_URL}/articles/6029824-media-gallery`,
    calendar: `${HELP_URL}/articles/6029820-managing-content-in-the-calendar`,
    rss_feeds: `${HELP_URL}/articles/6029780-rss-feed`,
    inbox: `${HELP_URL}/articles/6029766-managing-your-inbox`,
    sandbox: `${HELP_URL}/articles/6029774-understanding-the-sandbox`,
    reviews: `${HELP_URL}/articles/6029812-managing-your-reviews`,
    outbox: `${HELP_URL}/articles/6029811-using-the-outbox`,
    // ai_review_responder: `${HELP_URL}/articles`,
    overview: `${HELP_URL}/articles/6029805-overview-of-employee-advocacy`,
    advocacy_leaderboard: `${HELP_URL}/articles/6029791-employee-advocacy-leaderboard`,
    rallio_profile: `${HELP_URL}/articles/6029815-managing-your-profile-settings`,
    ai_playbook: `${HELP_URL}`,
    social_profile: `${HELP_URL}/articles/6029832-connecting-your-social-profiles`,
    fb_ads_settings: `${HELP_URL}/articles/6029814-facebook-ads`
  }
};

export const LANG_OPTION_LABELS = {
  ENGLISH: 'English',
  FRENCH: 'French',
  QUEBEC_FRENCH: 'Quebec French',
  GERMAN: 'German',
  ITALIAN: 'Italian',
  SPANISH: 'Spanish',
  DUTCH: 'Dutch',
  PORTUGUESE: 'Portuguese',
  ORIGINAL_TEXT: 'Original Text'
};

export const LANG_OPTION_VALUES = {
  ENGLISH: 'English (US flag)',
  FRENCH: 'French (France flag)',
  QUEBEC_FRENCH: 'Quebec French (Canada flag)',
  GERMAN: 'German (German flag)',
  ITALIAN: 'Italian (Italy flag)',
  SPANISH: 'Spanish (Spain flag)',
  DUTCH: 'Dutch (Netherland flag)',
  PORTUGUESE: 'Portuguese (Portugal flag)',
  ORIGINAL_TEXT: 'Original Text'
};

export const TRANSLATE_LANG_OPTIONS = [
  { id: 2, label: LANG_OPTION_LABELS.ENGLISH, value: LANG_OPTION_VALUES.ENGLISH, imgName: 'localization/united-states' },
  { id: 2, label: LANG_OPTION_LABELS.FRENCH, value: LANG_OPTION_VALUES.FRENCH, imgName: 'localization/french' },
  { id: 2, label: LANG_OPTION_LABELS.QUEBEC_FRENCH, value: LANG_OPTION_VALUES.QUEBEC_FRENCH, imgName: 'localization/canada' },
  { id: 3, label: LANG_OPTION_LABELS.GERMAN, value: LANG_OPTION_VALUES.GERMAN, imgName: 'localization/german' },
  { id: 6, label: LANG_OPTION_LABELS.ITALIAN, value: LANG_OPTION_VALUES.ITALIAN, imgName: 'localization/italy' },
  { id: 5, label: LANG_OPTION_LABELS.SPANISH, value: LANG_OPTION_VALUES.SPANISH, imgName: 'localization/spanish' },
  { id: 6, label: LANG_OPTION_LABELS.DUTCH, value: LANG_OPTION_VALUES.DUTCH, imgName: 'localization/dutch' },
  { id: 4, label: LANG_OPTION_LABELS.PORTUGUESE, value: LANG_OPTION_VALUES.PORTUGUESE, imgName: 'localization/portuguese' }
];

export const MULTI_SELECT_MEDIA_POST_FILTER_VALUE = {
  status: 'approved',
  source: 'all',
  multi_syndicated_to_any_franchisor: 'all',
  multi_syndicated_to_any_account: 'all'
};
