import { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Accordion } from 'react-bootstrap';
import { AccordionEventKey } from 'react-bootstrap/AccordionContext';

import { setAIPost, setAIPromptActive, setAISubscriptionDetailsModal, setAITextContent, setCreatorAITabName, setCsLocationSubscriptionModalType } from 'actions';
import { aiContentCreatorContainerTypes, IStore } from 'types';
import {
  AI_IMAGE_NO_RESULTS_FOUND,
  IAISugesstionTabs,
  AI_CAPTION_RESULTS_MESSAGE,
  AI_IMAGE_RESULTS_MESSAGE,
  AI_ICON_BG_IMG,
  AI_LOADING_IMAGE_GENERATION_CONTEXT_MSG,
  SIDE_NAV_NAME,
  USER_OWNERSHIP,
  SEPERATE_TEXT_TO_HASH_REGEX,
  IAIResultsTab,
  RIPPLE_COLOR,
  AI_LOADING_CONTEXT_MSG_FOR_MEDIA
} from 'utils/constants';
import { convertStringToEditorState, disableSaveButton, getUniqueHashtags, handleAddAssetsUpload, insertHashtagAtCursorPosition, removeHashtagsFromText } from 'utils/helpers';
import { useAccountSwitcherData, useSSOIdentifiersForAccount } from 'utils/hooks';
import { AILoading } from 'components';
import { AIPrompt } from './AIPrompt';
import { AIMediaListMemo } from './AIMediaList';
import { CaptionsList } from './CaptionsList';
import { HashTagsList } from './HashTagsList';
import { EditorStateContext } from '../..';
import { ImageValidation } from 'widgets/Media';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

const NoResult = ({ parent, isDataLoading }: { parent: string; isDataLoading?: boolean }) => (
  <span className="ai-no-results">{isDataLoading ? null : parent === IAISugesstionTabs.CAPTIONS_AND_HASHTAGS ? AI_CAPTION_RESULTS_MESSAGE : AI_IMAGE_RESULTS_MESSAGE}</span>
);

const MediaNoResult = () => <span className="ai-no-results">{AI_IMAGE_NO_RESULTS_FOUND}</span>;

export const BotResults = ({
  selectedTextObj,
  editedContent,
  handleSuccess,
  enableCaptionGenerate,
  enableImageGenerate,
  handleCreateCaption,
  isEnableImageTab
}: aiContentCreatorContainerTypes.IBotResults) => {
  const dispatch = useDispatch();
  const { id, userOwnership, isValidId, currentRoute } = useAccountSwitcherData();
  const { isCSLocation } = useSSOIdentifiersForAccount();
  const [textEditorState, setEditorState] = useContext(EditorStateContext);

  const createPostSuggestions = useSelector((state: IStore) => state.aiContentCreator.createPostSuggestions);
  const createPostHashtagSuggestions = useSelector((state: IStore) => state.aiContentCreator.createPostHashtagSuggestions);
  const isCreatePostSuggestionsFetching = useSelector((state: IStore) => state.aiContentCreator.isCreatePostSuggestionsFetching);
  const isShowAiImageTab = useSelector((state: IStore) => state.addPostFranchisorAction.isShowAiImageTab);
  const creatorCompName = useSelector((state: IStore) => state.common.creatorCompName);
  const selectedTab = useSelector((state: IStore) => state.addPostFranchisorAction.aiTabName);
  const isAIImageFetching = useSelector((state: IStore) => state.aiImageGeneration.isAIImageFetching);
  const response = useSelector((state: IStore) => state.mediaGallery.uploadCloudinaryMedia.response);
  const addedAssetsCount = useSelector((state: IStore) => state.mediaGallery.uploadCloudinaryMedia.addedAssetsCount);
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const activeUserFirstName = useSelector((state: IStore) => state.login.activeUser?.first_name) || '';
  const activeUserLastName = useSelector((state: IStore) => state.login.activeUser?.last_name) || '';
  const currentName = useSelector((state: IStore) => state.accountSwitcher.currentName);
  const topLevelFranchisorId = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorId);
  const topLevelFranchisor = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisor);
  const cloudinaryDataResponse = useSelector((state: IStore) => state.mediaGallery.uploadCloudinaryMedia.cloudinaryData);
  const isPromptActive = useSelector((state: IStore) => state.aiContentCreator.isPromptActive);
  const aiGenerated = useSelector((state: IStore) => state.aiContentCreator.aiGenerated);
  const captionState = useSelector((state: IStore) => state.aiContentCreator.caption);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const aiGeneratedImages = useSelector((state: IStore) => state.aiImageGeneration.aiGeneratedImages);
  const assetsList = useSelector((state: IStore) => state.mediaGallery.assetsList);
  const aiTextContent = useSelector((state: IStore) => state.aiContentCreator.aiTextContent);
  const complianceWarning = useSelector((state: IStore) => state.aiContentCreator.complianceWarning);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails?.account);
  const aiLimitation = useSelector((state: IStore) => state.aiContentCreator.aiLimitation);

  const [isAIFilterEnabled, setEnableAIFilter] = useState(false);
  const [activeKeys, setActiveKeys] = useState<string>(IAIResultsTab.CONTENTS);

  const isMediaScreen = currentRoute.split('/')[1] === SIDE_NAV_NAME.CONTENT_MEDIA;
  const accountId = accountDetails?.id;

  useEffect(() => {
    if (Object.keys(aiGenerated).length) {
      dispatch(setAIPost(Object.values(aiGenerated).includes(true) ? true : false));
    }
  }, [aiGenerated, dispatch]);

  useEffect(() => {
    if (!disableSaveButton(response, addedAssetsCount)) {
      handleAddAssetsUpload(
        response,
        activeUserId,
        id,
        userOwnership,
        isValidId,
        activeUserFirstName,
        activeUserLastName,
        currentName,
        topLevelFranchisorId,
        topLevelFranchisor,
        cloudinaryDataResponse,
        true,
        dispatch,
        isMediaScreen ? null : creatorCompName,
        isMediaScreen ? null : accountId,
        isMediaScreen ? null : true
      );
      handleSuccess();
    }
  }, [response, addedAssetsCount]); // eslint-disable-line

  const addOrRemoveHashtags = (platform: string, modifiedText: string, uniqueHashtags: string[], isOnlyCaptionText?: boolean) => {
    // New state that specifically changes in caption text.
    const captionsNewState = convertStringToEditorState(`${modifiedText}${uniqueHashtags.length ? ` ${uniqueHashtags.join(' ')} ` : ''}`);
    // Hashtag state changes and insert the hashtag at the current cursor position.
    const newState = uniqueHashtags?.length ? insertHashtagAtCursorPosition(textEditorState[platform], uniqueHashtags) : convertStringToEditorState(modifiedText);
    setEditorState({ ...textEditorState, [platform]: isOnlyCaptionText ? captionsNewState : newState });
  };

  const handleEditorStateChanges = (text: string | null, hashtags?: string[], isSelectedHashtags?: boolean) => {
    let modifiedText = text || editedContent;
    // Extract hashtags from text when there is a change in the text state.
    const validHashtags = hashtags ? hashtags : editedContent?.match(SEPERATE_TEXT_TO_HASH_REGEX) || [];
    // Separate the unique hashtags to avoid duplicating the same ones.
    const uniqueHashtags = getUniqueHashtags(validHashtags, modifiedText);
    // Remove hashtags from text
    if (isSelectedHashtags) modifiedText = removeHashtagsFromText(hashtags || captionState.selectedHashtags, modifiedText);
    if (savedPostDetails?.current_tab && userOwnership === USER_OWNERSHIP.ACCOUNT) {
      return addOrRemoveHashtags(savedPostDetails?.current_tab, modifiedText, uniqueHashtags, Boolean(text));
    } else if (contentObj.currentTab && userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      return addOrRemoveHashtags(contentObj?.currentTab, modifiedText, uniqueHashtags, Boolean(text));
    }
  };

  const handleSelect = (eventKey: AccordionEventKey) => {
    setActiveKeys(eventKey as string);
  };

  return (
    <div className={`ai-bot-results ${createPostHashtagSuggestions?.length ? 'ai-ht-on' : 'ai-ht-off'} ${complianceWarning?.caption || complianceWarning?.image ? 'cmplnce-on' : 'cmplnce-off'}`}>
      {isPromptActive ? (
        <AIPrompt handlePromptClose={() => dispatch(setAIPromptActive(false))} activeTab={selectedTab.parent} />
      ) : (
        <>
          <div className={`aip-head-wrap ${isAIFilterEnabled ? 'aiFilterHeaderActive' : ''}`}>
            {isAIFilterEnabled && (
              <div className="fltr-back-btn">
                <ImageValidation isImgValid defaultImg="backArwRounded" customName="Back" onClick={() => setEnableAIFilter(false)} />
              </div>
            )}
            <h3>AI Results</h3>
          </div>
          {selectedTab.parent === IAISugesstionTabs.CAPTIONS_AND_HASHTAGS ? (
            <>
              {isCreatePostSuggestionsFetching && <AILoading aiIconBgColorType={AI_ICON_BG_IMG.DARK_GREY} context={AI_LOADING_CONTEXT_MSG_FOR_MEDIA} />}
              {createPostSuggestions?.length ? (
                <div className={`aia-asset-gnrtn ai-ch`}>
                  {complianceWarning?.caption && (
                    <div className="compliance-wrap">
                      <h2>Compliance</h2>
                      <p>{complianceWarning?.caption}</p>
                    </div>
                  )}
                  <div className="ai-brt-cg">
                    <Accordion defaultActiveKey={IAIResultsTab.CONTENTS} activeKey={activeKeys} onSelect={handleSelect}>
                      <Accordion.Item eventKey={IAIResultsTab.CONTENTS}>
                        <Accordion.Header>Captions</Accordion.Header>
                        <Accordion.Body>
                          <CaptionsList
                            handleEditorStateChanges={handleEditorStateChanges}
                            handleSelect={() => handleSelect(IAIResultsTab.HASHTAGS)}
                            selectedTab={selectedTab}
                            NoResult={NoResult}
                            dispatch={dispatch}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      {createPostHashtagSuggestions.length ? (
                        <Accordion.Item eventKey={IAIResultsTab.HASHTAGS}>
                          <Accordion.Header>hashtags</Accordion.Header>
                          <Accordion.Body>
                            <HashTagsList handleEditorStateChanges={handleEditorStateChanges} selectedTextObj={selectedTextObj} />
                          </Accordion.Body>
                        </Accordion.Item>
                      ) : null}
                    </Accordion>
                  </div>
                  {enableCaptionGenerate || enableImageGenerate ? (
                    <div className="ai-sub-btns">
                      {selectedTab.parent === IAISugesstionTabs.CAPTIONS_AND_HASHTAGS && isEnableImageTab && (
                        <CustomRippleButton rippleClass="ac-primary" custColor={RIPPLE_COLOR.primary}>
                          <button
                            className={`gnrc-btn blue-gnr`}
                            onClick={() => {
                              if (isCSLocation && Boolean(aiLimitation && +aiLimitation?.ai_caption_limit <= 0)) {
                                dispatch(setCsLocationSubscriptionModalType('ai_caption'));
                                dispatch(setAISubscriptionDetailsModal({ type: 'CSLocationAISubscription', isOpen: true }));
                              } else {
                                handleCreateCaption();
                                dispatch(setCreatorAITabName({ parent: IAISugesstionTabs.IMAGES, child: IAIResultsTab.IMAGES }));
                                setAITextContent({ ...aiTextContent, image: '' });
                              }
                            }}
                          >
                            <span>Want to add image?</span>
                          </button>
                        </CustomRippleButton>
                      )}
                      {selectedTab.parent === IAISugesstionTabs.CAPTIONS_AND_HASHTAGS ? (
                        <CustomRippleButton rippleClass="ac-primary" custColor={RIPPLE_COLOR.skyBlue}>
                          <button className={`gnrc-btn skyblue`} onClick={handleSuccess}>
                            <span>Done</span>
                          </button>
                        </CustomRippleButton>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              ) : (
                !isPromptActive && <NoResult isDataLoading={isCreatePostSuggestionsFetching} parent={selectedTab.parent} />
              )}
            </>
          ) : (
            <>
              {isAIImageFetching && !isShowAiImageTab && <AILoading aiIconBgColorType={AI_ICON_BG_IMG.WHITE} context={AI_LOADING_IMAGE_GENERATION_CONTEXT_MSG} />}
              {aiGeneratedImages.length || isShowAiImageTab || assetsList.length ? (
                <div className={`aia-asset-gnrtn ai-images ${isAIFilterEnabled ? 'aim-filter-active' : ''}`}>
                  {selectedTab.parent === IAISugesstionTabs.IMAGES ? (
                    <>
                      {complianceWarning?.image && (
                        <div className="compliance-wrap">
                          <h2>Image Restrictions</h2>
                          <p>{complianceWarning?.image}</p>
                        </div>
                      )}
                      <AIMediaListMemo
                        userOwnership={userOwnership}
                        selectedTab={selectedTab}
                        MediaNoResult={MediaNoResult}
                        NoResult={NoResult}
                        isAIFilterEnabled={isAIFilterEnabled}
                        setEnableAIFilter={setEnableAIFilter}
                        dispatch={dispatch}
                      />
                      {/* {isShowAiImageTab && <MediaListMemo id={id} accountId={accountId} selectedTab={selectedTab} MediaNoResult={MediaNoResult} dispatch={dispatch} />} */}
                    </>
                  ) : null}
                </div>
              ) : (
                <NoResult isDataLoading={isAIImageFetching} parent={selectedTab.parent} />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
