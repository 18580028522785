import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Loading, VirtualTable } from 'components';
import { ICustomHeaderDropDownOption, IStore, ITableHeader, postsContainerTypes, postsReducerTypes } from 'types';
import {
  POST_PUBLISHED_STATUS_ARR,
  TOOLTIP_PLACEMENT,
  POST_PUBLISHED_STATUS_SORT_TYPES,
  USER_OWNERSHIP,
  POSTS_INIT_FILTER_DATA,
  timeZonelist,
  POST_STATUS_IMAGE_NAME,
  COMMON_SOCIAL_MEDIA_NAME,
  PostPublishStatusNotes,
  POSTS_STATUS_TYPE,
  COMMON_PAGE_TYPE
} from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { CustomTooltip } from 'widgets/Tooltip';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { checkValidDate, convertLocationTimezone, getInitials, getRoundNumberTo2Decimal } from 'utils/helpers';
import { handleFilterChange } from 'analytics/utils';
import { getPostPerformanceRateRequest, postPublishedStatusRequest } from 'actions';
import { RenderPublishStatusSearch } from './RenderPublishStatusSearch';

export const RenderPublishStatusTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userOwnership, optionalParams } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor(POSTS_INIT_FILTER_DATA);

  const postPublishedStatus = useSelector((state: IStore) => state.posts.postPublishedStatus);
  const postPerformanceRate = useSelector((state: IStore) => state.posts.postPerformanceRate);
  const isPostStatsFetching = useSelector((state: IStore) => state.posts.isPublishedStatusFetching);

  const publishStatusSocialMedia = [
    COMMON_SOCIAL_MEDIA_NAME.FACEBOOK,
    COMMON_SOCIAL_MEDIA_NAME.TWITTER,
    COMMON_SOCIAL_MEDIA_NAME.LINKEDIN,
    COMMON_SOCIAL_MEDIA_NAME.GOOGLE,
    COMMON_SOCIAL_MEDIA_NAME.INSTAGRAM,
    COMMON_SOCIAL_MEDIA_NAME.TIKTOK
  ];

  const postId = optionalParams[0] === COMMON_PAGE_TYPE.DETAILS ? +optionalParams[1] : optionalParams[0] !== COMMON_PAGE_TYPE.FAVORITE_TAGS ? +optionalParams[0] : 0;

  useEffect(() => {
    if (!postPublishedStatus.length) {
      dispatch(postPublishedStatusRequest({ userOwnership: queryParams?.status === POSTS_STATUS_TYPE.AA_LOCATION_CONTENT ? USER_OWNERSHIP.ACCOUNT : userOwnership, id: postId }));
      dispatch(getPostPerformanceRateRequest({ [userOwnership === USER_OWNERSHIP.FRANCHISOR ? 'content_id' : 'saved_post_id']: postId }));
    }
  }, [postPublishedStatus.length, postId, userOwnership, queryParams?.status, dispatch]);

  const sortAppliedData = useMemo(() => {
    let searchAppliedData = postPublishedStatus;
    if (queryParams?.location) {
      searchAppliedData = postPublishedStatus.slice(0, postPublishedStatus.length).filter((it) => it.account_name.toLowerCase().indexOf(queryParams?.location.toLowerCase()) > -1);
    }
    const sortByLocationName = (data: postsReducerTypes.IPostPublishStatus[]) => {
      if (!queryParams?.ascending) {
        const sorted = data.sort((a, b) => a?.account_name.localeCompare(b?.account_name));
        return sorted;
      } else {
        const sorted = data.sort((a, b) => a?.account_name.localeCompare(b?.account_name)).reverse();
        return sorted;
      }
    };

    const sortBySocialMedia = (data: postsReducerTypes.IPostPublishStatus[]) => {
      if (!queryParams?.ascending) {
        return data
          .sort((a, b) => a.account_name.localeCompare(b.account_name))
          .sort((a, b) =>
            String(a[`${queryParams?.sort_by}_post_status` as keyof postsReducerTypes.IPostPublishStatus]).localeCompare(
              String(b[`${queryParams?.sort_by}_post_status` as keyof postsReducerTypes.IPostPublishStatus])
            )
          );
      } else {
        return data
          .sort((a, b) => a.account_name.localeCompare(b.account_name))
          .sort((a, b) =>
            String(a[`${queryParams?.sort_by}_post_status` as keyof postsReducerTypes.IPostPublishStatus]).localeCompare(
              String(b[`${queryParams?.sort_by}_post_status` as keyof postsReducerTypes.IPostPublishStatus])
            )
          )
          .reverse();
      }
    };

    if (searchAppliedData?.length > 0) {
      const dataToBeSorted = searchAppliedData.slice(0, searchAppliedData.length);
      const sort = (sortType: string) => {
        switch (sortType) {
          case POST_PUBLISHED_STATUS_SORT_TYPES.FACEBOOK:
          case POST_PUBLISHED_STATUS_SORT_TYPES.LINKEDIN:
          case POST_PUBLISHED_STATUS_SORT_TYPES.GOOGLE:
          case POST_PUBLISHED_STATUS_SORT_TYPES.INSTAGRAM:
          case POST_PUBLISHED_STATUS_SORT_TYPES.TWITTER:
          case POST_PUBLISHED_STATUS_SORT_TYPES.TIKTOK:
            return sortBySocialMedia(dataToBeSorted);
          default:
            return sortByLocationName(dataToBeSorted);
        }
      };
      return sort(queryParams?.sort_by);
    }
    return [];
  }, [postPublishedStatus, queryParams]);

  const PublishedStatusRowData = ({
    header,
    rowObj,
    convertedDate
  }: {
    header: ITableHeader<postsReducerTypes.IPostPublishStatus>;
    rowObj: postsReducerTypes.IPostPublishStatus;
    convertedDate: string;
  }) => {
    const postStatusObj = POST_PUBLISHED_STATUS_ARR.find((statusEle) => statusEle.status === rowObj[`${header?.identifier}_post_status` as keyof postsReducerTypes.IPostPublishStatus]);
    const postUrl = rowObj[`${header?.identifier}_post_url` as keyof postsReducerTypes.IPostPublishStatus];
    const tooltipText = `${
      postStatusObj?.tooltipText.includes('<platform>')
        ? `${postStatusObj?.tooltipText
            .replaceAll('<platform>', header.labelString)
            .replaceAll('<published_at>', `${convertedDate ? `@ ${convertedDate}` : ''}. ${postUrl ? `Click to view post.` : ''}`)}`
        : postStatusObj?.tooltipText
    } ${rowObj?.[`${header?.identifier}_error` as keyof postsReducerTypes.IPostPublishStatus] || ''}`;

    return postUrl ? (
      <a href={String(postUrl || '')} target="_blank" rel="noreferrer noopener">
        <CustomTooltip
          customPlacement={TOOLTIP_PLACEMENT.TOP}
          customTooltipText={tooltipText}
          customClassname={'custom-tooltip-long-text r-tbl-header__tip contetn-an alb-tooltip dps-tooltip'}
          customInput={() => (
            <span>
              <ImageValidation isImgValid defaultImg={postStatusObj?.imageName} customName={postStatusObj?.imageName} customClassname="status" />
            </span>
          )}
        />
      </a>
    ) : (
      <CustomTooltip
        customPlacement={TOOLTIP_PLACEMENT.TOP}
        customTooltipText={tooltipText}
        customClassname={'custom-tooltip-long-text r-tbl-header__tip contetn-an alb-tooltip dps-tooltip'}
        customInput={() => (
          <span>
            <ImageValidation isImgValid defaultImg={postStatusObj?.imageName} customName={postStatusObj?.imageName} customClassname="status" />
          </span>
        )}
      />
    );
  };

  const RenderNotes = ({ rowObj }: { rowObj: postsReducerTypes.IPostPublishStatus }) => {
    const errorPostStats: string[] = [];
    const notApprovePostStats: string[] = [];
    const platformConnectionStats: string[] = [];
    const postScheduleTime = new Date(rowObj.scheduled_for).getTime();
    const currentMillSeconds = new Date().getTime();
    publishStatusSocialMedia.forEach((socialMedia) => {
      const postStatusObj = POST_PUBLISHED_STATUS_ARR.find((statusEle) => statusEle.status === rowObj[`${socialMedia.toLowerCase()}_post_status` as keyof postsReducerTypes.IPostPublishStatus]);
      if (postStatusObj) {
        if (postStatusObj.imageName === POST_STATUS_IMAGE_NAME.RED) {
          errorPostStats.push(socialMedia);
        } else if (postStatusObj.imageName === POST_STATUS_IMAGE_NAME.YELLOW) {
          notApprovePostStats.push(socialMedia);
        } else if (postStatusObj.imageName === POST_STATUS_IMAGE_NAME.GREEN) {
          platformConnectionStats.push(socialMedia);
        }
      }
    });

    return (
      <span className="dps-note">
        {errorPostStats.length
          ? PostPublishStatusNotes.ERROR.replace('<platform>', errorPostStats.join(', '))
          : notApprovePostStats.length
          ? postScheduleTime > currentMillSeconds
            ? PostPublishStatusNotes.NOT_APPROVED_FUTURE
            : PostPublishStatusNotes.NOT_APPROVED_PAST
          : postScheduleTime > currentMillSeconds
          ? PostPublishStatusNotes.POST_SCHEDULED
          : platformConnectionStats.length
          ? PostPublishStatusNotes.SUCCESS
          : PostPublishStatusNotes.PLATFORM_NOT_CONNECTED}
      </span>
    );
  };

  const renderColumn = (rowObj: postsReducerTypes.IPostPublishStatus | any, header: ITableHeader<postsReducerTypes.IPostPublishStatus>) => {
    const publishedAt: string | null = String(
      rowObj[`${header.identifier === POST_PUBLISHED_STATUS_SORT_TYPES.LOCATION ? 'scheduled_for' : `${header?.identifier}_published_at`}` as keyof postsReducerTypes.IPostPublishStatus]
    );
    const timezoneName: any = timeZonelist.find((tz) => tz.timezone.includes(rowObj?.time_zone))?.timezone.find((it) => it === rowObj?.time_zone) || '';
    const convertedDate = checkValidDate(publishedAt) ? convertLocationTimezone(publishedAt, timezoneName) : '';
    switch (header.identifier) {
      case POST_PUBLISHED_STATUS_SORT_TYPES.LOCATION:
        return (
          <div className="an-social-icon-title r-flx r-flx-ac">
            <div className="init d-none">{getInitials(String(rowObj?.account_name))}</div>
            <div className="dp-loc-dtls">
              <div className="loc-right">
                <div className="loc-name" title={String(rowObj?.account_name)}>
                  {rowObj.account_name}
                </div>
              </div>
              <div className="dp-DateTime">
                {/* @ <span className="dp-time">11:30</span> on <span className="dp-Date">July 2, 2021 PDT</span> */}@ <span className="dp-time">{convertedDate}</span>
              </div>
            </div>
          </div>
        );
      case POST_PUBLISHED_STATUS_SORT_TYPES.NOTES:
        return <RenderNotes rowObj={rowObj} />;
      case POST_PUBLISHED_STATUS_SORT_TYPES.SUCCESS_RATE:
        return <p>{getRoundNumberTo2Decimal(rowObj[header.identifier])}%</p>;
      case POST_PUBLISHED_STATUS_SORT_TYPES.FACEBOOK:
      case POST_PUBLISHED_STATUS_SORT_TYPES.TWITTER:
      case POST_PUBLISHED_STATUS_SORT_TYPES.LINKEDIN:
      case POST_PUBLISHED_STATUS_SORT_TYPES.GOOGLE:
      case POST_PUBLISHED_STATUS_SORT_TYPES.INSTAGRAM:
      case POST_PUBLISHED_STATUS_SORT_TYPES.TIKTOK:
        return <PublishedStatusRowData header={header} rowObj={rowObj} convertedDate={convertedDate} />;
      default:
        return '';
    }
  };

  const PublishedStatusHeader = ({ name }: { name: string }) => {
    const SortIcon = ({ sortFlag }: { sortFlag: boolean }) => (
      <ImageValidation isImgValid defaultImg={'sort'} customName="Sort" customClassname={`${!sortFlag ? 'sort dp-icon Sort' : 'sort desc-sorted dp-lHSort'}`} />
    );

    const HeaderWrapper = ({ children }: { children: React.ReactNode }) => <div className="r-flx r-flx-ac r-flx-jc dp-icons">{children}</div>;

    const SocialMediaHeader = ({ imgName, customName, sortKey, sortFlag, socialMedia }: postsContainerTypes.ISocialMediaHeaderProps) => (
      <HeaderWrapper>
        <CustomTooltip
          customPlacement={TOOLTIP_PLACEMENT.TOP}
          customTooltipText={
            <div>
              <p>
                Successful: {getRoundNumberTo2Decimal(postPerformanceRate?.[`${socialMedia}_post_success_rate` as keyof postsReducerTypes.IPostPerformanceRateRes] || 0)}% -{' '}
                {postPerformanceRate?.[`${socialMedia}_post_success_count` as keyof postsReducerTypes.IPostPerformanceRateRes] || 0}/
                {postPerformanceRate?.[`${socialMedia}_pages_count` as keyof postsReducerTypes.IPostPerformanceRateRes] || 0} pages
              </p>
              <p>
                Not Approved: {getRoundNumberTo2Decimal(postPerformanceRate?.[`${socialMedia}_post_not_approved_rate` as keyof postsReducerTypes.IPostPerformanceRateRes] || 0)}% -{' '}
                {postPerformanceRate?.[`${socialMedia}_post_not_approved_count` as keyof postsReducerTypes.IPostPerformanceRateRes] || 0}/
                {postPerformanceRate?.[`${socialMedia}_pages_count` as keyof postsReducerTypes.IPostPerformanceRateRes] || 0} pages
              </p>
              <p>
                Error: {getRoundNumberTo2Decimal(postPerformanceRate?.[`${socialMedia}_post_error_rate` as keyof postsReducerTypes.IPostPerformanceRateRes] || 0)}% -{' '}
                {postPerformanceRate?.[`${socialMedia}_post_error_count` as keyof postsReducerTypes.IPostPerformanceRateRes] || 0}/
                {postPerformanceRate?.[`${socialMedia}_pages_count` as keyof postsReducerTypes.IPostPerformanceRateRes] || 0} pages
              </p>
            </div>
          }
          customInput={() => (
            <div>
              <ImageValidation isImgValid defaultImg={imgName} customName={customName} customClassname="social-media" />
              {(queryParams?.sort_by === sortKey || userOwnership === USER_OWNERSHIP.ACCOUNT) && <SortIcon sortFlag={sortFlag} />}
            </div>
          )}
        />
      </HeaderWrapper>
    );

    switch (name) {
      case POST_PUBLISHED_STATUS_SORT_TYPES.LOCATION:
        return (
          <div className="dp-fc-head">
            <div className="dp-fc-head-top">
              <span className="fc-head-item">{`Location${userOwnership === USER_OWNERSHIP.FRANCHISOR && sortAppliedData.length > 1 ? 's' : ''}`}</span>
              {!queryParams?.sort_by && userOwnership === USER_OWNERSHIP.FRANCHISOR ? <SortIcon sortFlag={!queryParams?.ascending} /> : null}
            </div>
            <span className="dp-subHead">Date and Time</span>
          </div>
        );
      case POST_PUBLISHED_STATUS_SORT_TYPES.NOTES:
        return <span className="fc-head-item">{`Note${userOwnership === USER_OWNERSHIP.FRANCHISOR && sortAppliedData.length > 1 ? 's' : ''}`}</span>;
      case POST_PUBLISHED_STATUS_SORT_TYPES.SUCCESS_RATE:
        return <span className="fc-head-item">Success Rate</span>;
      case POST_PUBLISHED_STATUS_SORT_TYPES.FACEBOOK:
        return <SocialMediaHeader imgName="fb-lv" customName="Facebook" sortKey={name} sortFlag={!queryParams?.ascending} socialMedia={POST_PUBLISHED_STATUS_SORT_TYPES.FACEBOOK} />;
      case POST_PUBLISHED_STATUS_SORT_TYPES.TWITTER:
        return <SocialMediaHeader imgName="twitter-bird" customName="Twitter" sortKey={name} sortFlag={!queryParams?.ascending} socialMedia={POST_PUBLISHED_STATUS_SORT_TYPES.TWITTER} />;
      case POST_PUBLISHED_STATUS_SORT_TYPES.LINKEDIN:
        return <SocialMediaHeader imgName="linkedin-s" customName="LinkedIn" sortKey={name} sortFlag={!queryParams?.ascending} socialMedia={POST_PUBLISHED_STATUS_SORT_TYPES.LINKEDIN} />;
      case POST_PUBLISHED_STATUS_SORT_TYPES.GOOGLE:
        return <SocialMediaHeader imgName="google-lv" customName="GooglePlus" sortKey={name} sortFlag={!queryParams?.ascending} socialMedia={POST_PUBLISHED_STATUS_SORT_TYPES.GOOGLE} />;
      case POST_PUBLISHED_STATUS_SORT_TYPES.INSTAGRAM:
        return <SocialMediaHeader imgName="instagram-d" customName="Instagram" sortKey={name} sortFlag={!queryParams?.ascending} socialMedia={POST_PUBLISHED_STATUS_SORT_TYPES.INSTAGRAM} />;
      case POST_PUBLISHED_STATUS_SORT_TYPES.TIKTOK:
        return <SocialMediaHeader imgName="tiktok-lv" customName="TikTOk" sortKey={name} sortFlag={!queryParams?.ascending} socialMedia={POST_PUBLISHED_STATUS_SORT_TYPES.TIKTOK} />;
      default:
        return <></>;
    }
  };

  const renderHeader = (
    headerObj: ITableHeader<postsReducerTypes.IPostPublishStatus, {}>,
    isResponsiveMode: boolean | undefined,
    setShowDropdown: React.Dispatch<React.SetStateAction<string>> | undefined,
    pinned: boolean | undefined
  ) => {
    return (
      <div
        onClick={() => {
          if (isResponsiveMode && !pinned && setShowDropdown) {
            setShowDropdown((prevState) => (headerObj.identifier === prevState ? '' : headerObj.identifier));
          }
        }}
        className="header-lbl__wrp cur-pointer"
      >
        <PublishedStatusHeader name={headerObj.identifier} />
      </div>
    );
  };

  const handleSortLocList = (publishedStatusTab: string) => {
    const sortType = !queryParams?.sort_by ? POST_PUBLISHED_STATUS_SORT_TYPES.LOCATION : queryParams?.sort_by;
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR && publishedStatusTab !== POST_PUBLISHED_STATUS_SORT_TYPES.NOTES) {
      if (publishedStatusTab === sortType) {
        handleChange({ sort_by: publishedStatusTab, ascending: queryParams?.ascending ? '1' : '0' });
      } else {
        handleChange({ sort_by: publishedStatusTab, ascending: '0' });
      }
    }
  };

  const handleChange = (data: { [key: string]: string }) => {
    const queryParamsObj = handleFilterChange(data, false, 0, queryParams);
    if (data.sort_by) {
      if (data.sort_by === POST_PUBLISHED_STATUS_SORT_TYPES.LOCATION) delete queryParamsObj.sort_by;
      else queryParamsObj.sort_by = data.sort_by;
    }
    if (data.ascending) {
      if (data.ascending === '0') queryParamsObj.ascending = data.ascending;
      else delete queryParamsObj.ascending;
    }
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  const responsiveSortingOptions: ICustomHeaderDropDownOption[] = [
    {
      identifier: 'ascending',
      text: 'Low to High',
      type: 'sort'
    },
    {
      identifier: 'descending',
      text: 'High to Low',
      type: 'sort'
    }
  ];

  const headers: ITableHeader<postsReducerTypes.IPostPublishStatus>[] = [
    {
      identifier: POST_PUBLISHED_STATUS_SORT_TYPES.LOCATION,
      label: `Location${sortAppliedData?.length > 1 ? 's' : ''}`,
      labelString: `Location${sortAppliedData?.length > 1 ? 's' : ''}`,
      headerClassName: `psw-th-head psw-loc-th ${!queryParams?.sort_by ? 'sort-active' : ''}`,
      colClassName: `psw-td-data psw-loc-td ${!queryParams?.sort_by ? 'sort-active' : ''}`,
      headerDropdownOptions: userOwnership === USER_OWNERSHIP.FRANCHISOR ? responsiveSortingOptions : [],
      renderHeader,
      renderColumn
    },
    {
      identifier: POST_PUBLISHED_STATUS_SORT_TYPES.NOTES,
      label: `Note${sortAppliedData?.length > 1 ? 's' : ''}`,
      labelString: `Note${sortAppliedData?.length > 1 ? 's' : ''}`,
      headerClassName: 'psw-th-head psw-note-th',
      colClassName: 'psw-td-data psw-note-td',
      renderHeader,
      renderColumn
    },
    {
      identifier: POST_PUBLISHED_STATUS_SORT_TYPES.SUCCESS_RATE,
      label: 'Success Rate',
      labelString: POST_PUBLISHED_STATUS_SORT_TYPES.SUCCESS_RATE,
      headerClassName: '',
      colClassName: '',
      renderHeader,
      renderColumn
    },
    {
      label: COMMON_SOCIAL_MEDIA_NAME.FACEBOOK,
      labelString: COMMON_SOCIAL_MEDIA_NAME.FACEBOOK,
      identifier: POST_PUBLISHED_STATUS_SORT_TYPES.FACEBOOK,
      headerClassName: queryParams?.sort_by === POST_PUBLISHED_STATUS_SORT_TYPES.FACEBOOK ? 'sort-active' : '',
      colClassName: queryParams?.sort_by === POST_PUBLISHED_STATUS_SORT_TYPES.FACEBOOK ? 'sort-active' : '',
      headerDropdownOptions: userOwnership === USER_OWNERSHIP.FRANCHISOR ? responsiveSortingOptions : [],
      renderHeader,
      renderColumn
    },
    {
      label: COMMON_SOCIAL_MEDIA_NAME.TWITTER,
      labelString: COMMON_SOCIAL_MEDIA_NAME.TWITTER,
      identifier: POST_PUBLISHED_STATUS_SORT_TYPES.TWITTER,
      headerClassName: queryParams?.sort_by === POST_PUBLISHED_STATUS_SORT_TYPES.TWITTER ? 'sort-active' : '',
      colClassName: queryParams?.sort_by === POST_PUBLISHED_STATUS_SORT_TYPES.TWITTER ? 'sort-active' : '',
      headerDropdownOptions: userOwnership === USER_OWNERSHIP.FRANCHISOR ? responsiveSortingOptions : [],
      renderHeader,
      renderColumn
    },
    {
      label: COMMON_SOCIAL_MEDIA_NAME.LINKEDIN,
      labelString: COMMON_SOCIAL_MEDIA_NAME.LINKEDIN,
      identifier: POST_PUBLISHED_STATUS_SORT_TYPES.LINKEDIN,
      headerClassName: queryParams?.sort_by === POST_PUBLISHED_STATUS_SORT_TYPES.LINKEDIN ? 'sort-active' : '',
      colClassName: queryParams?.sort_by === POST_PUBLISHED_STATUS_SORT_TYPES.LINKEDIN ? 'sort-active' : '',
      headerDropdownOptions: userOwnership === USER_OWNERSHIP.FRANCHISOR ? responsiveSortingOptions : [],
      renderHeader,
      renderColumn
    },
    {
      label: COMMON_SOCIAL_MEDIA_NAME.GOOGLE,
      labelString: COMMON_SOCIAL_MEDIA_NAME.GOOGLE,
      identifier: POST_PUBLISHED_STATUS_SORT_TYPES.GOOGLE,
      headerClassName: queryParams?.sort_by === POST_PUBLISHED_STATUS_SORT_TYPES.GOOGLE ? 'sort-active' : '',
      colClassName: queryParams?.sort_by === POST_PUBLISHED_STATUS_SORT_TYPES.GOOGLE ? 'sort-active' : '',
      headerDropdownOptions: userOwnership === USER_OWNERSHIP.FRANCHISOR ? responsiveSortingOptions : [],
      renderHeader,
      renderColumn
    },
    {
      label: COMMON_SOCIAL_MEDIA_NAME.INSTAGRAM,
      labelString: COMMON_SOCIAL_MEDIA_NAME.INSTAGRAM,
      identifier: POST_PUBLISHED_STATUS_SORT_TYPES.INSTAGRAM,
      headerClassName: queryParams?.sort_by === POST_PUBLISHED_STATUS_SORT_TYPES.INSTAGRAM ? 'sort-active' : '',
      colClassName: queryParams?.sort_by === POST_PUBLISHED_STATUS_SORT_TYPES.INSTAGRAM ? 'sort-active' : '',
      headerDropdownOptions: userOwnership === USER_OWNERSHIP.FRANCHISOR ? responsiveSortingOptions : [],
      renderHeader,
      renderColumn
    },
    {
      label: COMMON_SOCIAL_MEDIA_NAME.TIKTOK,
      labelString: COMMON_SOCIAL_MEDIA_NAME.TIKTOK,
      identifier: POST_PUBLISHED_STATUS_SORT_TYPES.TIKTOK,
      headerClassName: queryParams?.sort_by === POST_PUBLISHED_STATUS_SORT_TYPES.TIKTOK ? 'sort-active' : '',
      colClassName: queryParams?.sort_by === POST_PUBLISHED_STATUS_SORT_TYPES.TIKTOK ? 'sort-active' : '',
      headerDropdownOptions: userOwnership === USER_OWNERSHIP.FRANCHISOR ? responsiveSortingOptions : [],
      renderHeader,
      renderColumn
    }
  ];

  return (
    <div className="ps-wrap">
      {isPostStatsFetching ? (
        <Loading />
      ) : (
        <div className={`dpp-status ${userOwnership === USER_OWNERSHIP.FRANCHISOR ? 'dp-search-active' : 'dp-search-inactive'}`}>
          {userOwnership === USER_OWNERSHIP.FRANCHISOR && <RenderPublishStatusSearch />}
          <VirtualTable<postsReducerTypes.IPostPublishStatus>
            tableClassName={`table-cmn--skeleton responsiveTable ${sortAppliedData.length > 5 ? 'if-scroll-active' : 'if-scroll-inactive'}`}
            isFetching={false}
            headers={headers}
            data={[sortAppliedData]}
            ipadBoundary={768}
            ipadColCount={3}
            mobileBoundary={575}
            mobileColCount={2}
            mdDeviceBoundary={992}
            mdDeviceColCount={4}
            pinnedColumns={[0]}
            scrollHolderClassName="dpps-table"
            onHeaderClick={(header) => handleSortLocList(header.identifier)}
            onCustomHeaderDropDownClick={(headerObj) => handleSortLocList(headerObj.identifier)}
            scrollableTargetString="scrollTargetModal"
            scrollDivHeight={sortAppliedData.length > 5 ? 500 : undefined}
          />
        </div>
      )}
    </div>
  );
};
