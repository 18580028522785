import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Modal } from 'react-bootstrap';

import {
  aiGetCaptionsListRequest,
  aiCreateCaptionRequest,
  getAIContentCreatorRequest,
  mgResetAll,
  resetAIContentCreator,
  setAIPost,
  setAIPostContentOpen,
  setAIPostUsed,
  setCreatorAITabName,
  setAIInputContext,
  setAIHashtagsAndEmojis,
  setCreatorAICaptions,
  setAIPromptActive,
  resetAIImageGenerationImages,
  setAITextContent,
  resetAICaptionHashtagGeneration,
  getAIImageGenerationImagesRequest,
  aiValidateCaptionRequest,
  aiUpdateLimitationRequest,
  setAISubscriptionDetailsModal,
  setCsLocationSubscriptionModalType
} from 'actions';
import { aiContentCreatorContainerTypes, IStore } from 'types';
import { creatorHasVideo, creatorPhotosCount, generateCustomerPersonaFieldData, splitTextFromHashAndEmoji } from 'utils/helpers';
import { CustomTooltip } from 'widgets/Tooltip';
import {
  IAIResultsTab,
  AI_ICON_BG_IMG,
  AI_SUGESSTIONS_TAB,
  IAISugesstionTabs,
  VALID_BRAND_CATEGORY_REGEX,
  TOOLTIP_PLACEMENT,
  RIPPLE_COLOR,
  AI_IMAGE_GENERATION_TAB_NAMES,
  SIDE_NAV_NAME,
  AI_ASSISTANT_DEFAULT_TEXT,
  AI_IMAGE_GENERATION_RESOLUTION,
  SETTINGS_CUSTOMER_PERSONA_USAGE_LEVELS,
  USER_OWNERSHIP,
  AI_TEXT_CONTENT_INITIAL_VALUE,
  AI_IMAGE_DETAIL_OPTIONS,
  AI_IMAGE_STYLE_OPTIONS,
  ICSSubscriptionPlans,
  ComplianceType
} from 'utils/constants';
import { useAccountSwitcherData, useParamsDeconstructor, useAIFeatureAccess, useSSOIdentifiersForAccount } from 'utils/hooks';
import { BotResults } from './BotResults';

import { TextArea } from 'widgets/Text';
import { ToggleSwitch } from 'widgets/Switch';
import { ImageValidation } from 'widgets/Media';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { CustomDropdown } from 'widgets/CustomDropdown';

export const AIContentSuggestions = ({ editedContent }: aiContentCreatorContainerTypes.IAIContentSuggestions) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userOwnership, id, currentRoute, optionalParams } = useAccountSwitcherData();
  const { isCSLocation } = useSSOIdentifiersForAccount();
  const { aiCaptionAccess, aiImageAccess } = useAIFeatureAccess();
  const { queryParams } = useParamsDeconstructor();

  const isToneFetching = useSelector((state: IStore) => state.aiContentCreator.aiTone.isToneFetching);
  const aiTags = useSelector((state: IStore) => state.aiContentCreator.aiTags);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const selectedTab = useSelector((state: IStore) => state.addPostFranchisorAction.aiTabName);
  const isAIImageFetching = useSelector((state: IStore) => state.aiImageGeneration.isAIImageFetching);
  const { ai_playbook, verticals, generalDocuments } = useSelector((state: IStore) => state.aiPlayBook);
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const aiGeneratedImages = useSelector((state: IStore) => state.aiImageGeneration.aiGeneratedImages);
  const isShowAiImageTab = useSelector((state: IStore) => state.addPostFranchisorAction.isShowAiImageTab);
  const assetsList = useSelector((state: IStore) => state.mediaGallery.assetsList);
  const createPostHashtagSuggestions = useSelector((state: IStore) => state.aiContentCreator.createPostHashtagSuggestions);
  const captionState = useSelector((state: IStore) => state.aiContentCreator.caption);
  const isHashtagsAndEmojis = useSelector((state: IStore) => state.aiContentCreator.isHashtagsAndEmojis);
  const isPromptActive = useSelector((state: IStore) => state.aiContentCreator.isPromptActive);
  const customerPersonaText = useSelector((state: IStore) =>
    userOwnership === USER_OWNERSHIP.ACCOUNT ? state.accounts.accountDetails?.account?.customer_persona_text : state.aiPlayBook.ai_playbook.customer_persona_text
  );
  const createPostSuggestions = useSelector((state: IStore) => state.aiContentCreator.createPostSuggestions);
  const aiTextContent = useSelector((state: IStore) => state.aiContentCreator.aiTextContent);
  const isCreatePostSuggestionsFetching = useSelector((state: IStore) => state.aiContentCreator.isCreatePostSuggestionsFetching);
  const captionsList = useSelector((state: IStore) => state.aiContentCreator.captionsList);
  const isAiContentOpen = useSelector((state: IStore) => state.aiContentCreator.isAiContentOpen);
  const isAiPostUsed = useSelector((state: IStore) => state.aiContentCreator.isAiPostUsed);
  const isAICaptionValidationEnabled = useSelector((state: IStore) => state.aiContentCreator.isAICaptionValidationEnabled);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails?.account);
  const aiLimitation = useSelector((state: IStore) => state.aiContentCreator.aiLimitation);
  const aiSubscription = useSelector((state: IStore) => state.aiContentCreator.aiSubscription);
  const imageComplianceSummary = useSelector((state: IStore) =>
    userOwnership === USER_OWNERSHIP.FRANCHISOR ? state.franchisors.franchisorDetails?.ai_compliance_image_summary : state.accounts.accountDetails?.account?.ai_compliance_image_summary
  );
  const franchisorDetails = useSelector((state: IStore) => state.franchisors.franchisorDetails);

  const [brandCategory, setBrandCategory] = useState('');
  const [recentCaptions, setRecentCaptions] = useState<{ isOpen: boolean; id: number | null }>({ isOpen: false, id: null });
  // const [aspectRatio, setAspectRatio] = useState(AI_IMAGE_GENERATION_RESOLUTION[0]?.value);
  const [aiImgFilters, setAIImgFilters] = useState({
    image_resolution: AI_IMAGE_GENERATION_RESOLUTION[0].value,
    image_detail: AI_IMAGE_DETAIL_OPTIONS[0].value,
    image_style: AI_IMAGE_STYLE_OPTIONS[0].value,
    ai_compliance_image_summary: imageComplianceSummary ?? ''
  });
  const selectedTextObj = { index: null, text: createPostSuggestions?.length ? '' : editedContent };

  const location = accountDetails?.city || '';
  const activeSubscription = aiSubscription.find((subscription: any) => subscription.account_id === id);
  const isAILimitation = activeSubscription?.account_id && +activeSubscription.franchisor_subscription_type_id === 7 && activeSubscription?.subscription_plan === ICSSubscriptionPlans.FREE;

  const isMediaScreen = currentRoute.split('/')[1] === SIDE_NAV_NAME.CONTENT_MEDIA;
  const imageName = `AI Generated-${aiGeneratedImages[0]?.id}`;
  const isAIImageSelected = selectedMediaList.find((it) => it.name === imageName);

  // For disable generate buttons
  const disableAICaptionsGenerateBtn = !aiTextContent.content || isToneFetching || isCreatePostSuggestionsFetching;
  const disableAIImageGenerateBtn = !aiTextContent.image || isAIImageFetching;
  const disableGenerateBtn = selectedTab.parent === IAISugesstionTabs.CAPTIONS_AND_HASHTAGS ? disableAICaptionsGenerateBtn : disableAIImageGenerateBtn;

  // For enable ai results section
  const activeAIResultImages = selectedTab.parent === IAISugesstionTabs.IMAGES && (aiGeneratedImages.length || (isShowAiImageTab && assetsList.length) || isAIImageFetching || isPromptActive);
  const activeAIResultCaptions =
    selectedTab.parent === IAISugesstionTabs.CAPTIONS_AND_HASHTAGS && (createPostSuggestions.length || createPostHashtagSuggestions.length || isCreatePostSuggestionsFetching || isPromptActive);

  // To show ai done and add image buttons
  const enableImageGenerate =
    selectedTab.parent === IAISugesstionTabs.IMAGES && selectedMediaList.length && (isAIImageSelected || (isShowAiImageTab && !isAIImageFetching)) && !isPromptActive && activeAIResultImages;
  const enableCaptionGenerate =
    selectedTab.parent === IAISugesstionTabs.CAPTIONS_AND_HASHTAGS && (captionState.index !== null || captionState.selectedHashtags?.length) && !isPromptActive && activeAIResultCaptions;

  // To show ai tabs
  const isEnableImageTab =
    !(creatorPhotosCount(selectedMediaList) >= 500) &&
    !creatorHasVideo(selectedMediaList) &&
    !contentObj?.facebook_call_to_action_type &&
    !contentObj?.google_call_to_action_type &&
    !linkData &&
    aiImageAccess;
  const isDisableCaptionTab = isMediaScreen || !aiCaptionAccess;

  const aiSuggestionTab = useMemo(() => {
    if (!isEnableImageTab) {
      return AI_SUGESSTIONS_TAB.filter((suggestion) => suggestion.value !== IAISugesstionTabs.IMAGES);
    }
    if (isDisableCaptionTab) {
      // return AI_SUGESSTIONS_TAB.filter((suggestion) => suggestion.value !== IAISugesstionTabs.CAPTIONS_AND_HASHTAGS);
      return [];
    }
    return AI_SUGESSTIONS_TAB;
  }, [isEnableImageTab, isDisableCaptionTab]);

  const toggleSwitches = [
    { title: 'Generate Hashtags', switchKey: 'hashtags', checkedStatus: isHashtagsAndEmojis.isHashtags, label: 'Hashtags' },
    { title: 'Include Emoji', switchKey: 'emojis', checkedStatus: isHashtagsAndEmojis.isEmojis, label: 'Emojis' }
  ];

  useEffect(() => {
    if (!isShowAiImageTab) {
      if (isDisableCaptionTab) {
        dispatch(setCreatorAITabName({ parent: IAISugesstionTabs.IMAGES, child: IAIResultsTab.IMAGES }));
      } else {
        dispatch(setCreatorAITabName({ parent: IAISugesstionTabs.CAPTIONS_AND_HASHTAGS, child: IAIResultsTab.CONTENTS }));
      }
    }
  }, [isDisableCaptionTab, isShowAiImageTab, dispatch]);

  useEffect(() => {
    dispatch(
      aiGetCaptionsListRequest({
        user_id: activeUserId ? activeUserId : null,
        [`${userOwnership}_id`]: id,
        page: 1,
        per: 10,
        caption_type: selectedTab.parent === IAISugesstionTabs.IMAGES ? 'photo' : 'caption'
      })
    );
  }, [id, userOwnership, activeUserId, selectedTab.parent, dispatch]); // eslint-disable-line

  useEffect(() => {
    if (verticals && verticals.length) {
      const filteredBrandCategory = verticals.find((it) => it.id === ai_playbook.vertical_id);
      if (filteredBrandCategory?.value) setBrandCategory(filteredBrandCategory?.value);
    }
  }, [verticals, ai_playbook.vertical_id, generalDocuments]);

  const handleCancel = () => {
    dispatch(setAIPostContentOpen(false));
    if (isMediaScreen) {
      dispatch(resetAIImageGenerationImages());
      dispatch(setAITextContent(AI_TEXT_CONTENT_INITIAL_VALUE));
      navigate({ pathname: `/${userOwnership}/${id.toString()}/${currentRoute}/${optionalParams[0]}`, search: queryParams ? `?${new URLSearchParams(queryParams).toString()}` : `` });
    } else {
      dispatch(setAIHashtagsAndEmojis({ isHashtags: true, isEmojis: true }));
      dispatch(setCreatorAITabName({ parent: IAISugesstionTabs.CAPTIONS_AND_HASHTAGS, child: IAIResultsTab.CONTENTS }));
    }
    const data = { aiTags };
    dispatch(resetAIContentCreator(data));
    if (!isMediaScreen) {
      dispatch(mgResetAll());
    }
    dispatch(setAIPost(isAiPostUsed ? true : false));
  };

  const handleCreateCaption = () => {
    const captionText = selectedTab.parent === IAISugesstionTabs.CAPTIONS_AND_HASHTAGS ? aiTextContent.content : aiTextContent.image;
    const selectedRecentCaptionsList = captionsList.find((it) => it.caption_text === captionText);
    dispatch(
      aiCreateCaptionRequest({
        caption_text: captionText,
        user_id: activeUserId ? activeUserId : null,
        [`${userOwnership}_id`]: id,
        caption_type: selectedTab.parent === IAISugesstionTabs.IMAGES ? 'photo' : 'caption',
        ...(selectedRecentCaptionsList?.id && { caption_id: selectedRecentCaptionsList.id })
      })
    );
  };

  const handleSuccess = () => {
    dispatch(setAIPostUsed(true));
    dispatch(setAIPost(true));
    dispatch(setAIPostContentOpen(false));
    if (isMediaScreen) {
      navigate({ pathname: `/${userOwnership}/${id.toString()}/${currentRoute}/${optionalParams[0]}`, search: queryParams ? `?${new URLSearchParams(queryParams).toString()}` : `` });
    } else {
      if (isAIImageSelected) {
        dispatch(resetAIImageGenerationImages());
      }
      dispatch(setAIHashtagsAndEmojis({ isHashtags: true, isEmojis: true }));
      dispatch(setCreatorAITabName({ parent: IAISugesstionTabs.CAPTIONS_AND_HASHTAGS, child: IAIResultsTab.CONTENTS }));
    }
    dispatch(setAIInputContext(null));
    if (!isMediaScreen) {
      dispatch(mgResetAll());
    }
  };

  const handleContentGenerate = () => {
    dispatch(setAIPromptActive(false));
    if (selectedTab.parent === IAISugesstionTabs.IMAGES) {
      dispatch(setCreatorAITabName({ parent: selectedTab.parent, child: IAIResultsTab.IMAGES }));
    }
    if (aiTextContent.content || aiTextContent.image) {
      if (selectedTab.parent === IAISugesstionTabs.CAPTIONS_AND_HASHTAGS) {
        if (isCSLocation && Boolean(aiLimitation && +aiLimitation?.ai_caption_limit <= 0)) {
          dispatch(setCsLocationSubscriptionModalType('ai_caption'));
          dispatch(setAISubscriptionDetailsModal({ type: 'CSLocationAISubscription', isOpen: true }));
        } else {
          const customerPersonaFieldData = generateCustomerPersonaFieldData(customerPersonaText || '');
          dispatch(setCreatorAICaptions({ selectedHashtags: [], index: null }));
          handleCreateCaption();
          if (ai_playbook.ai_compliance_summary && isAICaptionValidationEnabled) {
            dispatch(
              aiValidateCaptionRequest({
                text: aiTextContent.content,
                compliance_summary: ai_playbook.ai_compliance_summary || '',
                preferred_language: userOwnership === USER_OWNERSHIP.ACCOUNT ? accountDetails?.preferred_language || '' : franchisorDetails?.preferred_language || '',
                type: ComplianceType.CAPTION
              })
            );
          }

          dispatch(
            getAIContentCreatorRequest({
              id,
              userOwnership,
              text: splitTextFromHashAndEmoji(aiTextContent.content).trim(),
              brandName: ai_playbook.name,
              customerAlias: ai_playbook.questions.customer_alias,
              businessSearch: ai_playbook.questions.business_search,
              brandCategory: brandCategory.replace(VALID_BRAND_CATEGORY_REGEX, '').trim(),
              documentUrls: generalDocuments.map((it: any) => it.url) ?? [],
              // compliance_document_urls: complianceDocuments.map((it: any) => it.url) ?? [],
              compliance_summary: ai_playbook.ai_compliance_summary || '',
              // ai_compliance_description: aiComplianceDescription || '',
              isHashtags: isHashtagsAndEmojis?.isHashtags,
              isEmojis: isHashtagsAndEmojis?.isEmojis,
              location,
              websiteUrl: '',
              customer_persona_description: customerPersonaText || '',
              customer_persona_usage_level: SETTINGS_CUSTOMER_PERSONA_USAGE_LEVELS[0].value,
              customer_persona_name: customerPersonaFieldData.name,
              customer_persona_occupation: customerPersonaFieldData.occupation,
              preferred_language: userOwnership === USER_OWNERSHIP.ACCOUNT ? accountDetails?.preferred_language || '' : franchisorDetails?.preferred_language || '',
              onAICaptionSuccessCallback: () => {
                if (isAILimitation) dispatch(aiUpdateLimitationRequest({ account_id: id, type: 'ai_caption' }));
              }

              // websiteUrl: ai_playbook.business_url
            })
          );
        }
      } else {
        if (isCSLocation && Boolean(aiLimitation && +aiLimitation?.ai_caption_limit <= 0)) {
          dispatch(setCsLocationSubscriptionModalType('ai_caption'));
          dispatch(setAISubscriptionDetailsModal({ type: 'CSLocationAISubscription', isOpen: true }));
        } else {
          const payload = { ...aiImgFilters };
          if (userOwnership === USER_OWNERSHIP.ACCOUNT) {
            delete payload.ai_compliance_image_summary;
          }
          handleCreateCaption();
          if (ai_playbook.ai_compliance_image_summary && isAICaptionValidationEnabled) {
            dispatch(
              aiValidateCaptionRequest({
                text: aiTextContent.image,
                compliance_summary: ai_playbook.ai_compliance_image_summary || '',
                preferred_language: userOwnership === USER_OWNERSHIP.ACCOUNT ? accountDetails?.preferred_language || '' : franchisorDetails?.preferred_language || '',
                type: ComplianceType.IMAGE
              })
            );
          }

          dispatch(
            getAIImageGenerationImagesRequest({
              image_description: aiTextContent.image,
              ...payload,
              onSuccess: () => {
                if (isAILimitation) dispatch(aiUpdateLimitationRequest({ account_id: id, type: 'ai_caption' }));
              }
            })
          );
        }
      }
    }
  };

  const handleStartOver = () => {
    dispatch(resetAIImageGenerationImages());
    dispatch(setAITextContent(AI_TEXT_CONTENT_INITIAL_VALUE));
    setAIImgFilters({
      image_resolution: AI_IMAGE_GENERATION_RESOLUTION[0].value,
      image_detail: AI_IMAGE_DETAIL_OPTIONS[0].value,
      image_style: AI_IMAGE_STYLE_OPTIONS[0].value,
      ai_compliance_image_summary: imageComplianceSummary || ''
    });
    dispatch(resetAICaptionHashtagGeneration());
    dispatch(setAIPost(isAiPostUsed ? true : false));
  };

  return (
    <div className="ai-wrap aimg-main">
      {/* new ui */}
      <Modal
        className={`ai-assistant${aiGeneratedImages.length === 1 ? ' single-ai-img' : ''} common-cm sai-media mediaAiImages sai-creator creatorAi cm-ai aiFeature${
          isShowAiImageTab ? ' sai-post' : ''
        }`}
        dialogClassName="modal-fullscreen"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isAiContentOpen || optionalParams[1] === AI_IMAGE_GENERATION_TAB_NAMES.CREATE_AI_IMAGE}
        animation={false}
        onHide={handleCancel}
      >
        <div
          className={`${activeAIResultImages || activeAIResultCaptions ? 'aia-bg-fill ai-results-active' : 'aia-bg-fill ai-results-Inactive'}${
            enableCaptionGenerate || enableImageGenerate ? ' ai-assets-selected' : ' ai-assets-not-selected'
          }`}
        >
          <div className="mod__close--icon">
            <ImageValidation defaultImg="mpcIcon" customClassname="close-icon close mod__close--img wh-30" customName={'close'} onClick={() => handleCancel()} />
          </div>
          <Modal.Title className="flex-content">
            <div className="logo-placeholder">
              <ImageValidation isImgValid defaultImg={AI_ICON_BG_IMG.DARK_BLUE} isNotSvgFormat customName="AI Assistant" />
              <h2>AI Assistant</h2>
            </div>
            <div className="ai-brt-head ag-tabs sleek-tab">
              <div className="ai-brt-group sub-nav-tabs">
                <ul>
                  {aiSuggestionTab.map((suggestion, index) => (
                    <li
                      key={`sugesstionTab-${index}`}
                      className={selectedTab.parent === suggestion.value ? `active` : ''}
                      onClick={() => {
                        dispatch(setAIPromptActive(false));
                        setRecentCaptions({ isOpen: false, id: null });
                        dispatch(
                          setCreatorAITabName({ parent: suggestion.value, child: suggestion.value === IAISugesstionTabs.CAPTIONS_AND_HASHTAGS ? IAIResultsTab.CONTENTS : IAIResultsTab.IMAGES })
                        );
                      }}
                    >
                      <span>{suggestion.label}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Modal.Title>
          <Modal.Body className="flex">
            <Card className="aia-content aia-left">
              <div className="pad-30 aia-left-top">
                <div className="topHeader">
                  <>
                    <h3>{selectedTab.parent === IAISugesstionTabs.IMAGES ? 'AI Image Editor' : 'AI Prompt Editor'}</h3>
                    <div className="aisi-wrap">
                      {isMediaScreen && (
                        <div className="startOver" onClick={handleStartOver}>
                          <span className="inline-txt">Start Over</span>
                          <div className="startOver-icon">
                            <ImageValidation isImgValid defaultImg="start-over" customName="Start over" />
                          </div>
                        </div>
                      )}

                      <CustomTooltip
                        customPlacement={TOOLTIP_PLACEMENT.TOP}
                        customTooltipText={'Click here to see how to get the best results from your AI Assistant'}
                        customClassname={'custom-tooltip-long-text r-tbl-header__tip contetn-an analytics-content-pg'}
                        customInput={() => (
                          <div className="infoIcon-divWrap">
                            <ImageValidation
                              isImgValid
                              defaultImg="info-blue"
                              customClassname={`${!isPromptActive ? '' : 'info-inactive'}`}
                              customName={'Best Results From AI Assistant'}
                              onClick={() => {
                                dispatch(setAIPromptActive(!isPromptActive));
                              }}
                            />
                          </div>
                        )}
                      />
                    </div>
                  </>
                </div>
                <div className="white-bg">
                  <div className="cm-ai-left-top">
                    <div className={`ai-prompt-editor ${recentCaptions.isOpen ? 'aipt-on' : 'aipt-off'}`}>
                      <div className="aip-toggle aip-tog-off">
                        <TextArea
                          placeholder={`Provide details about the ${
                            selectedTab.parent === IAISugesstionTabs.CAPTIONS_AND_HASHTAGS ? 'caption' : 'image'
                          } you want the AI to generate and click Generate ${selectedTab.parent === IAISugesstionTabs.CAPTIONS_AND_HASHTAGS ? 'Captions' : 'Image'}`}
                          id="exampleForm.ControlTextarea1"
                          className="form-control"
                          autoComplete="off"
                          onChange={(e) => {
                            setRecentCaptions({ ...recentCaptions, id: null });
                            dispatch(setAITextContent({ ...aiTextContent, [selectedTab.parent === IAISugesstionTabs.IMAGES ? 'image' : 'content']: e.target.value }));
                          }}
                          value={selectedTab.parent === IAISugesstionTabs.CAPTIONS_AND_HASHTAGS ? aiTextContent.content : aiTextContent.image}
                          maxRows={4}
                        />
                        <button
                          className="tog-btn"
                          onClick={() => {
                            const captionText = selectedTab.parent === IAISugesstionTabs.CAPTIONS_AND_HASHTAGS ? aiTextContent.content : aiTextContent.image;
                            const selectedRecentCaptionsList = captionsList.find((it) => it.caption_text === captionText);
                            setRecentCaptions({ isOpen: !recentCaptions.isOpen, id: selectedRecentCaptionsList ? selectedRecentCaptionsList?.id : null });
                          }}
                        >
                          <span className="tog-label">
                            View Recent Prompt <span className="rp-value">({captionsList?.length > 10 ? 10 : captionsList?.length})</span>
                          </span>
                        </button>
                      </div>
                      <div className="aip-toggle aip-tog-on">
                        <ul className="aip-items-group">
                          {captionsList?.map((it, index) => {
                            return (
                              <li
                                className={`aip-items${it.id === recentCaptions.id ? ' active' : ''}`}
                                key={`captions-${index}`}
                                onClick={() => {
                                  dispatch(setAITextContent({ ...aiTextContent, [selectedTab.parent === IAISugesstionTabs.IMAGES ? 'image' : 'content']: it.caption_text }));
                                  setRecentCaptions({ isOpen: !recentCaptions.isOpen, id: it.id });
                                }}
                              >
                                <p>{it.caption_text}</p>
                              </li>
                            );
                          })}
                        </ul>
                        <button className="tog-btn" onClick={() => setRecentCaptions({ isOpen: !recentCaptions.isOpen, id: recentCaptions.id })}>
                          <span className="tog-label">Less</span>
                        </button>
                      </div>
                    </div>

                    {selectedTab.parent === IAISugesstionTabs.IMAGES && (
                      <>
                        <div className="iar-wrap">
                          <h3>Image Aspect Ratio</h3>
                          <CustomDropdown
                            name="add_detail"
                            classNamePrefix="iar"
                            id="ai-image-aspect-ratio"
                            placeholder={'Select a day'}
                            // menuIsOpen={true}
                            options={AI_IMAGE_GENERATION_RESOLUTION}
                            value={AI_IMAGE_GENERATION_RESOLUTION.find((detail) => detail.value === aiImgFilters.image_resolution)}
                            onChange={(eventObj: any) => setAIImgFilters((prev) => ({ ...prev, image_resolution: eventObj.value }))}
                            isSearchable={false}
                          />
                        </div>
                        <div className="iar-wrap">
                          <h3>Image Style</h3>
                          <CustomDropdown
                            name="add_detail"
                            classNamePrefix="iar"
                            id="ai-image-aspect-ratio"
                            placeholder={'Select a day'}
                            // menuIsOpen={true}
                            options={AI_IMAGE_STYLE_OPTIONS}
                            value={AI_IMAGE_STYLE_OPTIONS.find((detail) => detail.value === aiImgFilters.image_style)}
                            onChange={(eventObj: any) => setAIImgFilters((prev) => ({ ...prev, image_style: eventObj.value }))}
                            isSearchable={false}
                          />
                        </div>
                        <div className="iar-wrap">
                          <h3>Image Detail</h3>
                          <CustomDropdown
                            name="add_detail"
                            classNamePrefix="iar"
                            id="ai-image-aspect-ratio"
                            placeholder={'Select a day'}
                            // menuIsOpen={true}
                            options={AI_IMAGE_DETAIL_OPTIONS}
                            value={AI_IMAGE_DETAIL_OPTIONS.find((detail) => detail.value === aiImgFilters.image_detail)}
                            onChange={(eventObj: any) => setAIImgFilters((prev) => ({ ...prev, image_detail: eventObj.value }))}
                            isSearchable={false}
                          />
                        </div>
                      </>
                    )}
                    {selectedTab.parent === IAISugesstionTabs.CAPTIONS_AND_HASHTAGS && (
                      <div className="tog-options">
                        {toggleSwitches.map((toggleSwitch) => (
                          <label key={toggleSwitch.switchKey}>
                            <span className="toggle-title">{toggleSwitch.title}</span>
                            <ToggleSwitch
                              id={'material-switch-12'}
                              handleOnChange={() => dispatch(setAIHashtagsAndEmojis({ ...isHashtagsAndEmojis, [`is${toggleSwitch.label}`]: !toggleSwitch.checkedStatus }))}
                              checkedStatus={toggleSwitch.checkedStatus}
                            />
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                  <CustomRippleButton rippleClass={`ac-secondary-box ai-generate-btn ${disableGenerateBtn ? 'pEvents-none' : ''}`} custColor={RIPPLE_COLOR.primary}>
                    <button className={`gnrc-btn blue-gnr ${disableGenerateBtn ? 'pEvents-none' : ''}`} disabled={disableGenerateBtn} onClick={handleContentGenerate}>
                      <div className="ai-asm-btn">
                        <ImageValidation isImgValid defaultImg={AI_ICON_BG_IMG.DARK_BLUE} isNotSvgFormat customName="AI Generate" />
                      </div>
                      <span>{selectedTab.parent === IAISugesstionTabs.CAPTIONS_AND_HASHTAGS ? 'Generate Captions!' : 'Generate Image!'}</span>
                    </button>
                  </CustomRippleButton>
                </div>
              </div>
            </Card>
            {activeAIResultImages || activeAIResultCaptions ? (
              <Card className="aia-content aia-right">
                <div className="aia-right-top">
                  <BotResults
                    selectedTextObj={selectedTextObj}
                    editedContent={editedContent}
                    handleSuccess={handleSuccess}
                    enableImageGenerate={enableImageGenerate}
                    enableCaptionGenerate={enableCaptionGenerate}
                    handleCreateCaption={handleCreateCaption}
                    isEnableImageTab={isEnableImageTab}
                  />
                </div>
              </Card>
            ) : isMediaScreen ? (
              <Card className="ai-coachMarks">
                <div className="aiac-wrap">
                  <h3>
                    Click
                    <ImageValidation
                      isImgValid
                      defaultImg="info-blue"
                      customClassname={``}
                      customName={'Best Results From AI Assistant'}
                      tooltip="Click here to see how to get the best results from your AI Assistant"
                    />
                    {AI_ASSISTANT_DEFAULT_TEXT}
                  </h3>
                </div>
              </Card>
            ) : null}
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};
