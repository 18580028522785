import { permissionsContainerTypes } from 'types';

export const PERMISSION_VIEW = {
  DEFAULT: 'default',
  CUSTOM: 'custom'
};

export const PERMISSION_VIEW_NAME = {
  CURRENT_VIEW: 'currentView'
};

export const PERMISSION_FIELD_TYPE: permissionsContainerTypes.IPermissionFieldType = {
  REVIEW_RESPONSE_LOCK: 'review_responses_locked',
  COPIED_PARENT_CONTENT_AUTO_APPROVE: 'copied_parent_contents_auto_approved',
  COPIED_CHILD_CONTENT_AUTO_APPROVE: 'copied_child_contents_auto_approved',
  FRANCHISEE_PERMISSIONS: 'franchisee_permissions',
  INBOX_RESPONSES_LOCKED: 'inbox_responses_locked',
  CUSTOM_POSTS_LOCKED: 'custom_posts_locked',
  CORPORATE_POSTS_LOCKED: 'corporate_posts_locked',
  CORPORATE_POST_AUTO_APPROVE: 'corporate_posts_auto_approved',
  CORPORATE_POST_AUTO_POSTED: 'corporate_posts_auto_posted',
  CORPORATE_POST_AUTO_OPTIONAL: 'corporate_posts_auto_optional',
  PROFILE_EDIT_LOCKED: 'profile_edit_locked',
  AI_MANUAL_REVIEW_RESPONDER: 'ai_review_response_setup_permission',
  AI_AUTO_REVIEW_RESPONDER: 'ai_auto_review_responder'
};

export const PERMISSION_TYPES = {
  SCH_POST: 'schPost' /** Turn On/Off Auto Approval & Auto Posting of Hub Content */,
  CORP_CONTENT: 'corpContent' /** Turn On/Off Individual Location Editing Abilities of Hub Content */,
  LOC_CONTENT: 'locContent' /** Turn On/Off Hub Required Approval of Individual Location Generated Content */,
  INBOX: 'inbox' /** Turn On/Off Each Individual Locations Ability to Respond to their Inbox */,
  REVIEW: 'review' /** Turn On/Off Individual Locations Ability to Respond to Reviews */,
  COPIED_CONTENT: 'copiedContent' /** Turn On/Off Auto Approval of Content Copied to Your Hubs */,
  PROFILE_EDIT: 'profileEdit' /** Turn On/Off Auto Approval of Content Copied to Your Hubs */,
  CONTENT_SUPPLIER: 'contentSupplier' /** Turn On/Off Auto Approval of Content from content suppliers */,
  MANULA_REVIEW_RESPONDER: 'manualReviewResponder' /** Turn On/Off Enable AI Auto Review Responder */,
  AUTO_REVIEW_RESPONDER: 'autoReviewResponder' /** Turn On/Off Enable AI Auto Review Responder */
};

export const ACCORDIAN_EVENTS = {
  EVENT_ZERO: '0',
  EVENT_ONE: '1',
  EVENT_TWO: '2',
  EVENT_THREE: '3',
  EVENT_FOUR: '4',
  EVENT_FIVE: '5',
  EVENT_SIX: '6',
  EVENT_SEVEN: '7',
  EVENT_EIGHT: '8',
  EVENT_NINE: '9'
};

export const ACCORD_EVENT_NAMES: permissionsContainerTypes.IAccordEventNames = {
  schPost: '0',
  corpContent: '1',
  locContent: '2',
  inbox: '3',
  review: '4',
  copiedContent: '5',
  profileEdit: '6',
  contentSupplier: '7',
  manualReviewResponder: '8',
  autoReviewResponder: '9'
};

export const PERMISSION_STATUS_INITIAL_VALUE: permissionsContainerTypes.IPermissionStatus = {
  [PERMISSION_TYPES.REVIEW]: { saveEnable: false, currentView: PERMISSION_VIEW.DEFAULT },
  [PERMISSION_TYPES.INBOX]: { saveEnable: false, currentView: PERMISSION_VIEW.DEFAULT },
  [PERMISSION_TYPES.LOC_CONTENT]: { saveEnable: false, currentView: PERMISSION_VIEW.DEFAULT },
  [PERMISSION_TYPES.CORP_CONTENT]: { saveEnable: false, currentView: PERMISSION_VIEW.DEFAULT },
  [PERMISSION_TYPES.SCH_POST]: { saveEnable: false, currentView: PERMISSION_VIEW.DEFAULT },
  [PERMISSION_TYPES.COPIED_CONTENT]: { saveEnable: false, currentView: PERMISSION_VIEW.DEFAULT },
  [PERMISSION_TYPES.PROFILE_EDIT]: { saveEnable: false, currentView: PERMISSION_VIEW.DEFAULT },
  [PERMISSION_TYPES.CONTENT_SUPPLIER]: { saveEnable: false, currentView: PERMISSION_VIEW.DEFAULT },
  [PERMISSION_TYPES.MANULA_REVIEW_RESPONDER]: { saveEnable: false, currentView: PERMISSION_VIEW.DEFAULT },
  [PERMISSION_TYPES.AUTO_REVIEW_RESPONDER]: { saveEnable: false, currentView: PERMISSION_VIEW.DEFAULT }
};

export const LOCATION_PERMISSION_TABLE_VALUES = {
  INDIVIDUAL: 'individual',
  PERMISSION: 'permission',
  AUTO_APPROVE: 'auto_approve',
  AUTO_POST: 'auto_post',
  OPTIONAL_APPROVE: 'optional_approve'
};

export const LOCATION_PERMISSION_TABLE_HEADER = [
  { title: 'Individual', value: LOCATION_PERMISSION_TABLE_VALUES.INDIVIDUAL },
  { title: 'Permission', value: LOCATION_PERMISSION_TABLE_VALUES.PERMISSION }
];

export const SCHEDULE_LOCATION_PERMISSION_HEADER = [
  { title: 'Individual', value: LOCATION_PERMISSION_TABLE_VALUES.INDIVIDUAL },
  { title: 'Automated Approval', value: LOCATION_PERMISSION_TABLE_VALUES.AUTO_APPROVE },
  { title: 'Manual Approval', value: LOCATION_PERMISSION_TABLE_VALUES.AUTO_POST },
  { title: 'Optional Approval', value: LOCATION_PERMISSION_TABLE_VALUES.OPTIONAL_APPROVE }
];
