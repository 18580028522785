export const BRAND_PROFILE_SETTINGS_SECTIONS = {
  PREFERRED_LANGUAGE: 'preferred_language',
  LOGO_URLS: 'logo_urls',
  FEED_BADGE_URL: 'feed_badge_url'
};

export const BRAND_PROFILE_IMAGE_TYPES = {
  BRAND_LOGO: 'brand_logo',
  FEED_BADGE_LOGO: 'feed_badge_logo'
};
