import { put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import {
  logoutRequest,
  exceptionHandlerRequest,
  getPostsStatsResponse,
  getPostsStatsRequestFailure,
  getPostsResponse,
  getPostsRequestFailure,
  postsTagsResponse,
  postsTagsResponseFailure,
  deletePostsResponse,
  deletePostsResponseFailure,
  copyPostsResponse,
  copyPostsResponseFailure,
  getPostsDetailsResponse,
  getPostsDetailsRequestFailure,
  getPostsInternalNotesListResponse,
  getPostsInternalNotesListFailure,
  postsDetailAddInternalNotesResponse,
  addPostAddInternalNotesFailure,
  postsDetailAddInternalNotesFailure,
  postsEditContentDraftResponse,
  postsEditContentDraftFailure,
  addPostGetInternalNotesResponse,
  addPostGetInternalNotesListFailure,
  addPostAddInternalNotesResponse,
  getPostsLocationContentResponse,
  getPostsLocationContentFailure,
  postsUpdateSavedPostResponse,
  postsUpdateSavedPostFailure,
  rejectSavedPostFromHubResponse,
  rejectSavedPostFromHubFailure,
  getPostsInternalNotesListRequest,
  postPublishedStatusResponse,
  updatePostDetailResponse,
  updatePostDetailFailure,
  getPostsTagsStatsResponse,
  getPostsTagsStatsFailure,
  getMultiSelectPostsListResponse,
  getPostPerformanceRateResponse,
  getPostPerformanceRateFailure
} from 'actions';
import {
  USER_OWNERSHIP,
  POST_FETCHING_STATS_FAILED,
  POST_EXCEPTION_ERROR,
  PAGINATION_PER_PAGE,
  POST_FETCHING_LIST_FAILED,
  POSTS_REJECT_SAVED_POST_FAILED,
  POST_DELETE_SUCCESS_MSG,
  POST_DELETE_FAILURE_MSG,
  POST_FETCHING_TAG_LIST_FAILED,
  POST_COPY_FAILURE_MSG,
  POST_FETCHING_DETAIL_DATA_FAILED,
  POST_DETAIL_INT_NOTES_LIST_FAILURE_MSG,
  POST_DETAIL_ADD_INT_NOTE_FAILURE_MSG,
  POST_EDIT_CONTENT_DRAFT_FAILURE_MSG,
  AP_INTERNAL_NOTES_TYPES,
  ADD_POST_INTERNAL_NOTES_FAILED,
  POST_EDIT_CONTENT_EXPIRE_DATE_FAILURE_MSG,
  POST_FETCHING_TAG_STATS_FAILED,
  ALERT_BOX_TYPES,
  SETTINGS_SUCCESS_MSG,
  SETTINGS_FAILURE_MSG
} from 'utils/constants';
import { apiCall, defaultHeader, API } from 'utils/helpers';
import { commonApiTypes, postsApiTypes } from 'types';
import { alertBoxCall } from 'components';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendPostsStatsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params: postsApiTypes.IPostListReqParams = {
      ...(payload.tags && { tags: payload.tags }),
      ...(payload.text && { text: payload.text }),
      // has_photo: payload.has_photo,
      // has_link: payload.has_link,
      // has_copy: payload.has_copy,
      has_long_text: payload.has_long_text,
      has_short_text: payload.has_short_text,
      // has_facebook: payload.has_facebook,
      // has_instagram: payload.has_instagram,
      // has_google: payload.has_google,
      // has_linkedin: payload.has_linkedin,
      // has_twitter: payload.has_twitter,
      date_range: payload.dateRange,
      ai_post: payload.ai_post,
      only_available_and_boosted: payload.isBoostedPosts ? 1 : 0
    };
    let response;
    let path;
    if (payload.userOwnership === USER_OWNERSHIP.ACCOUNT) {
      params.trashed = 0;
      params.account_id = payload.id;
      path = API.getPostsStats.apiPath;
      response = yield apiCall({ headers, params, apiPath: path, action: API.getPostsStats.action });
    } else if (payload.userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      params.sent_to_franchisor = 1;
      params.not_blank = 1;
      params.franchisor_id = payload.id;
      path = API.getPostsContentsStats.apiPath;
      response = yield apiCall({ headers, params, apiPath: path, action: API.getPostsContentsStats.action });
    }
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getPostsStatsResponse(response.data));
    } else {
      yield put(getPostsStatsRequestFailure(POST_FETCHING_STATS_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_EXCEPTION_ERROR));
  }
}

function* sendPostsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params: postsApiTypes.IPostListReqParams = {
      ...(!payload.postsDataId && !payload.type ? (payload?.campaign_id ? { per: 15 } : { per: PAGINATION_PER_PAGE }) : null),
      // ...(payload.after && { after: payload.after }),
      ...(payload.before && { before: payload.before }),
      ...(payload.reverse && { reverse: payload.reverse }),
      ...(payload.tags && { tags: payload.tags }),
      ...(payload.text && { text: payload.text }),
      has_photo: payload.has_photo,
      has_link: payload.has_link,
      has_copy: payload.has_copy,
      has_long_text: payload.has_long_text,
      has_short_text: payload.has_short_text,
      has_facebook: payload.has_facebook,
      has_instagram: payload.has_instagram,
      has_google: payload.has_google,
      has_linkedin: payload.has_linkedin,
      has_twitter: payload.has_twitter,
      has_tiktok: payload.has_tiktok,
      date_range: payload.dateRange,
      ...(payload.status_id && payload.status_id.length && { status_id: payload.status_id }),
      ...(payload.rejected !== undefined && { rejected: payload.rejected }),
      ...(!payload.postsDataId && { only_available_and_boosted: payload.isBoostedPosts ? 1 : 0 }),
      ai_post: payload.ai_post,
      published: payload.published,
      ...(payload.after && { after: payload.after }),
      ...(payload.cs_franchisor_id && { cs_franchisor_id: payload.cs_franchisor_id }),
      ...(payload?.source && { source: payload.source }),
      ...(payload.syndicated_to_any_franchisor && { syndicated_to_any_franchisor: payload.syndicated_to_any_franchisor }),
      ...(payload.syndicated_to_any_account && { syndicated_to_any_account: payload.syndicated_to_any_account })
    };
    let response;
    let path;
    if (payload.userOwnership === USER_OWNERSHIP.ACCOUNT) {
      if (payload.postsDataId) {
        path = API.getPostsDetail.apiPath.replace(':id', `${payload.postsDataId}`);
        response = yield apiCall({ headers, params, apiPath: path, action: API.getPostsDetail.action });
      } else {
        params.trashed = 0;
        params.account_id = payload.id;
        path = API.getSavedPosts.apiPath;
        response = yield apiCall({ headers, params, apiPath: path, action: API.getSavedPosts.action });
      }
    } else if (payload.userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      if (payload.postsDataId) {
        path = API.getContentPostDetail.apiPath.replace(':id', `${payload.postsDataId}`);
        response = yield apiCall({ headers, params, apiPath: path, action: API.getContentPostDetail.action });
      } else {
        params.sent_to_franchisor = 1;
        params.franchisor_id = payload.id;
        params.not_blank = 1;
        path = API.getContentPosts.apiPath;
        response = yield apiCall({ headers, params, apiPath: path, action: API.getContentPosts.action });
      }
    }
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (payload.postsDataId) {
        yield put(getPostsDetailsResponse(response.data));
      } else {
        response.data.page = payload.page;
        if (payload?.isMultiSelect) {
          yield put(getMultiSelectPostsListResponse(response.data));
        } else {
          yield put(getPostsResponse(response.data));
        }
      }
    } else {
      if (payload.postsDataId) {
        yield put(getPostsDetailsRequestFailure(POST_FETCHING_DETAIL_DATA_FAILED));
      } else {
        yield put(getPostsRequestFailure(POST_FETCHING_LIST_FAILED));
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_EXCEPTION_ERROR));
  }
}

function* sendPostsLocationContentRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params: postsApiTypes.IPostListReqParams = {
      ...(!payload.postsDataId && !payload.type && { per: PAGINATION_PER_PAGE }),
      ...(payload.after && { after: payload.after }),
      ...(payload.before && { before: payload.before }),
      ...(payload.reverse && { reverse: payload.reverse }),
      ...(payload.tags && { tags: payload.tags }),
      ...(payload.ai_post && { ai_post: payload.ai_post }),
      ...(payload.published && { published: 1 }),
      ...(payload.notpublished && { published: 0 }),
      ...(payload.text && { text: payload.text }),
      has_photo: payload.has_photo,
      has_link: payload.has_link,
      has_copy: payload.has_copy,
      has_long_text: payload.has_long_text,
      has_short_text: payload.has_short_text,
      has_facebook: payload.has_facebook,
      has_instagram: payload.has_instagram,
      has_google: payload.has_google,
      has_linkedin: payload.has_linkedin,
      has_twitter: payload.has_twitter,
      date_range: payload.dateRange,
      ...(payload.status_id && payload.status_id.length && { status_id: payload.status_id }),
      ...(payload.rejected !== undefined && { rejected: payload.rejected }),
      ...(!payload.postsDataId && { only_available_and_boosted: payload.isBoostedPosts ? 1 : 0 })
    };
    params.trashed = 0;
    params.ancestor_franchisor_id = payload.id;
    const response = yield apiCall({ headers, params, ...API.getSavedPosts });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      response.data.page = payload.page;
      yield put(getPostsLocationContentResponse(response.data));
    } else {
      yield put(getPostsLocationContentFailure(POST_FETCHING_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_EXCEPTION_ERROR));
  }
}

function* sendPostsTagsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      per: payload?.per || 20,
      page: payload?.page,
      franchisor_id: payload?.franchisor_id,
      include_usage_by_original_franchisor_ids: payload?.include_usage_by_original_franchisor_ids,
      ...(payload.contentSupplierId ? { content_supplier_franchisor_id: payload.contentSupplierId, prefix: payload.searchTag } : { prefix: payload.searchTag }),
      ...(!payload.contentSupplierId && { used_only: true })
    };
    const response = yield apiCall({ headers, params, ...API.getPostsTags });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(postsTagsResponse({ page: payload?.page || 0, response: response.data.tags }));
    } else {
      yield put(postsTagsResponseFailure(POST_FETCHING_TAG_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_EXCEPTION_ERROR));
  }
}

function* sendDeletePostRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    let response;
    if (payload.userOwnership === USER_OWNERSHIP.ACCOUNT) {
      path = API.deletePosts.apiPath.replace(':id', payload.postsId);
      const data = {
        saved_post: { trashed: true }
      };
      response = yield apiCall({ headers, data, apiPath: path, action: API.deletePosts.action });
    } else if (payload.userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      path = API.deleteAdminPosts.apiPath.replace(':id', payload.postsId);
      response = yield apiCall({ headers, apiPath: path, action: API.deleteAdminPosts.action });
    }
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(deletePostsResponse({ message: POST_DELETE_SUCCESS_MSG, id: payload.postsId }));
    } else {
      yield put(deletePostsResponseFailure({ message: POST_DELETE_FAILURE_MSG }));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_EXCEPTION_ERROR));
  }
}

function* sendCopyPostRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = payload.contentId ? API.copyAdminPosts.apiPath.replace(':id', payload.contentId) : API.copyLocationPosts.apiPath.replace(':id', payload.savedPostId);
    const response = yield apiCall({ headers, apiPath: path, action: API.copyAdminPosts.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(copyPostsResponse(payload.contentId ? response.data.content : response.data.saved_post));
    } else {
      yield put(copyPostsResponseFailure(POST_COPY_FAILURE_MSG));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_EXCEPTION_ERROR));
  }
}

function* sendGetPostDetailIntNotesListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    let response;
    if (payload.userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      const params = {
        content_id: payload.postsDataId,
        order: 'id'
      };
      response = yield apiCall({ headers, params, ...API.getContentIntNotes });
    } else {
      path = API.getSavedPostComments.apiPath.replace(':id', payload.postsDataId);
      response = yield apiCall({ headers, apiPath: path, action: API.getSavedPostComments.action });
    }
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (payload.type === AP_INTERNAL_NOTES_TYPES.SAVED_POST) {
        yield put(addPostGetInternalNotesResponse(response.data.saved_post_comments));
      } else {
        yield put(getPostsInternalNotesListResponse(payload.userOwnership === USER_OWNERSHIP.FRANCHISOR ? response.data.content_comments : response.data.saved_post_comments));
      }
    } else {
      if (payload.type === AP_INTERNAL_NOTES_TYPES.SAVED_POST) {
        yield put(addPostGetInternalNotesListFailure(ADD_POST_INTERNAL_NOTES_FAILED));
      } else {
        yield put(getPostsInternalNotesListFailure(POST_DETAIL_INT_NOTES_LIST_FAILURE_MSG));
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_EXCEPTION_ERROR));
  }
}

function* sendSavePostDetailsInternalNoteRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    let response;
    if (payload.userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      const bodyFormData = new FormData();
      bodyFormData.append('content_comment[body]', payload.customText);
      bodyFormData.append('content_comment[content_id]', payload.postDataId);
      response = yield apiCall({ headers, data: bodyFormData, ...API.addContentIntNotes });
    } else {
      const bodyFormData = new FormData();
      bodyFormData.append('saved_post_comment[body]', payload.customText);
      path = API.addSavedPostComments.apiPath.replace(':id', payload.postDataId);
      response = yield apiCall({ headers, data: bodyFormData, apiPath: path, action: API.addSavedPostComments.action });
    }
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (payload.type === AP_INTERNAL_NOTES_TYPES.SAVED_POST) {
        yield put(addPostAddInternalNotesResponse(response.data.saved_post_comment.id));
        yield put(
          getPostsInternalNotesListRequest({
            postsDataId: payload.postDataId,
            userOwnership: USER_OWNERSHIP.ACCOUNT,
            type: AP_INTERNAL_NOTES_TYPES.SAVED_POST
          })
        );
      } else {
        yield put(postsDetailAddInternalNotesResponse(payload.userOwnership === USER_OWNERSHIP.FRANCHISOR ? response.data.content_comment : response.data.saved_post_comment));
      }
    } else {
      if (payload.type === AP_INTERNAL_NOTES_TYPES.SAVED_POST) {
        yield put(addPostAddInternalNotesFailure(ADD_POST_INTERNAL_NOTES_FAILED));
      } else {
        yield put(postsDetailAddInternalNotesFailure(POST_DETAIL_ADD_INT_NOTE_FAILURE_MSG));
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_EXCEPTION_ERROR));
  }
}

function* sendPostsEditContentDraftRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.editContentDraftPost.apiPath.replace(':id', payload.contentId);
    const data = {
      content: payload.reqBody
    };
    const response = yield apiCall({ headers, data, apiPath: path, action: API.editContentDraftPost.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(postsEditContentDraftResponse(response.data.content));
    } else {
      yield put(postsEditContentDraftFailure(POST_EDIT_CONTENT_EXPIRE_DATE_FAILURE_MSG));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_EXCEPTION_ERROR));
  }
}

function* sendUpdatePostDetailRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const isAccount = payload?.userOwnership === USER_OWNERSHIP.ACCOUNT;
    const apiObj = isAccount ? API.editSavedPostExpireDate : API.editContentPostExpireDate;
    apiObj.apiPath = apiObj.apiPath.replace(':id', payload.contentId);
    const data = {
      [isAccount ? 'saved_post' : 'content']: payload.reqBody
    };
    const response = yield apiCall({ headers, data, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(updatePostDetailResponse(isAccount ? response.data?.saved_post : response.data?.content));
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, SETTINGS_SUCCESS_MSG);
    } else {
      yield put(updatePostDetailFailure(POST_EDIT_CONTENT_DRAFT_FAILURE_MSG));
      alertBoxCall(ALERT_BOX_TYPES.ERROR, SETTINGS_FAILURE_MSG);
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_EXCEPTION_ERROR));
  }
}

function* sendUpdateSavedPostRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = payload?.type === 'approve' ? API.approvePost.apiPath.replace(':id', payload.id) : API.updateSavedPost.apiPath.replace(':id', payload.id);
    const { id, ...newPayload } = payload;
    const data = { saved_post: newPayload };
    const response = yield apiCall({ headers, data, apiPath: path, action: payload?.type === 'approve' ? API.approvePost.action : API.updateSavedPostSingleField.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(postsUpdateSavedPostResponse(response.data.saved_post));
    } else {
      yield put(postsUpdateSavedPostFailure(POST_EDIT_CONTENT_DRAFT_FAILURE_MSG));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_EXCEPTION_ERROR));
  }
}

function* sendRejectSavedPostFromHubRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.rejectSavedPost.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, apiPath: path, action: API.rejectSavedPost.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(rejectSavedPostFromHubResponse(response.data.saved_post));
    } else {
      yield put(rejectSavedPostFromHubFailure(POSTS_REJECT_SAVED_POST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_EXCEPTION_ERROR));
  }
}

function* sendPostPublishedStatusRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = `${API.postPublishedStatus.apiPath}?${payload.userOwnership === USER_OWNERSHIP.ACCOUNT ? `saved_post_id=${payload.id}` : `content_id=${payload.id}`}`;
    const response = yield apiCall({ headers, apiPath: path, action: API.postPublishedStatus.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(postPublishedStatusResponse(response.data.scheduled_post_statuses));
    } else {
      yield put(postPublishedStatusResponse(response.data));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_EXCEPTION_ERROR));
  }
}

function* sendPostsTagsStatsRequest(): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, ...API.getPostsTagsStats });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getPostsTagsStatsResponse(response.data));
    } else {
      yield put(getPostsTagsStatsFailure(POST_FETCHING_TAG_STATS_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_EXCEPTION_ERROR));
  }
}

function* sendGetPostPerformanceRateRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, ...API.getPostPerformanceRate, params: payload });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getPostPerformanceRateResponse(response.data.post_performance_rate));
    } else {
      yield put(getPostPerformanceRateFailure(response.message));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_EXCEPTION_ERROR));
  }
}

export function* takePostsRequest() {
  yield takeLatest(types.POSTS_STATS_REQUEST, sendPostsStatsRequest);
  yield takeLatest(types.POSTS_LIST_REQUEST, sendPostsListRequest);
  yield takeLatest(types.GET_MULTI_SELECT_POSTS_LIST_REQUEST, sendPostsListRequest);
  yield takeEvery(types.POSTS_LOCATION_CONTENT_REQUEST, sendPostsLocationContentRequest);
  yield takeEvery(types.POSTS_TAGS_REQUEST, sendPostsTagsRequest);
  yield takeLatest(types.POSTS_DELETE_REQUEST, sendDeletePostRequest);
  yield takeLatest(types.POSTS_COPY_REQUEST, sendCopyPostRequest);
  yield takeEvery(types.POSTS_DETAIL_INT_NOTES_LIST_REQUEST, sendGetPostDetailIntNotesListRequest);
  yield takeLatest(types.POSTS_DETAIL_ADD_INTERNAL_NOTE_REQUEST, sendSavePostDetailsInternalNoteRequest);
  yield takeLatest(types.POSTS_EDIT_CONTENT_DRAFT_REQUEST, sendPostsEditContentDraftRequest);
  yield takeLatest(types.POSTS_DETAIL_UPDATE_REQUEST, sendUpdatePostDetailRequest);
  yield takeLatest(types.POSTS_UPDATE_SAVED_POST_REQUEST, sendUpdateSavedPostRequest);
  yield takeLatest(types.POSTS_REJECT_SAVED_POST_REQUEST, sendRejectSavedPostFromHubRequest);
  yield takeLatest(types.POSTS_PUBLISHED_STATUS_REQUEST, sendPostPublishedStatusRequest);
  yield takeLatest(types.GET_POSTS_TAGS_STATS_REQUEST, sendPostsTagsStatsRequest);
  yield takeLatest(types.GET_POST_PERFORMANCE_RATE_REQUEST, sendGetPostPerformanceRateRequest);
}
