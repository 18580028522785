import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';

import { ImageValidation } from 'widgets/Media';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { IStore, commonModalPopup, communicationReducerTypes } from 'types';
import { CALENDAR_EVENT_DATE_FORMAT, RIPPLE_COLOR, USER_OWNERSHIP } from 'utils/constants';
import { getFormatBasedOnExactDate } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { communicationMarkAsSeenRequest } from 'actions';
import { Loading } from 'components/Common/Loading';

export const ListCommunications = ({
  setShowListCommunications,
  setDeleteConfirmationModalOpen,
  setSelectedCommunication,
  setShowCreateCommunicationModal,
  communicationRef
}: commonModalPopup.ICommunications) => {
  const dispatch = useDispatch();

  const { userOwnership } = useAccountSwitcherData();
  const isHubUser = userOwnership === USER_OWNERSHIP.FRANCHISOR;

  const isFetching = useSelector((state: IStore) => state.communications.isFetching);
  const communicationsList = useSelector((state: IStore) => state.communications.communicationList);

  const handleSelectItem = (item: communicationReducerTypes.ICommunicationList, isUpdate = false) => {
    setSelectedCommunication(item);
    if (isUpdate) {
      setShowCreateCommunicationModal(true);
    } else {
      setDeleteConfirmationModalOpen(true);
    }
  };

  // if (isFetching) {
  //   return <Loading />;
  // }
  return (
    <>
      <div className="communicationWrap" ref={communicationRef}>
        <div className="cTopArw">
          <ImageValidation
            isImgValid
            defaultImg={'arw-up-notify'}
            customName={'Communications'}
            customClassname="ctArw"
            onClick={() => {
              setShowListCommunications(false);
            }}
          />
        </div>
        <div className="com-nm-header">
          <h2>Communications</h2>
          {userOwnership === USER_OWNERSHIP.FRANCHISOR && (
            <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
              <Button
                className={`ac-btn ac-primary`}
                onClick={() => {
                  setShowCreateCommunicationModal(true);
                }}
              >
                <span>New</span>
              </Button>
            </CustomRippleButton>
          )}
        </div>
        <div className="com-nm-wrap">
          {isFetching ? (
            <Loading />
          ) : communicationsList?.length ? (
            communicationsList.map((item, index) => (
              <div
                className={`com-nm-item ${!item.seen ? 'cnm-highlight ' : ''}${isHubUser ? 'pointer' : ''}`}
                key={`communication-list-${index}`}
                onClick={() => {
                  if (userOwnership === USER_OWNERSHIP.FRANCHISOR && item.can_update) {
                    handleSelectItem(item, true);
                  } else {
                    if (!item.seen) {
                      dispatch(communicationMarkAsSeenRequest(item.id));
                    }
                  }
                }}
              >
                <div className="item-top-header">
                  <div className="whenCom">
                    <h2>{item.franchisor_name}</h2>
                    <div className="nm-post-details">
                      <span className="wc-date">{getFormatBasedOnExactDate(item.updated_at || item.created_at, CALENDAR_EVENT_DATE_FORMAT)}</span>
                      {/* <span className="wc-time">@ 2:13 PM</span> */}
                    </div>
                  </div>
                  {userOwnership === USER_OWNERSHIP.FRANCHISOR && item.can_update ? (
                    <div className="nm-delete">
                      <ImageValidation
                        isImgValid
                        defaultImg="delete-bright-red"
                        customName="Delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSelectItem(item);
                        }}
                      />
                    </div>
                  ) : null}
                </div>
                <p>{item.body}</p>
                {userOwnership === USER_OWNERSHIP.FRANCHISOR ? (
                  <div className={item.seen ? 'nm-seen' : 'not-seen'}>
                    <ImageValidation isImgValid defaultImg="tick-message-seen" customName="SeenConfirmation" />
                  </div>
                ) : null}
              </div>
            ))
          ) : (
            <p>No Message Found.</p>
          )}
        </div>
      </div>
      {/* {showCreateCommunicationModal && userOwnership === USER_OWNERSHIP.FRANCHISOR && (
        <CreateCommunications
          isShowModal={showCreateCommunicationModal}
          onModalClose={() => {
            setShowCreateCommunicationModal(false);
            setSelectedCommunication(null);
          }}
          communicationData={selectedCommunication}
        />
      )}
      {deleteConfirmationModalOpen && (
        <DeleteConfirmationModal
          isModalShow={deleteConfirmationModalOpen}
          onClose={() => {
            setDeleteConfirmationModalOpen(false);
            setSelectedCommunication(null);
          }}
          onDelete={() => {
            if (deleteConfirmationModalOpen && selectedCommunication) {
              setDeleteConfirmationModalOpen(false);
              handlecommunicationDelete();
            }
          }}
          deleteMsg={COMMUNICATION_DELETE_CONFIRM_MSG}
        />
      )} */}
    </>
  );
};
