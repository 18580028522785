import { Modal } from 'react-bootstrap';

import { ImageValidation } from 'widgets/Media';
import { RenderPublishStatusTable } from '../SubPages';

export const PostPublishStatusModal = ({ isShowModal, onModalClose }: { isShowModal: boolean; onModalClose: () => void }) => {
  return (
    <>
      <Modal
        // className="creator-tag-modal-wrp schedule-post-modal-hubuser schedule-post__main syndicate-post-modal-hubuser"
        className="glbl__modal--wrp syndicate-post-modal-hubuser syndicate__modal--wrp dps-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isShowModal}
        animation={false}
        onHide={onModalClose}
      >
        <Modal.Body>
          <div className="sec-header multi-headItems">
            <div className="header-item__wrp">
              <span className="title">Publishing Status</span>
              <div className="mod__close--icon">
                <ImageValidation isImgValid defaultImg={'close-creator'} customName={'close'} customClassname="mod__close--img" onClick={onModalClose} />
              </div>
            </div>
          </div>
          <RenderPublishStatusTable />
        </Modal.Body>
      </Modal>
    </>
  );
};
