import moment, { Moment } from 'moment-timezone';

import { ICommonDateRange } from 'types/common/common-props';
import { ICommonDateTypes, UTC_TIMESTAMP_FORMAT } from 'utils/constants';

export const checkDayIsEqual = (date: Date | null, checkDate: Date | string | null) => {
  return date && checkDate ? moment(date).isSame(checkDate) : false;
};

export const utcToLocalHandler = (date: number | Date | null | string, formatStr?: string) => {
  if (formatStr) {
    return moment.utc(date).local().format(formatStr);
  } else {
    return moment.utc(date).local().valueOf();
  }
};

export const subtractDayHandler = (date: number | Date | null, count: number, formatStr: string = '') => {
  if (date) {
    const result = moment(date).subtract(count, 'day');
    return formatStr ? result.format(formatStr) : result;
  }
};

export const getDiffDateHandler = (date: number | Date | null | Moment, count: number) => {
  return moment(date).diff(count, 'days');
};

export const dateEqualHandler = (date: string | number | Date | null, curr: any, formatStr: string, range: string = '') => {
  if (date && curr) {
    if (range === 'after') {
      return moment(date).isSameOrAfter(moment(curr).format(formatStr));
    } else if (range === 'before') {
      return moment(date).isSameOrBefore(curr.format(formatStr));
    } else {
      return moment(date).isSame(moment(curr).format(formatStr));
    }
  }
};

export const dateHelperForWeek = (isPrev = false): ICommonDateRange => {
  const today = isPrev ? moment().subtract(1, 'd').endOf('day') : moment();
  const weekOrMonthBeforeToday = moment().subtract(1, 'week').startOf('day');
  return {
    [ICommonDateTypes.FROM_DATE]: {
      selected: weekOrMonthBeforeToday.toDate(),
      timeStamp: weekOrMonthBeforeToday.format(UTC_TIMESTAMP_FORMAT)
    },
    [ICommonDateTypes.TO_DATE]: {
      selected: today.toDate(),
      timeStamp: today.format(UTC_TIMESTAMP_FORMAT)
    }
  };
};

export const dateHelperForMonth = (isPrev = false): ICommonDateRange => {
  const today = isPrev ? moment().subtract(1, 'd').endOf('day') : moment();
  const weekOrMonthBeforeToday = moment().subtract(1, 'month').startOf('day');
  return {
    [ICommonDateTypes.FROM_DATE]: {
      selected: weekOrMonthBeforeToday.toDate(),
      timeStamp: weekOrMonthBeforeToday.format(UTC_TIMESTAMP_FORMAT)
    },
    [ICommonDateTypes.TO_DATE]: {
      selected: today.toDate(),
      timeStamp: today.format(UTC_TIMESTAMP_FORMAT)
    }
  };
};

export const dateFormatter = (date: string | Date | number | null, format?: string) => {
  if (format) {
    return moment(date).format(format);
  } else {
    return moment(date).format();
  }
};

export const convertMsTimeFormat = (time: number, isSplitString: boolean = false, isMs: boolean = false) => {
  let temp = Math.abs(time) / (isMs ? 1000 : 1);
  const years = Math.floor(temp / 31536000);
  const days = Math.floor((temp %= 31536000) / 86400);
  const hours = Math.floor((temp %= 86400) / 3600);
  const minutes = Math.floor((temp %= 3600) / 60);
  const seconds = temp % 60;

  // eslint-disable-next-line
  const dateString = `${years > 0 ? years : ''}${years > 0 ? 'y' : ''} ${days > 0 ? days : ''}${days > 0 ? 'd' : ''} ${hours > 0 ? hours : ''}${hours > 0 ? 'h' : ''} ${minutes > 0 ? minutes : ''}${
    minutes > 0 ? 'm' : ''
  } ${Number.parseFloat(seconds.toFixed(0).toString())}s`.trim();
  const splitedString = dateString.split(' ');
  return isSplitString ? `${splitedString[0]} ${splitedString[1] || ''}` : dateString;
};

export const convertMsHourMinsSecFormat = (time: number, isSplitString: boolean = false, isMs: boolean = false) => {
  let temp = Math.abs(time) / (isMs ? 1000 : 1);
  const hours = Math.floor(temp / 3600);
  const minutes = Math.floor((temp %= 3600) / 60);
  const seconds = temp % 60;

  // eslint-disable-next-line
  const dateString = `${hours > 0 ? hours : ''}${hours > 0 ? 'h' : ''} ${minutes > 0 ? minutes : ''}${minutes > 0 ? 'm' : ''} ${Number.parseFloat(seconds.toFixed(0).toString())}s`.trim();
  const splitedString = dateString.split(' ');
  return isSplitString ? `${splitedString[0]} ${splitedString[1] || ''}` : dateString;
};

export const convertChartMsTimeFormat = (time: number, isMs: boolean = false) => {
  let temp = Math.abs(time) / (isMs ? 1000 : 1);
  const years = Math.floor(temp / 31536000);
  const days = Math.floor((temp %= 31536000) / 86400);
  const hours = Math.floor((temp %= 86400) / 3600);
  const minutes = Math.floor((temp %= 3600) / 60);
  const seconds = temp % 60;

  const isSeconds = years || days || minutes || hours ? seconds : true;

  return `${years ? `<span>${years}y</span><br/>` : ''}
   ${days ? `<span>${days}d</span><br/>` : ''}
   ${hours ? `<span>${hours}h</span><br/>` : ''}
   ${minutes ? `<span>${minutes}m</span><br/>` : ''}
   ${isSeconds ? `<span>${Number.parseFloat(seconds.toFixed(0).toString())}s</span>` : ''}`;
};

export const calendarDateFormatter = (date: string | null, format?: string) => {
  if (!date) return '';
  return moment(date).calendar();
};

export const dateTimeFormatter = (date: string | null, format?: string, type: string = '') => {
  let momentDateObj = moment(date);
  if (type === 'startOfDay') {
    momentDateObj = momentDateObj.startOf('day');
  }
  if (type === 'endOfDay') {
    momentDateObj = momentDateObj.endOf('day');
  }
  return dateFormatter(momentDateObj.toDate(), format);
};
