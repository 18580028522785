import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { BoostMainModal } from 'components/Common/Boost';
import { PostNowModal, SchedulePostModal, ViewCalendarModal } from 'containers/Content/Creator/AccountSubPages';
import { apAccountContainerTypes, IStore } from 'types';
import {
  AP_ADD_TO_POST_TAB_NAMES,
  COMMON_SOCIAL_PROVIDER_TYPE,
  ADD_POST_ACTION_TYPE,
  AP_ACTION_TYPES,
  ADD_POST_STATUS_NAME,
  CREATOR_SCH_DATE_AND_TIME,
  AP_UPCOMING_DAYS_TOOLTIP_INIT_OBJ,
  RIPPLE_COLOR,
  CALENDAR_VIEW_DATE_RANGE,
  USER_OWNERSHIP,
  VALID_BRAND_CATEGORY_REGEX,
  SETTINGS_CUSTOMER_PERSONA_USAGE_LEVELS,
  TOOLTIP_PLACEMENT,
  POST_NOT_AVAILABLE_MSG,
  ComplianceType
} from 'utils/constants';
import {
  ApInitialUpcomingDaysList,
  ApUpcomingDaysSchPostList,
  getApPostActionReqPayload,
  getPreviewSocialMediaList,
  getApUpdatePostActionReqPayload,
  checkSiteRestriction,
  getTimezoneAbbreviation,
  calenderDateHelper,
  getCurrentDate,
  accountCanSchedule,
  splitTextFromHashAndEmoji,
  generateCustomerPersonaFieldData
} from 'utils/helpers';
import { useAIFeatureAccess, useAccountSwitcherData, useSSOIdentifiersForAccount } from 'utils/hooks';
import {
  addPostSavePostActionRequest,
  addPostUpdateSavedPostRequest,
  addPostSetEditConfirmForApprovedPost,
  calendarViewDateRange,
  setCreatorModalName,
  aiValidateCaptionRequest,
  setAIPostContentOpen,
  setAITextContent,
  getAIContentCreatorRequest
} from 'actions';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { Loading, AddOrSearchCampaignModal } from 'components';
import { CustomTooltip } from 'widgets/Tooltip';

export const AccountCreatePostFilter = () => {
  const dispatch = useDispatch();

  const { id, userOwnership } = useAccountSwitcherData();
  const { isCSLocation } = useSSOIdentifiersForAccount();
  const { aiCaptionAccess } = useAIFeatureAccess();

  const isFetching = useSelector((state: IStore) => state.calendar.isFetching);
  const locationActualData = useSelector((state: IStore) => state.calendar.locationActualData);
  const addPostAction = useSelector((state: IStore) => state.addPostAccount.addPostAction);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const selectedTags = useSelector((state: IStore) => state.addPostAccount.selectedTags);
  const editSavedPost = useSelector((state: IStore) => state.addPostAccount.editSavedPost);
  const permissions = useSelector((state: IStore) => state.accounts.permissions);
  const instaType = useSelector((state: IStore) => state.addPostAccount.instaPhotoPreviewObj.actionType);
  const isPostUpdate = useSelector((state: IStore) => state.addPostAccount.isPostUpdate);
  const editConfirmObj = useSelector((state: IStore) => state.addPostAccount.editConfirmObj);
  const isPostEditable = useSelector((state: IStore) => state.addPostAccount.editConfirmObj.isPostEditable);
  const isEditConfirmed = useSelector((state: IStore) => state.addPostAccount.editConfirmObj.isEditConfirmed);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin) || false;
  const isFranchisor = useSelector((state: IStore) => state.login.activeUser?.franchisor) || false;
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const fbTargetingChoice = useSelector((state: IStore) => state.fbAdsTargeting.fbTargetingChoice);
  const fbBoostConfig = useSelector((state: IStore) => state.fbAdsTargeting.facebookBoostConfig);
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);
  const campaignData = useSelector((state: IStore) => state.addPostFranchisorAction.campaignData);
  const creatorModalName = useSelector((state: IStore) => state.addPostFranchisorAction.creatorModalName);
  const isAiPost = useSelector((state: IStore) => state.aiContentCreator.isAiPost);
  const overriddenCorporatePostsAutoApproved = useSelector((state: IStore) => state.accounts.permissions.franchiseePermissions?.overridden_corporate_posts_auto_approved || false);
  // const complianceDocuments = useSelector((state: IStore) => state.aiPlayBook.complianceDocuments);
  const aiComplianceSummary = useSelector((state: IStore) => state.aiPlayBook.ai_playbook.ai_compliance_summary);
  const aiPlaybook = useSelector((state: IStore) => state.aiPlayBook.ai_playbook);
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);
  const isAICaptionValidationEnabled = useSelector((state: IStore) => state.aiContentCreator.isAICaptionValidationEnabled);
  const isAiContentOpen = useSelector((state: IStore) => state.aiContentCreator.isAiContentOpen);
  const aiTextContent = useSelector((state: IStore) => state.aiContentCreator.aiTextContent);
  const customerPersonaText = useSelector((state: IStore) =>
    userOwnership === USER_OWNERSHIP.ACCOUNT ? state.accounts.accountDetails?.account?.customer_persona_text : state.aiPlayBook.ai_playbook.customer_persona_text
  );
  const { generalDocuments, verticals } = useSelector((state: IStore) => state.aiPlayBook);
  const isHashtagsAndEmojis = useSelector((state: IStore) => state.aiContentCreator.isHashtagsAndEmojis);
  const location = useSelector((state: IStore) => state.accounts.accountDetails?.account?.city || '');
  const isCampaign = useSelector((state: IStore) => state.accounts.accountDetails?.account.campaign_feature || state.franchisors.franchisorDetails?.campaign_feature || false);
  const isPostNowEnabled = useSelector((state: IStore) => state.addPostAccount.isPostNowEnabled);
  const reducerTextEditorState = useSelector((state: IStore) => state.addPostFranchisorAction.textEditorState);

  const [selectedDayTooltip, setSelectedDayTooltip] = useState(AP_UPCOMING_DAYS_TOOLTIP_INIT_OBJ);
  const [showTooltip, setTooltip] = useState(false);

  const overriddenCustomPostsLocked = permissions.franchiseePermissions?.overridden_custom_posts_locked || false;
  const isFranchisee = accountDetails?.account_profiles[0].is_franchisee || false;
  const enableActionButton = addPostAction === AP_ACTION_TYPES.EDIT && savedPostDetails?.status_name === ADD_POST_STATUS_NAME.AWAITING_APPROVAL ? false : true;
  const customPostsLocked = permissions.franchiseePermissions?.custom_posts_locked || false;
  const isHideSendForApproval = userOwnership ? checkSiteRestriction(userOwnership, menuAccess, 'creator/send_for_approval') : false;
  const isHubUser = USER_OWNERSHIP.FRANCHISOR === userOwnership;

  const isEnableAICaptionValidation = Boolean(aiCaptionAccess && isAISubscribed && aiComplianceSummary && isAICaptionValidationEnabled);

  const upcomingDays = useMemo(() => {
    if (id && userOwnership && !isFetching && accountDetails?.account?.tzinfo_name && locationActualData?.length) {
      return ApUpcomingDaysSchPostList(
        ApInitialUpcomingDaysList(),
        locationActualData,
        accountDetails?.account?.tzinfo_name || '',
        isHubUser,
        isFranchisee,
        overriddenCorporatePostsAutoApproved,
        overriddenCustomPostsLocked
      );
    } else return ApInitialUpcomingDaysList();
  }, [id, userOwnership, isFetching, accountDetails?.account?.tzinfo_name, locationActualData, isFranchisee, isHubUser, overriddenCorporatePostsAutoApproved, overriddenCustomPostsLocked]);

  const schedulePostStatus = useMemo(
    () => accountCanSchedule(isFranchisee, overriddenCustomPostsLocked, savedPostDetails, editSavedPost, selectedMediaList, linkData, selectedTags, campaignData),
    [overriddenCustomPostsLocked, isFranchisee, savedPostDetails, editSavedPost, selectedMediaList, linkData, selectedTags, campaignData]
  );

  const enableSaveAsReady =
    Boolean((isFranchisee && overriddenCustomPostsLocked && savedPostDetails?.status_name !== ADD_POST_STATUS_NAME.APPROVED) || schedulePostStatus || isAdmin || isFranchisor) &&
    !overriddenCustomPostsLocked &&
    !customPostsLocked;

  /* const siteRestrictions = useMemo(() => {
    const restrictions = {
      showBoost: false,
    };
    if (menuAccess) {
      const siteRestrictions = menuAccess?.site_restrictions;
      if (id && userOwnership) {
        if (userOwnership === USER_OWNERSHIP.ACCOUNT) {
          restrictions.showBoost = Boolean(siteRestrictions?.location_user?.includes('boost'));
        }
      }
    }
    return restrictions;
  }, [menuAccess, id, userOwnership]); */

  useEffect(() => {
    if (isFetching) {
      setSelectedDayTooltip(AP_UPCOMING_DAYS_TOOLTIP_INIT_OBJ);
    }
  }, [isFetching]); // eslint-disable-line

  useEffect(() => {
    if (id && userOwnership && !isFetching && accountDetails?.account?.tzinfo_name && locationActualData?.length) {
      const validSchedulePosts = ApUpcomingDaysSchPostList(
        upcomingDays,
        locationActualData,
        accountDetails?.account?.tzinfo_name || '',
        isHubUser,
        isFranchisee,
        overriddenCorporatePostsAutoApproved,
        overriddenCustomPostsLocked
      );
      setSelectedDayTooltip({
        classname: `day-0${validSchedulePosts.find((datum) => datum.postCount)?.dayNumber}` || `day-0${0}`,
        postCount: validSchedulePosts.find((datum) => datum.postCount)?.postCount || 0,
        approvalPostsCount: validSchedulePosts.find((datum) => datum.approvalPostsCount)?.approvalPostsCount || 0
      });
    }
  }, [id, userOwnership, isFetching, accountDetails?.account?.tzinfo_name, JSON.stringify(locationActualData)]); // eslint-disable-line

  const handleAddSavedPostData = (actionType: string) => {
    if (addPostAction === AP_ACTION_TYPES.ADD && !disableSavedPostAction()) {
      const apReqPayload = getApPostActionReqPayload(id, userOwnership, savedPostDetails, selectedFBTagList, selectedMediaList, selectedTags, linkData, actionType, instaType, campaignData, isAiPost);
      dispatch(addPostSavePostActionRequest({ actionType, reqBody: apReqPayload }));
    } else if (addPostAction === AP_ACTION_TYPES.EDIT && !disableSavedPostAction()) {
      handleUpdateSavedPostData(actionType);
    }
  };

  const handleUpdateSavedPostData = (actionType: string) => {
    if (id && userOwnership && savedPostDetails && editSavedPost?.id && !disableSavedPostAction()) {
      const apReqPayload = getApUpdatePostActionReqPayload(
        editSavedPost,
        savedPostDetails,
        selectedFBTagList,
        selectedMediaList,
        selectedTags,
        linkData,
        actionType,
        instaType,
        reducerTextEditorState?.facebook,
        campaignData,
        isEditConfirmed,
        editSavedPost?.ai_post || isAiPost
      );
      dispatch(addPostUpdateSavedPostRequest({ actionType, savedPostId: editSavedPost?.id, reqBody: apReqPayload }));
    }
  };

  const disableSavedPostAction = () => {
    if (savedPostDetails && !getPreviewSocialMediaList(savedPostDetails).length) return true;
    else return false;
  };

  const handleSelectDayForTooltip = (listItem: apAccountContainerTypes.IAddPostUpcomingDays) => {
    if (listItem.postCount) {
      setSelectedDayTooltip({
        classname: `day-0${listItem.dayNumber}`,
        postCount: listItem.postCount,
        approvalPostsCount: listItem?.approvalPostsCount || 0
      });
    }
    setTooltip(true);
  };

  const handleEditConfirmation = () => {
    dispatch(addPostSetEditConfirmForApprovedPost({ ...editConfirmObj, isShowModal: true, isEditConfirmed: false }));
  };

  const handleTabAction = (tabName: string) => {
    if (isPostEditable) {
      handleEditConfirmation();
    } else {
      setModalName(tabName);
    }
  };

  const setModalName = (data: string | null) => dispatch(setCreatorModalName(data));

  const validateAICaption = (onSuccess: () => void) => {
    if (!isEnableAICaptionValidation) {
      onSuccess();
    } else {
      const socialMediaForValidation = [
        COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK,
        COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM,
        COMMON_SOCIAL_PROVIDER_TYPE.TWITTER,
        COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN,
        COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK,
        COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
      ].find((socialMedia) => Boolean(savedPostDetails?.[`${socialMedia}_text`]));

      const validationText = savedPostDetails?.[`${socialMediaForValidation}_text`] || '';
      dispatch(
        aiValidateCaptionRequest({
          text: validationText,
          type: ComplianceType.CAPTION,
          // compliance_document_urls: complianceDocuments.map((it: any) => it.url) ?? [],
          compliance_summary: aiPlaybook.ai_compliance_summary || '',
          preferred_language: accountDetails?.account?.preferred_language || '',
          // ai_compliance_description: aiPlaybook.ai_compliance_description || '',
          onSuccess: (warning: string) => {
            if (!warning) {
              onSuccess();
            } else {
              const customerPersonaFieldData = generateCustomerPersonaFieldData(customerPersonaText || '');
              dispatch(setAIPostContentOpen(!isAiContentOpen));
              dispatch(setAITextContent({ ...aiTextContent, content: savedPostDetails?.facebook_text || '' }));
              dispatch(
                getAIContentCreatorRequest({
                  id,
                  userOwnership,
                  text: splitTextFromHashAndEmoji(validationText).trim(),
                  brandName: aiPlaybook.name,
                  customerAlias: aiPlaybook.questions.customer_alias,
                  businessSearch: aiPlaybook.questions.business_search,
                  brandCategory: verticals
                    .find((it) => it.id === aiPlaybook.vertical_id)
                    ?.value.replace(VALID_BRAND_CATEGORY_REGEX, '')
                    .trim(),
                  documentUrls: generalDocuments.map((it: any) => it.url) ?? [],
                  // compliance_document_urls: complianceDocuments.map((it: any) => it.url) ?? [],
                  compliance_summary: aiPlaybook.ai_compliance_summary || '',
                  // ai_compliance_description: aiPlaybook.ai_compliance_description || '',
                  isHashtags: isHashtagsAndEmojis?.isHashtags,
                  isEmojis: isHashtagsAndEmojis?.isEmojis,
                  location,
                  websiteUrl: '',
                  customer_persona_description: customerPersonaText || '',
                  customer_persona_usage_level: SETTINGS_CUSTOMER_PERSONA_USAGE_LEVELS[0].value,
                  customer_persona_name: customerPersonaFieldData.name,
                  customer_persona_occupation: customerPersonaFieldData.occupation,
                  preferred_language: accountDetails?.account?.preferred_language || ''
                })
              );
            }
          }
        })
      );
    }
  };

  return (
    <>
      <section className="item-g filter coupon-filter creator-filter__main">
        <div className="createpost-right-section-new-section">
          <div className="action-btn-holder">
            {
              /* siteRestrictions.showBoost && */
              enableActionButton &&
                accountDetails?.social_media?.find((datum) => datum.provider_name === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK)?.id &&
                fbTargetingChoice?.facebook_ad_account?.id &&
                savedPostDetails?.use_facebook && (
                  <>
                    <CustomRippleButton rippleClass={'ac-primary-box'} custColor={RIPPLE_COLOR.primary}>
                      <button className={'ac-btn ac-icon-btn ac-primary-light ac-block ac-outline add-boost-btn'} onClick={() => handleTabAction(AP_ADD_TO_POST_TAB_NAMES.BOOST)}>
                        <ImageValidation isImgValid defaultImg={'boost-btn-icon'} customName="Boost button icon" />
                        <span className="boost-txt">{fbBoostConfig?.isAddBoost ? 'View Boost' : 'Add Boost'}</span>
                      </button>
                    </CustomRippleButton>
                    <div className="seperator" />
                  </>
                )
            }
            {enableActionButton && (addPostAction === AP_ACTION_TYPES.ADD || enableSaveAsReady) && (
              <div className={`grid-btn-section${!enableSaveAsReady || addPostAction === AP_ACTION_TYPES.EDIT ? ` cpLogin` : ``}`}>
                {addPostAction === AP_ACTION_TYPES.ADD && (
                  <CustomRippleButton rippleClass={`ac-primary-box ${disableSavedPostAction() ? ` pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
                    <button
                      className={`ac-btn ac-primary ac-block r-flx r-flx-jc ${disableSavedPostAction() ? `disabled` : ``}`}
                      onClick={() => validateAICaption(() => handleAddSavedPostData(ADD_POST_ACTION_TYPE.SAVE_AS_DRAFT))}
                    >
                      Save as Draft
                    </button>
                  </CustomRippleButton>
                )}
                {enableSaveAsReady && (
                  <CustomRippleButton
                    rippleClass={`ac-primary-box ${
                      disableSavedPostAction() || (savedPostDetails?.locked && editSavedPost?.id && editSavedPost?.syndicated_from_content_id) ? ` pointer-events-none` : ``
                    }`}
                    custColor={RIPPLE_COLOR.primary}
                  >
                    <button
                      className={`ac-btn ac-primary ac-block r-flx r-flx-jc ${
                        disableSavedPostAction() || (savedPostDetails?.locked && editSavedPost?.id && editSavedPost?.syndicated_from_content_id) ? `disabled` : ``
                      }`}
                      onClick={() => validateAICaption(() => handleAddSavedPostData(ADD_POST_ACTION_TYPE.SAVE_TO_DATABASE))}
                    >
                      Save as Ready
                    </button>
                  </CustomRippleButton>
                )}
              </div>
            )}
            {addPostAction === AP_ACTION_TYPES.EDIT && (
              <CustomRippleButton
                rippleClass={`ac-primary-box r-mb2 ${
                  disableSavedPostAction() || isPostUpdate || (savedPostDetails?.locked && editSavedPost?.id && editSavedPost?.syndicated_from_content_id) ? `pointer-events-none` : ``
                }`}
                custColor={RIPPLE_COLOR.primary}
              >
                <button
                  className={`ac-btn ac-primary ac-block ${
                    disableSavedPostAction() || isPostUpdate || (savedPostDetails?.locked && editSavedPost?.id && editSavedPost?.syndicated_from_content_id) ? `disabled` : ``
                  }`}
                  onClick={() => validateAICaption(() => handleUpdateSavedPostData(ADD_POST_ACTION_TYPE.UPDATE_SAVED_POST))}
                >
                  {savedPostDetails?.status_name === ADD_POST_STATUS_NAME.AWAITING_APPROVAL ? `Update Post` : `Update Saved Post`}
                </button>
              </CustomRippleButton>
            )}
            {enableActionButton && schedulePostStatus ? (
              <>
                <CustomRippleButton rippleClass={`ac-primary-box r-mb2 ${disableSavedPostAction() ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
                  <button
                    className={`ac-btn ac-primary ac-block ${disableSavedPostAction() ? `disabled` : ``}`}
                    onClick={() => validateAICaption(() => setModalName(AP_ADD_TO_POST_TAB_NAMES.SCHEDULE))}
                  >
                    Schedule
                  </button>
                </CustomRippleButton>
                {!isPostNowEnabled ? (
                  <CustomTooltip
                    customPlacement={TOOLTIP_PLACEMENT.TOP}
                    customTooltipText={POST_NOT_AVAILABLE_MSG}
                    customClassname={'custom-tooltip-long-text'}
                    customInput={() => <button className={`ac-btn ac-primary ac-block r-mb2 button-opacity`}>Post Now</button>}
                  />
                ) : (
                  <CustomRippleButton rippleClass={`ac-primary-box r-mb2 ${disableSavedPostAction() ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
                    <button
                      className={`ac-btn ac-primary ac-block ${disableSavedPostAction() || !isPostNowEnabled ? `disabled` : ``}`}
                      onClick={() => validateAICaption(() => setModalName(AP_ADD_TO_POST_TAB_NAMES.POST_NOW))}
                    >
                      Post Now
                    </button>
                  </CustomRippleButton>
                )}
              </>
            ) : enableActionButton && !isHideSendForApproval ? (
              <CustomRippleButton rippleClass={`ac-primary-box r-mb2 ${disableSavedPostAction() ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
                <button
                  className={`ac-btn ac-secondary ac-block ${disableSavedPostAction() ? `disabled` : ``}`}
                  onClick={() => validateAICaption(() => handleAddSavedPostData(ADD_POST_ACTION_TYPE.SEND_FOR_APPROVAL))}
                >
                  Send for Approval
                </button>
              </CustomRippleButton>
            ) : null}
            {isCampaign && !isCSLocation && (
              <div className="atc-btn">
                <CustomRippleButton rippleClass={`ac-primary-box ${disableSavedPostAction() ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
                  <button className={`ac-btn ac-secondary ac-block ${disableSavedPostAction() ? `disabled` : ``}`} onClick={() => setModalName(AP_ADD_TO_POST_TAB_NAMES.CAMPAIGN)}>
                    Assign to Campaign
                  </button>
                </CustomRippleButton>
              </div>
            )}
            {enableActionButton && savedPostDetails?.account_schedule_date_time.selected && (
              <div className="current-time-stamp">
                <span>
                  {`${calenderDateHelper(getCurrentDate(), accountDetails?.account?.tzinfo_name || '', CREATOR_SCH_DATE_AND_TIME)} `}(
                  {`${getTimezoneAbbreviation(accountDetails?.account?.tzinfo_name || '')}`})
                </span>
              </div>
            )}
            {enableActionButton && <div className="seperator r-mb2" />}
            {enableActionButton && upcomingDays.length ? (
              <div className="udf-options">
                <div className="grid-btn-section">
                  <span className="up-days-creator">Upcoming Days</span>
                  <span
                    className="view-calendar-text"
                    onClick={() => {
                      dispatch(calendarViewDateRange(CALENDAR_VIEW_DATE_RANGE));
                      setModalName(AP_ADD_TO_POST_TAB_NAMES.VIEW_CALENDAR);
                    }}
                  >
                    View Calendar
                  </span>
                </div>
                {isFetching ? (
                  <Loading isSmall />
                ) : (
                  <>
                    <div className="grid-calendar-section">
                      {upcomingDays.map((dateItem, index) => {
                        return (
                          <span className="" key={`creator-uday-${index}`}>
                            {dateItem.day}
                          </span>
                        );
                      })}
                    </div>
                    <div className="grid-calendar-section">
                      {upcomingDays.map((dateItem, index) => {
                        return (
                          <span
                            className={dateItem.approvalPostsCount ? 'days-approval-bg' : dateItem.postCount ? 'days-bg' : 'days-without-bg'}
                            key={`creator-scheduled-posts-${index}`}
                            onMouseOver={() => dateItem.postCount && handleSelectDayForTooltip(dateItem)}
                            onMouseOut={() => setTooltip(false)}
                          >
                            {dateItem.date.substring(3, 5)}
                          </span>
                        );
                      })}
                    </div>
                  </>
                )}
                {selectedDayTooltip.postCount && showTooltip ? (
                  <div className={`grid-cal-tooltip-section ${selectedDayTooltip.classname} ${selectedDayTooltip.approvalPostsCount ? 'apc-on' : 'apc-off'}`}>
                    {selectedDayTooltip.postCount === selectedDayTooltip.approvalPostsCount ? (
                      <div className="custom-text">{`${selectedDayTooltip.approvalPostsCount} ${selectedDayTooltip.approvalPostsCount === 1 ? `Post` : `Posts`} waiting for approval.`}</div>
                    ) : !selectedDayTooltip.approvalPostsCount ? (
                      <div className="custom-text">{`${selectedDayTooltip.postCount} ${selectedDayTooltip.postCount === 1 ? `Post` : `Posts`} scheduled for this day.`}</div>
                    ) : (
                      <div className="custom-text">{`You have ${selectedDayTooltip.postCount} posts lined up for this day with ${selectedDayTooltip.approvalPostsCount} requiring approval.`}</div>
                    )}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </section>
      {creatorModalName === AP_ADD_TO_POST_TAB_NAMES.CAMPAIGN ? (
        <AddOrSearchCampaignModal
          isShowModal
          onModalClose={() => setModalName(null)}
          onSaveChanges={() => handleAddSavedPostData(campaignData.length > 1 ? ADD_POST_ACTION_TYPE.MULTIPLE_CAMPAIGN_POSTS : ADD_POST_ACTION_TYPE.SINGLE_CAMPAIGN_POST)}
        />
      ) : creatorModalName === AP_ADD_TO_POST_TAB_NAMES.BOOST ? (
        <BoostMainModal isModalShow handleModalClose={() => setModalName(null)} />
      ) : creatorModalName === AP_ADD_TO_POST_TAB_NAMES.POST_NOW ? (
        // <PostNowModal isModalShow handleModalClose={() => setModalName(null)} containerClassName={'prime-modal modal-confirmation confirm-delete post__now--modal'} />
        <PostNowModal isModalShow handleModalClose={() => setModalName(null)} containerClassName={'post__now--modal'} />
      ) : creatorModalName === AP_ADD_TO_POST_TAB_NAMES.SCHEDULE ? (
        <SchedulePostModal isModalShow handleModalClose={() => setModalName(null)} containerClassName={'aps-modal'} />
      ) : creatorModalName === AP_ADD_TO_POST_TAB_NAMES.VIEW_CALENDAR ? (
        <ViewCalendarModal isModalShow handleModalClose={() => setModalName(null)} containerClassName={'aps-modal'} />
      ) : null}
    </>
  );
};
