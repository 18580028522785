import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Masonry from 'react-masonry-css';
import { useNavigate } from 'react-router';

import { IStore, mgApiTypes } from 'types';
import {
  USER_OWNERSHIP,
  MG_MEDIA_TYPE,
  BREAK_POINT_COLUMNS_OBJ_MEDIA,
  ALERT_BOX_TYPES,
  MSG_RELEASE_DOWNLOAD_SUCCESS,
  MSG_RELEASE_DOWNLOAD_NOT_AVAIL,
  TOOLTIP_PLACEMENT,
  MG_TOOLTIP_ICONS_NAME,
  ROUTES,
  MgMediaType,
  MG_FILTER_INITIAL_VALUE,
  AI_ICON_BG_IMG,
  FRANCHISOR_SYN_CONTENT_WRITER_OBJ,
  MG_CLOUD_MEDIA_TYPE,
  VALID_BRAND_CATEGORY_REGEX,
  SETTINGS_CUSTOMER_PERSONA_USAGE_LEVELS,
  PERSONA_FIELDS_FOR_CAPTION_GENERATION,
  AI_LOADING_CONTEXT_MSG_FOR_MEDIA,
  ICSSubscriptionPlans,
  FranchisorSyndicationMediaType
} from 'utils/constants';
import { ImageValidation, Video } from 'widgets/Media';
import { handleLinkNavigation, getUseMediaContentData, getUseMediaContentTextField, getUseMediaSavedPostData, splitTextFromHashAndEmoji } from 'utils/helpers';
import { useAccountSwitcherData, useActivePlatform, useParamsDeconstructor, useSSOIdentifiersForAccount } from 'utils/hooks';
import { AILoading, alertBoxCall } from 'components';
import { DeleteAssetModalPopup } from 'components/Common/Modal/DeleteAssetModalPopup';
import {
  mgLikeRequest,
  mgDislikeRequest,
  mgDeleteAssetRequest,
  mgMediaReleaseRequest,
  addPostSetSelectedMediaList,
  mgDetailDataReset,
  addPostSetCustomTextEditorData,
  addPostUpdateSavedPostDetails,
  addPostUseMediaContentData,
  getAIContentCreatorRequest,
  setAISubscriptionDetailsModal,
  setCsLocationSubscriptionModalType,
  aiUpdateLimitationRequest,
  mgUpdateSelectedMediaList
} from 'actions';
import { CustomTooltip } from 'widgets/Tooltip';
import { FranSyndicatePostModal } from 'containers/Content/Creator/BrandOrHubSubPages';
import { MediaGalleryOverlay } from './MediaGalleryOverlay';

export const MediaGalleryList = ({ isMultiSelect }: { isMultiSelect?: boolean }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, userOwnership, optionalParams, currentRoute } = useAccountSwitcherData();
  const { isCSLocation } = useSSOIdentifiersForAccount();

  const { queryParams } = useParamsDeconstructor(MG_FILTER_INITIAL_VALUE);
  const activePlatforms = useActivePlatform();

  const assetsList = useSelector((state: IStore) => (isMultiSelect ? state.mediaGallery.multiSelectMediaList : state.mediaGallery.assetsList));
  const mediaReleases = useSelector((state: IStore) => state.mediaGallery.mediaReleases);
  const mediaReleasesError = useSelector((state: IStore) => state.mediaGallery.mediaReleasesError);
  const accessiblityResponse = useSelector((state: IStore) => state.menuAccessiblity.accessiblityResponse);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin) || false;
  const isContentWriter = useSelector((state: IStore) => state.login.activeUser?.content_writer) || false;
  const isFranchisor = useSelector((state: IStore) => state.login.activeUser?.franchisor) || false;
  const tzInfoName = useSelector((state: IStore) => state.accounts?.accountDetails?.account?.tzinfo_name || '');
  const franchisorSyndication = useSelector((state: IStore) => state.franchisors?.currentFranDetails?.franchisors_for_syndication);
  const { ai_playbook, generalDocuments, verticals } = useSelector((state: IStore) => state.aiPlayBook);
  const customerPersonaText = useSelector((state: IStore) =>
    userOwnership === USER_OWNERSHIP.ACCOUNT ? state.accounts.accountDetails?.account?.customer_persona_text : state.aiPlayBook.ai_playbook.customer_persona_text
  );
  const isHashtagsAndEmojis = useSelector((state: IStore) => state.aiContentCreator.isHashtagsAndEmojis);
  const location = useSelector((state: IStore) => state.accounts.accountDetails?.account?.city || '');
  const isCreatePostSuggestionsFetching = useSelector((state: IStore) => state.aiContentCreator.isCreatePostSuggestionsFetching);
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);
  const aiLimitation = useSelector((state: IStore) => state.aiContentCreator.aiLimitation);
  const aiSubscription = useSelector((state: IStore) => state.aiContentCreator.aiSubscription);
  const selectedMediaIds = useSelector((state: IStore) => state.mediaGallery.selectedMediaIds);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails?.account);
  const franchisorDetails = useSelector((state: IStore) => state.franchisors.franchisorDetails);
  // const customMediaReleaseFeature = useSelector(
  //   (state: IStore) => state.franchisors.franchisorDetails?.custom_media_release_feature || state.accounts?.accountDetails?.account?.features?.custom_media_release_feature || false
  // );

  const [deleteItem, setDeleteItem] = useState<null | mgApiTypes.IMGListResponseObj>(null);
  const [openSyndicateModal, setOpenSyndicateModal] = useState<mgApiTypes.IFranSynContentWriterObj>(FRANCHISOR_SYN_CONTENT_WRITER_OBJ);

  const activeSubscription = aiSubscription.find((subscription: any) => subscription.account_id === id);
  const isAILimitation = activeSubscription?.account_id && +activeSubscription.franchisor_subscrniption_type_id === 7 && activeSubscription?.subscription_plan === ICSSubscriptionPlans.FREE;

  const mediaType = optionalParams[0];

  const customerPersonaFieldData = useMemo(() => {
    const data: Record<string, string> = { name: '', occupation: '' };
    if (customerPersonaText) {
      PERSONA_FIELDS_FOR_CAPTION_GENERATION.forEach((field) => {
        const CustomerPersonaRegex = new RegExp(`${field.label}:\\s*([^\n]+)`);
        const nameArr = customerPersonaText?.match(CustomerPersonaRegex) || null;
        data[field.key as string] = nameArr?.[1] || '';
      });
    }

    return data;
  }, [customerPersonaText]);

  useEffect(() => {
    if (mediaReleases.length) {
      handleLinkNavigation(mediaReleases[0].url);
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, MSG_RELEASE_DOWNLOAD_SUCCESS);
    } else if (mediaReleasesError) {
      alertBoxCall(ALERT_BOX_TYPES.INFO, MSG_RELEASE_DOWNLOAD_NOT_AVAIL);
    }
  }, [mediaReleases, mediaReleasesError]); // eslint-disable-line

  const handleAssetDataDelete = (deleteData: mgApiTypes.IMGListResponseObj) => {
    setDeleteItem(deleteData);
  };

  const handleMGDownload = (url: string, filename: string) => {
    filename = filename ? filename : url.substring(url.lastIndexOf('/') + 1);
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const createdDoc = document.createElement('a');
        createdDoc.href = url;
        createdDoc.download = filename;
        createdDoc.click();
        createdDoc.remove();
      });
    });
  };

  const handleReleaseDownload = (assetsItem: mgApiTypes.IMGListResponseObj) => {
    // old -assetsItem.media_release && !customMediaReleaseFeature
    if (assetsItem.media_release) {
      dispatch(mgMediaReleaseRequest({ assetId: assetsItem.id, mediaType }));
    }
  };

  const handlePostsLike = (assetId: number) => {
    if (assetId && id && userOwnership) {
      dispatch(mgLikeRequest({ assetId, mediaType }));
    }
  };

  const handlePostsDislike = (assetId: number) => {
    if (assetId && id && userOwnership) {
      dispatch(mgDislikeRequest({ assetId, mediaType }));
    }
  };

  const handleMGUse = (selectedAssestObj: mgApiTypes.IMGListResponseObj) => {
    dispatch(addPostSetSelectedMediaList([selectedAssestObj]));
    handleUseMediaDescription(selectedAssestObj?.comments, selectedAssestObj?.cloudinary_id);
    navigate(ROUTES.CONTENT_CREATOR.replace(':type', userOwnership).replace(':id', id.toString()).replace('/*', ''));
  };

  const handleUseMediaDescription = (mediaComments: null | string, cloudinaryId: null | string) => {
    if (mediaComments || cloudinaryId) {
      if (userOwnership === USER_OWNERSHIP.FRANCHISOR) {
        dispatch(addPostUseMediaContentData(getUseMediaContentData(activePlatforms, mediaComments || '')));
      } else {
        dispatch(addPostUpdateSavedPostDetails(getUseMediaSavedPostData(activePlatforms, mediaComments || '', tzInfoName)));
      }
      dispatch(addPostSetCustomTextEditorData(getUseMediaContentTextField(activePlatforms, mediaComments || '')));
    }
  };

  const routePush = (path: string, searchParams: { [key: string]: string } = {}) => {
    navigate({ pathname: path, search: searchParams ? `?${new URLSearchParams(searchParams).toString()}` : `` });
  };

  const handleMGDetailsData = (assetId: number) => {
    if (id && userOwnership && assetId) {
      dispatch(mgDetailDataReset());
      routePush(`/${userOwnership}/${id.toString()}/${currentRoute}/${mediaType}/${assetId.toString()}`, queryParams);
    }
  };

  const handleCSSubscription = () => {
    dispatch(setAISubscriptionDetailsModal({ type: 'CSLocationAISubscription', isOpen: true }));
    dispatch(setCsLocationSubscriptionModalType('ai_caption'));
  };

  const generateAIContent = (description: string, selectedAssestObj: mgApiTypes.IMGListResponseObj) => {
    if (!isAISubscribed) {
      if (!isCSLocation) {
        dispatch(setAISubscriptionDetailsModal({ type: 'trialPeriod', isOpen: true }));
      } else {
        handleCSSubscription();
      }
    } else if (Boolean(aiLimitation && +aiLimitation?.ai_caption_limit <= 0)) {
      handleCSSubscription();
    } else {
      dispatch(addPostSetSelectedMediaList([selectedAssestObj]));
      dispatch(
        getAIContentCreatorRequest({
          id,
          userOwnership,
          text: splitTextFromHashAndEmoji(description).trim(),
          brandName: ai_playbook.name,
          customerAlias: ai_playbook.questions.customer_alias,
          businessSearch: ai_playbook.questions.business_search,
          brandCategory: verticals
            .find((it) => it.id === ai_playbook.vertical_id)
            ?.value.replace(VALID_BRAND_CATEGORY_REGEX, '')
            .trim(),
          documentUrls: generalDocuments.map((it: any) => it.url) ?? [],
          // compliance_document_urls: complianceDocuments.map((it: any) => it.url) ?? [],
          compliance_summary: ai_playbook.ai_compliance_summary || '',
          // ai_compliance_description: ai_playbook.ai_compliance_description || '',
          isHashtags: isHashtagsAndEmojis?.isHashtags,
          isEmojis: isHashtagsAndEmojis?.isEmojis,
          location,
          websiteUrl: '',
          customer_persona_description: customerPersonaText || '',
          customer_persona_usage_level: SETTINGS_CUSTOMER_PERSONA_USAGE_LEVELS[0].value,
          customer_persona_name: customerPersonaFieldData.name,
          customer_persona_occupation: customerPersonaFieldData.occupation,
          onAICaptionSuccessCallback: (description) => {
            handleUseMediaDescription(description, null);
            navigate(ROUTES.CONTENT_CREATOR.replace(':type', userOwnership).replace(':id', id.toString()).replace('/*', ''));
            if (isAILimitation) dispatch(aiUpdateLimitationRequest({ account_id: id, type: 'ai_caption' }));
          },
          preferred_language: userOwnership === USER_OWNERSHIP.ACCOUNT ? accountDetails?.preferred_language || '' : franchisorDetails?.preferred_language || '',
          ai_type: 'media' // for media only
        })
      );
    }
  };

  return (
    <>
      <div className="masonry-list-view-group px-0">
        {isCreatePostSuggestionsFetching && <AILoading aiIconBgColorType={AI_ICON_BG_IMG.WHITE} context={AI_LOADING_CONTEXT_MSG_FOR_MEDIA} />}
        <Masonry breakpointCols={BREAK_POINT_COLUMNS_OBJ_MEDIA} className="masonry-grid media-grid lpx" columnClassName="masonry-grid_column">
          {assetsList.map((assetsItem: mgApiTypes.IMGListResponseObj, index: number) => {
            const isAIEnabled = (assetsItem?.comments || assetsItem?.ai_description) && assetsItem?.active && assetsItem.media_type === MG_MEDIA_TYPE.PHOTO;

            const disableImgEdit =
              (!assetsItem?.active || assetsItem?.locked) && userOwnership === USER_OWNERSHIP.ACCOUNT && assetsItem?.franchisor_id
                ? 'pointer-events-none'
                : (!assetsItem?.active || assetsItem?.locked) && assetsItem?.original_franchisor_id !== assetsItem?.franchisor_id
                ? 'pointer-events-none'
                : '';
            const selectedMedia = isMultiSelect && selectedMediaIds.includes(assetsItem.id);

            return (
              <div
                key={`assets-item-${index}`}
                id={`mg-item-${assetsItem.id}`}
                className={`m-item${!assetsItem?.active ? ' mediaInactive' : ''} ${isAIEnabled ? 'mediaAi-on' : 'mediaAi-off'} ${
                  selectedMedia ? 'mdcpItem cp-selected mdcp-active' : 'mdcpItem mdcp-inactive'
                }`}
              >
                {isMultiSelect && assetsItem?.active ? (
                  <div className="mih-expand mdcpAction justify-content-center align-items-center h-100 w-100 position-absolute z-100 top-0" style={{ zIndex: 100 }}>
                    <button
                      className={`gnrc-btn white-gnr ac-btn`}
                      onClick={() => {
                        dispatch(mgUpdateSelectedMediaList(selectedMedia ? selectedMediaIds.filter((id) => id !== assetsItem.id) : selectedMediaIds.concat(assetsItem.id)));
                      }}
                    >
                      <span>{selectedMedia ? 'Selected' : 'Select'}</span>
                    </button>
                  </div>
                ) : null}
                <div className={`mi-cntrls${!assetsItem?.active ? ' pointer-events-none' : ''}`}>
                  {isAIEnabled && (
                    <MediaGalleryOverlay isAISubscribed={isAISubscribed} handleAIClick={() => generateAIContent(`${assetsItem?.ai_description || assetsItem?.comments || ''} `, assetsItem)} />
                  )}
                  <div className={`mint-likes`}>
                    {accessiblityResponse && (accessiblityResponse.media_asset_like_dislike_feature_enabled || accessiblityResponse.media_asset_like_dislike_feature) && (
                      <div
                        className={`switch-button ${assetsItem.liked ? 'like-active' : assetsItem.liked === null ? '' : 'unlike-active'}
                        ${isAdmin || isContentWriter || isFranchisor ? '' : 'cur-disabled'}`}
                      >
                        <span className="active" />
                        <button className={`switch-button-case left active-case ${isAdmin || isContentWriter || isFranchisor || assetsItem?.can_like ? '' : 'events-none pointer-events-none'}`}>
                          <CustomTooltip
                            customClassname="mg-tooltip__wrp"
                            customPlacement={TOOLTIP_PLACEMENT.TOP}
                            customTooltipText={MG_TOOLTIP_ICONS_NAME.LIKE}
                            customInput={() => (
                              <span>
                                <div className="lb-btn lb-like">
                                  <ImageValidation
                                    isImgValid
                                    defaultImg={assetsItem.liked === null ? 'like-grey' : assetsItem.liked ? 'like-a' : 'like-grey'}
                                    customName={!assetsItem?.active ? 'events-none pointer-events-none Like' : 'Like'}
                                    onClick={() => {
                                      handlePostsLike(assetsItem.id);
                                    }}
                                  />
                                </div>{' '}
                              </span>
                            )}
                          />
                        </button>
                        <button className={`switch-button-case right ${isAdmin || isContentWriter || isFranchisor || assetsItem?.can_like ? '' : 'events-none pointer-events-none'}`}>
                          <CustomTooltip
                            customClassname="mg-tooltip__wrp"
                            customPlacement={TOOLTIP_PLACEMENT.TOP}
                            customTooltipText={MG_TOOLTIP_ICONS_NAME.DISLIKE}
                            customInput={() => (
                              <span>
                                <div className="lb-btn lb-unlike">
                                  <ImageValidation
                                    isImgValid
                                    defaultImg={assetsItem.liked === null ? 'dislike-grey' : !assetsItem.liked ? 'dislike-a' : 'dislike-grey'}
                                    customName={!assetsItem?.active ? 'events-none pointer-events-none Unlike' : 'Unlike'}
                                    onClick={() => {
                                      handlePostsDislike(assetsItem.id);
                                    }}
                                  />
                                </div>
                              </span>
                            )}
                          />
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="mic-actions">
                    {mediaType !== MgMediaType.DOCUMENTS ? (
                      <CustomTooltip
                        customClassname="mg-tooltip__wrp"
                        customPlacement={TOOLTIP_PLACEMENT.TOP}
                        customTooltipText={MG_TOOLTIP_ICONS_NAME.SHARE}
                        customInput={() => (
                          <div className="mic">
                            <ImageValidation
                              customClassname={!assetsItem?.active ? 'events-none pointer-events-none mic__share--icon' : 'mic__share--icon'}
                              imgUrl={null}
                              defaultImg={'use-white.svg'}
                              isNotSvgFormat
                              customName="Share"
                              onClick={() => handleMGUse(assetsItem)}
                            />
                          </div>
                        )}
                      />
                    ) : null}
                    <CustomTooltip
                      customClassname="mg-tooltip__wrp"
                      customPlacement={TOOLTIP_PLACEMENT.TOP}
                      customTooltipText={
                        mediaType === MgMediaType.PHOTOS
                          ? MG_TOOLTIP_ICONS_NAME.DOWNLOAD_IMAGE
                          : mediaType === MgMediaType.VIDEOS
                          ? MG_TOOLTIP_ICONS_NAME.DOWNLOAD_VIDEO
                          : MG_TOOLTIP_ICONS_NAME.DOWNLOAD_DOCUMENT
                      }
                      customInput={() => (
                        <div className={`mic ${disableImgEdit}`}>
                          <ImageValidation
                            isImgValid
                            customClassname="mic__download--icon"
                            defaultImg={'download-white'}
                            customName={'Download'}
                            onClick={() => handleMGDownload(assetsItem.url, assetsItem.name)}
                          />
                        </div>
                      )}
                    />
                    {franchisorSyndication?.length && !assetsItem?.account_id ? (
                      <CustomTooltip
                        customClassname="mg-tooltip__wrp"
                        customPlacement={TOOLTIP_PLACEMENT.TOP}
                        customTooltipText={MG_TOOLTIP_ICONS_NAME.SYNDICATE}
                        customInput={() => (
                          <div className="mic">
                            <ImageValidation
                              isImgValid
                              defaultImg={'last-syndicate'}
                              customName="Syndicate"
                              customClassname={!assetsItem?.active ? 'events-none pointer-events-none mic__export--icon' : 'mic__export--icon'}
                              onClick={() =>
                                setOpenSyndicateModal({
                                  isShowModal: true,
                                  mediaType:
                                    assetsItem.media_type === MG_MEDIA_TYPE.RAW || assetsItem?.name.split('.').pop() === 'pdf' ? FranchisorSyndicationMediaType.DOCUMENT : assetsItem.media_type,
                                  assetId: assetsItem.id
                                })
                              }
                            />
                          </div>
                        )}
                      />
                    ) : null}
                    {mediaType !== MgMediaType.DOCUMENTS ? (
                      <CustomTooltip
                        customClassname="mg-tooltip__wrp"
                        customPlacement={TOOLTIP_PLACEMENT.TOP}
                        customTooltipText={
                          // old-cond assetsItem.media_release && customMediaReleaseFeature
                          assetsItem.media_release ? MG_TOOLTIP_ICONS_NAME.DOWNLOAD_RELEASE : MG_TOOLTIP_ICONS_NAME.RELEASE_NOT_AVAILABLE
                        }
                        customInput={() => (
                          <div className="mic">
                            <ImageValidation
                              isImgValid
                              defaultImg={'export-white'}
                              customName="Export"
                              customClassname={!assetsItem.media_release ? 'events-none pointer-events-none mic__export--icon' : 'mic__export--icon'}
                              onClick={() => handleReleaseDownload(assetsItem)}
                            />
                          </div>
                        )}
                      />
                    ) : null}
                    <CustomTooltip
                      customClassname="mg-tooltip__wrp"
                      customPlacement={TOOLTIP_PLACEMENT.TOP}
                      customTooltipText={MG_TOOLTIP_ICONS_NAME.DELETE}
                      customInput={() => (
                        <div className="mic">
                          <ImageValidation isImgValid customClassname="mic__delete--icon" defaultImg={'delete-m-red'} customName="Delete" onClick={() => handleAssetDataDelete(assetsItem)} />
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="m-ast" onClick={() => handleMGDetailsData(assetsItem.id)}>
                  {!assetsItem?.active ? (
                    <div className="campaign-tag media-tag">
                      <span>Expired</span>
                    </div>
                  ) : null}
                  {[MG_MEDIA_TYPE.PHOTO, MG_MEDIA_TYPE.RAW, MG_CLOUD_MEDIA_TYPE.IMAGE].includes(assetsItem.media_type) ? (
                    <ImageValidation
                      defaultImg={assetsItem.media_type !== MG_MEDIA_TYPE.PHOTO ? 'list_document_icon' : ''}
                      imgUrl={assetsItem.medium_size_url || 'list_document_icon'}
                      customClassname={'m-ast-itm m-ast-img'}
                      customName={'Media Gallery Asset'}
                    />
                  ) : (
                    <div className="video-outer-element">
                      <Video videoUrl={assetsItem.medium_size_url || ''} light={assetsItem.thumbnail_url} customClassname="m-ast-itm m-ast-video" />
                      {/* <ImageValidation imgUrl={assetsItem.thumbnail_url} customClassname={'m-ast-itm m-ast-img'} customName={'Media Gallery Asset'} /> */}
                    </div>
                  )}
                </div>
                <div className="m-ast-dtls" onClick={() => handleMGDetailsData(assetsItem.id)}>
                  <div className="mastg-main">
                    <div className="mast-prime-dtls">
                      <div className="mast-prof-pic">
                        <ImageValidation
                          imgUrl={assetsItem.created_user_profile_photo_url || ''}
                          defaultImg={'user-avatar-common.png'}
                          isNotSvgFormat
                          isImgValid
                          customClassname={''}
                          customName={'MG Profile Picture'}
                        />
                      </div>
                      <div className="mast-prof-txt">
                        <span className="sm-txt-top"> {assetsItem.name}</span>
                        {mediaType !== MgMediaType.DOCUMENTS ? (
                          <span className="lg-txt-base">
                            <span className="mlg-label">Uses</span>
                            <span className="mlg-count"> {assetsItem?.used_count || 0}</span>
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="mast-controls">
                      <CustomTooltip
                        customClassname="mg-tooltip__wrp"
                        customPlacement={TOOLTIP_PLACEMENT.TOP}
                        customTooltipText={MG_TOOLTIP_ICONS_NAME.VIEW}
                        customInput={() => (
                          <span>
                            <div className="mc-expand">
                              <ImageValidation isImgValid defaultImg={'expand'} customName="Open Detailed View" />
                            </div>
                          </span>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Masonry>
      </div>
      <DeleteAssetModalPopup
        showModal={Boolean(deleteItem?.id)}
        handleModalClose={() => setDeleteItem(null)}
        handleDelete={() => {
          if (deleteItem && Object.keys(deleteItem).length && id && userOwnership) {
            dispatch(
              mgDeleteAssetRequest({
                mediaType,
                assetId: deleteItem.id,
                reqPayload: {
                  ...deleteItem,
                  trashed: true
                }
              })
            );
            setDeleteItem(null);
          }
        }}
      />
      <FranSyndicatePostModal
        isModalShow={openSyndicateModal.isShowModal}
        mediaType={openSyndicateModal.mediaType}
        postOrMediaId={openSyndicateModal.assetId}
        handleModalClose={() => setOpenSyndicateModal({ isShowModal: false, mediaType: '', assetId: null })}
      />
    </>
  );
};
