import { createAction, ActionsUnion } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { commonCloudinary, commonModalPopup, IContentWarnOptions, loginApiType, loginReducerType, mgApiTypes } from 'types';

// CLOUDINARY SIGN
export const cloudSignRequest = (payload: commonCloudinary.ICloudinaryRequest) => createAction(actionTypes.CLOUD_SIGN_REQUEST, payload);
export const cloudSignFailure = (error: null | string) => createAction(actionTypes.CLOUD_SIGN_FAILURE, error);

export const loadConfigurationRequest = () => createAction(actionTypes.LOAD_ENV_CONFIGURATION_REQUEST);
export const loadConfigurationResponse = (payload: string) => createAction(actionTypes.LOAD_ENV_CONFIGURATION_RESPONSE, payload);

// GET CURRENT USER DETAIL
export const getCurrentUserDetailsRequest = (payload: string) => createAction(actionTypes.GET_CURRENT_USER_DETAILS_REQUEST, payload);
export const getCurrentUserDetailsResponse = (payload: loginReducerType.ILoginResponseUser) => createAction(actionTypes.GET_CURRENT_USER_DETAILS_RESPONSE, payload);
export const getCurrentUserDetailsFailure = (payload: null | string) => createAction(actionTypes.GET_CURRENT_USER_DETAILS_FAILURE, payload);

// GET CURRENT USER TAB LIST
export const getCurrentUserTabListRequest = (payload: commonModalPopup.IUserTabListRequest) => createAction(actionTypes.GET_CURRENT_USER_TAB_LIST_REQUEST, payload);
export const getCurrentUserTabListResponse = (data: commonModalPopup.IUserTabListResponse) => createAction(actionTypes.GET_CURRENT_USER_TAB_LIST_RESPONSE, data);
export const getCurrentUserTabListFailure = (error: null | string) => createAction(actionTypes.GET_CURRENT_USER_TAB_LIST_FAILURE, error);

// UPDATE CURRENT USER DETAIL
export const updateCurrentUserDetailsRequest = (payload: Partial<loginReducerType.ILoginResponseUser>) => createAction(actionTypes.UPDATE_CURRENT_USER_DETAILS_REQUEST, payload);
export const updateCurrentUserDetailsResponse = (payload: loginReducerType.ILoginResponseUser) => createAction(actionTypes.UPDATE_CURRENT_USER_DETAILS_RESPONSE, payload);
export const updateCurrentUserDetailsFailure = (payload: null | string) => createAction(actionTypes.UPDATE_CURRENT_USER_DETAILS_FAILURE, payload);

// UPLOAD CURRENT USER PROFILE IMG
export const uploadCurrentUserCloudProfileImgRequest = (payload: mgApiTypes.IMGUploadCloudMediaRequest) => createAction(actionTypes.UPLOAD_CURRENT_USER_CLOUD_PROFILE_IMG_REQUEST, payload);
export const uploadCurrentUserCloudProfileImgResponse = (data: loginApiType.IUserUploadProfileImgResp) => createAction(actionTypes.UPLOAD_CURRENT_USER_CLOUD_PROFILE_IMG_RESPONSE, data);
export const uploadCurrentUserCloudProfileImgFailure = (error: null | string) => createAction(actionTypes.UPLOAD_CURRENT_USER_CLOUD_PROFILE_IMG_FAILURE, error);

// COMMON STATE UPDATES
export const filterStatus = (payload: boolean) => createAction(actionTypes.FILTER_STATUS, payload);

export const toggleNav = () => createAction(actionTypes.TOGGLE_LEFT_NAV);
export const toggleFilter = () => createAction(actionTypes.TOGGLE_FILTER);
export const showHideFilterButton = (payload: boolean) => createAction(actionTypes.SHOW_HIDE_FILTER_BUTTON, payload);

// DATA LOADING
export const setIsDataLoading = (data: boolean) => createAction(actionTypes.IS_DATA_LOADING, data);

export const setSalesPages = (payload: string[]) => createAction(actionTypes.SET_SALES_PAGES, payload);

// CREATOR - SET COMPONENT NAME
export const creatorSetComponentName = (payload: null | string) => createAction(actionTypes.CREATOR_SET_COMPONENT_NAME, payload);

export const commonSetContentModifiedStatus = (data: boolean) => createAction(actionTypes.COMMON_SET_CONTENT_MODIFIED_STATUS, data);

// SET CURRENT USER IMG
export const setCurrentUserCloudProfileImg = (data: loginApiType.IUserUploadProfileImgResp) => createAction(actionTypes.SET_CURRENT_USER_CLOUD_PROFILE_IMG, data);

// SET IS EDIT CURRENT USER
export const setIsEditCurrentUser = (data: boolean) => createAction(actionTypes.SET_IS_EDIT_CURRENT_USER, data);

// SET CURRENT USER ACTIVE TAB
export const setCurrentUserActiveTab = (data: string) => createAction(actionTypes.SET_CURRENT_USER_ACTIVE_TAB, data);

// SET CURRENT USER SEARCH
export const setCurrentUserSearch = (data: string) => createAction(actionTypes.SET_CURRENT_USER_SEARCH, data);

// DISABLE STATE HANDLE - IN CREATOR
export const setCreatorTextAlertMsg = (data: null | string) => createAction(actionTypes.SET_CREATOR_TEXT_ALERT_MSG, data);

// SHOW PAGE NOT FOUND
export const isShowPageNotFound = (data: boolean) => createAction(actionTypes.IS_SHOW_PAGE_NOT_FOUND, data);

// CURRENT USER DETAILS STATUS RESET
export const currentUserDetailStatusReset = () => createAction(actionTypes.CURRENT_USER_DETAIL_STATUS_RESET);

// CURRENT USER DETAILS RESET
export const currentUserDetailsReset = () => createAction(actionTypes.CURRENT_USER_DETAILS_RESET);

export const commonSetContentWarningOption = (data: IContentWarnOptions) => createAction(actionTypes.COMMON_SET_CONTENT_WARNING_OPTION, data);

const COMMON_ACTIONS = {
  cloudSignRequest,
  cloudSignFailure,
  loadConfigurationRequest,
  loadConfigurationResponse,
  getCurrentUserDetailsRequest,
  getCurrentUserDetailsResponse,
  getCurrentUserDetailsFailure,
  getCurrentUserTabListRequest,
  getCurrentUserTabListResponse,
  getCurrentUserTabListFailure,
  updateCurrentUserDetailsRequest,
  updateCurrentUserDetailsResponse,
  updateCurrentUserDetailsFailure,
  uploadCurrentUserCloudProfileImgRequest,
  uploadCurrentUserCloudProfileImgResponse,
  uploadCurrentUserCloudProfileImgFailure,
  filterStatus,
  toggleNav,
  toggleFilter,
  showHideFilterButton,
  setIsDataLoading,
  setSalesPages,
  creatorSetComponentName,
  commonSetContentModifiedStatus,
  setCurrentUserCloudProfileImg,
  setIsEditCurrentUser,
  setCurrentUserActiveTab,
  setCurrentUserSearch,
  setCreatorTextAlertMsg,
  isShowPageNotFound,
  currentUserDetailStatusReset,
  commonSetContentWarningOption,
  currentUserDetailsReset
};

export type CommonActions = ActionsUnion<typeof COMMON_ACTIONS>;
