import { overviewContainerType, settingsRallioProfileContainerTypes } from 'types';
import { useAccountSwitcherData } from 'utils/hooks';
import { compareProfileObjects } from 'utils/helpers';
import { CONNECTION_TABS, RALLIO_PROFILE_OBJ_TYPE, RALLIO_PROFILE_TAB_FILEDS, RIPPLE_COLOR, TRANSLATE_LANG_OPTIONS } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { CustomDropdown } from 'widgets/CustomDropdown';
import { ImageValidation } from 'widgets/Media';
import { ToggleSwitch } from 'widgets/Switch';

export const PreferredLanguageSection = ({ values, actualValues, handleChange, handleIndividualCancel, handleIndividualSave }: settingsRallioProfileContainerTypes.ISettingsPreferredLangProps) => {
  const { optionalParams } = useAccountSwitcherData();

  return (
    <div className="settings-rallio-profile-new-section wn-urls wnh plfpWrap">
      <div className="content-full-wrp settins-content--wrp">
        <div className="content-left-section">
          <h3 className="global__title--txt">Preferred Language For Posts</h3>
        </div>

        <div className="content-right-section form-elements-new relative pr-a-wrap">
          <div className={`form-group form-field-item fg-dropdown m-align-items cs-item cs-clean`}>
            <CustomDropdown
              classNamePrefix="plsdMain"
              id="select-language-dropdown"
              placeholder={'Select language'}
              options={TRANSLATE_LANG_OPTIONS}
              value={TRANSLATE_LANG_OPTIONS.find((datum) => datum.value === values.preferred_language)}
              onChange={(eventObj: any) => handleChange(RALLIO_PROFILE_TAB_FILEDS.PREFERRED_LANGUAGE, eventObj?.value)}
              formatOptionLabel={(data: overviewContainerType.IOverviewLocationDropDown & { imgName: string }) => {
                return (
                  <div className="plsdItem" key={`languge-${data?.label}`}>
                    {data.imgName && <ImageValidation isImgValid defaultImg={data.imgName} customName="translate" customClassname="tranSign" />}
                    <span>{data.label}</span>
                  </div>
                );
              }}
            />
            <span className={`fltlabels`}>Choose Language</span>
          </div>
          <span className="pr-desc">
            Please note that this feature applies to scheduled and syndicated content from Corporate or Content Suppliers. The selected language will be used for these posts. To translate your own
            content, navigate to the Creator tab within the content section and adjust the translation settings there.
          </span>
          <div className="r-mt2">
            <b className="pr-desc ">Auto translate syndicated posts to preferred language</b>
            <div className="right-section">
              <div className="tog-options">
                <ToggleSwitch
                  id={'material-switch-12'}
                  handleOnChange={() => handleChange(RALLIO_PROFILE_TAB_FILEDS.ENABLE_AUTOMATIC_POST_TRANSLATIONS, !values.enable_automatic_post_translations)}
                  checkedStatus={values.enable_automatic_post_translations}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {optionalParams[2] !== CONNECTION_TABS.RALLIO_PROFILE && !compareProfileObjects(RALLIO_PROFILE_OBJ_TYPE.PREFERRED_LANGUAGES, values, actualValues) && (
        <div className="indivual-button-section">
          <div className="stg-item form-configure">
            <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
              <button className="ac-btn ac-secondary-white ac-outline size-xs border-0" onClick={() => handleIndividualCancel(RALLIO_PROFILE_OBJ_TYPE.PREFERRED_LANGUAGES)}>
                Cancel
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass={`ripple-unset ac-primary-box r-ml2`} custColor={RIPPLE_COLOR.primary}>
              <button className="ac-btn ac-primary size-xs" onClick={() => handleIndividualSave(RALLIO_PROFILE_OBJ_TYPE.PREFERRED_LANGUAGES)}>
                Save
              </button>
            </CustomRippleButton>
          </div>
        </div>
      )}
    </div>
  );
};
