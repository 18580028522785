import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { ICommon } from 'types/reducers/common';
import { CommonActions } from 'actions';
import { decryptEnvData } from 'utils/helpers';
import { CONTENT_WARN_INIT_OBJ, USER_DETAIL_INIT_OBJ } from 'utils/constants';

const initialState: ICommon = {
  isFilterOpen: false,
  // selectedNavBarData: {
  //   mainNavPageName: null,
  //   subNavPageName: null,
  //   selectedSubNavData: []
  // },
  leftNav: false,
  mobileFilter: false,
  showFilterBtn: true,
  creatorCompName: null,
  isDataLoading: false, // While the account switcher data is getting updated, this state is used for loading purpose
  isEnvFetching: false,
  envEncryptedData: '',
  decryptedEnvData: null,
  salesPages: [],
  showPageNotFound: false,
  contentModified: false,
  contentWarnOptions: CONTENT_WARN_INIT_OBJ,
  error: null,
  userDetailObj: USER_DETAIL_INIT_OBJ,
  creatorTextAlertMsg: null
};

export const commonReducer: Reducer<ICommon, CommonActions> = (state: ICommon = initialState, action: CommonActions) => {
  switch (action.type) {
    case types.CLOUD_SIGN_REQUEST:
      return {
        ...state
      };
    case types.CLOUD_SIGN_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case types.LOAD_ENV_CONFIGURATION_REQUEST:
      return {
        ...state,
        isEnvFetching: true
      };
    case types.LOAD_ENV_CONFIGURATION_RESPONSE:
      return {
        ...state,
        isEnvFetching: false,
        envEncryptedData: action.payload,
        decryptedEnvData: decryptEnvData(action.payload)
      };
    case types.GET_CURRENT_USER_DETAILS_REQUEST:
      return {
        ...state,
        userDetailObj: {
          ...initialState.userDetailObj,
          isFetching: true
        }
      };
    case types.GET_CURRENT_USER_DETAILS_RESPONSE:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isFetching: false,
          currentUserDetails: action.payload,
          userProfileObj: {
            cloudinaryId: action.payload?.profile_photo_cloudinary_id || null,
            photoUrl: action.payload?.profile_photo_url || null
          }
        }
      };
    case types.GET_CURRENT_USER_DETAILS_FAILURE:
      return {
        ...state,
        userDetailObj: {
          ...initialState.userDetailObj,
          error: action.payload || 'No data found',
          isFetching: false
        }
      };
    case types.GET_CURRENT_USER_TAB_LIST_REQUEST:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isListFetching: true,
          search: action.payload.search || '',
          currentPage: action.payload.page || 0
        }
      };
    case types.GET_CURRENT_USER_TAB_LIST_RESPONSE:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isListFetching: false,
          ...(action.payload?.assetsList
            ? { assetsList: state.userDetailObj.currentPage > 1 ? state.userDetailObj.assetsList.concat(action.payload.assetsList) : action.payload.assetsList || [] }
            : []),
          ...(action.payload?.postsList ? { postsList: state.userDetailObj.currentPage > 1 ? state.userDetailObj.postsList.concat(action.payload?.postsList) : action.payload?.postsList || [] } : []),
          ...(action.payload?.rewardsList
            ? { rewardsList: state.userDetailObj.currentPage > 0 ? state.userDetailObj.rewardsList.concat(action.payload?.rewardsList) : action.payload?.rewardsList || [] }
            : [])
        }
      };
    case types.GET_CURRENT_USER_TAB_LIST_FAILURE:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isListFetching: false
        }
      };
    case types.UPDATE_CURRENT_USER_DETAILS_REQUEST:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isUserDataUpdate: true,
          updateUserDetailStatus: {
            isUpdated: false,
            isFailed: false
          }
        }
      };
    case types.UPDATE_CURRENT_USER_DETAILS_RESPONSE:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          currentUserDetails: action.payload,
          isUserDataUpdate: false,
          userProfileObj: {
            cloudinaryId: action.payload.profile_photo_cloudinary_id,
            photoUrl: action.payload.profile_photo_url
          },
          updateUserDetailStatus: {
            ...state.userDetailObj.updateUserDetailStatus,
            isUpdated: true
          }
        }
      };
    case types.UPDATE_CURRENT_USER_DETAILS_FAILURE:
      return {
        ...state,
        userDetailObj: {
          ...initialState.userDetailObj,
          isUserDataUpdate: false,
          updateUserDetailStatus: {
            ...state.userDetailObj.updateUserDetailStatus,
            isFailed: true
          }
        }
      };

    case types.UPLOAD_CURRENT_USER_CLOUD_PROFILE_IMG_REQUEST:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isProfileImgFetching: true
        }
      };
    case types.UPLOAD_CURRENT_USER_CLOUD_PROFILE_IMG_RESPONSE:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isProfileImgFetching: false,
          userProfileObj: action.payload
        }
      };
    case types.UPLOAD_CURRENT_USER_CLOUD_PROFILE_IMG_FAILURE:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isProfileImgFetching: false,
          userProfileObj: initialState.userDetailObj.userProfileObj
        }
      };
    case types.FILTER_STATUS:
      return {
        ...state,
        isFilterOpen: action.payload
      };
    case types.TOGGLE_LEFT_NAV:
      return {
        ...state,
        leftNav: !state.leftNav,
        mobileFilter: false
      };
    case types.TOGGLE_FILTER:
      return {
        ...state,
        mobileFilter: !state.mobileFilter,
        leftNav: false
      };
    case types.SHOW_HIDE_FILTER_BUTTON:
      return {
        ...state,
        showFilterBtn: action.payload
      };
    case types.CREATOR_SET_COMPONENT_NAME:
      return {
        ...state,
        creatorCompName: action.payload
      };
    case types.IS_DATA_LOADING:
      return {
        ...state,
        isDataLoading: action.payload
      };
    case types.SET_SALES_PAGES:
      return {
        ...state,
        salesPages: action.payload
      };

    case types.COMMON_SET_CONTENT_MODIFIED_STATUS:
      return {
        ...state,
        contentModified: action.payload
      };
    case types.COMMON_SET_CONTENT_WARNING_OPTION:
      return {
        ...state,
        contentWarnOptions: action.payload
      };

    case types.SET_CURRENT_USER_CLOUD_PROFILE_IMG:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          userProfileObj: action.payload
        }
      };
    case types.SET_IS_EDIT_CURRENT_USER:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isEditUser: action.payload
        }
      };
    case types.SET_CURRENT_USER_ACTIVE_TAB:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          activeTab: action.payload,
          search: initialState.userDetailObj.search,
          assetsList: initialState.userDetailObj.assetsList,
          postsList: initialState.userDetailObj.postsList,
          rewardsList: initialState.userDetailObj.rewardsList
        }
      };
    case types.SET_CURRENT_USER_SEARCH:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          search: action.payload
        }
      };
    case types.SET_CREATOR_TEXT_ALERT_MSG:
      return {
        ...state,
        creatorTextAlertMsg: action.payload
      };
    case types.IS_SHOW_PAGE_NOT_FOUND:
      return {
        ...state,
        showPageNotFound: action.payload
      };
    case types.CURRENT_USER_DETAIL_STATUS_RESET:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isUserDataUpdate: false,
          updateUserDetailStatus: initialState.userDetailObj.updateUserDetailStatus
        }
      };
    case types.CURRENT_USER_DETAILS_RESET:
      return {
        ...state,
        userDetailObj: initialState.userDetailObj
      };
    default:
      return state;
  }
};
