import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { apFranContainerTypes, IStore } from 'types';
import { commonSetContentModifiedStatus, commonSetContentWarningOption } from 'actions';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { CONTENT_WARN_INIT_OBJ, RIPPLE_COLOR } from 'utils/constants';

export const CommonSaveChangesConfirmModal = ({ isModalShow, handleModalClose, handleNavigation }: apFranContainerTypes.IFranCreatorSaveChangesPopup) => {
  const dispatch = useDispatch();

  const contentWarnOptions = useSelector((state: IStore) => state.common.contentWarnOptions);

  const handleUnsaveChanges = () => {
    dispatch(commonSetContentModifiedStatus(false));
    dispatch(commonSetContentWarningOption(CONTENT_WARN_INIT_OBJ));
    if (!contentWarnOptions.disableNavigation) {
      handleNavigation();
    }
    handleModalClose();
  };

  return (
    // <Modal className="prime-modal modal-confirmation confirm-delete creator-modal-confirm-new" size="sm" aria-labelledby="contained-modal-title-vcenter" centered show={isModalShow} animation={false}>
    <Modal className="cnfrm__delete--modal glbl__delete--modal glbl__unsave--modal" size="sm" aria-labelledby="contained-modal-title-vcenter" centered show={isModalShow} animation={false}>
      <Modal.Header>
        <div className="modal-icon-wraps">
          <span className="modal-itm-wraps modal-danger-wraps modal-info-wraps" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="unsave__modal--txt">{contentWarnOptions.message || `You have unsaved changes that will be lost. Continue anyway?`}</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <div className="modal-btn-grp-wraps delete__btn--wrp"> */}
        {contentWarnOptions.showOnlyContinueBtn ? (
          <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
            <button className="ac-btn ac-primary ac-block" onClick={handleUnsaveChanges}>
              Ok
            </button>
          </CustomRippleButton>
        ) : (
          <div className="delete__btn--wrp">
            {!contentWarnOptions.showOnlyContinueBtn ? (
              <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                <button className="ac-btn ac-secondary-white ac-outline ac-block border-0" onClick={handleModalClose}>
                  Cancel
                </button>
              </CustomRippleButton>
            ) : null}
            <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
              <button className="ac-btn ac-primary ac-block" onClick={handleUnsaveChanges}>
                Ok
              </button>
            </CustomRippleButton>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};
