import { settingsRallioProfileContainerTypes } from 'types';
import { RALLIO_PROFILE_TAB_FILEDS, VALID_PHONE_NUMBER_REGEX_PROFILE } from 'utils/constants';

export const BusinessInfoSection = ({
  accountId,
  values,
  actualValues,
  handleBlur,
  handleChange,
  getInputTitleClassName,
  getInputBoxClassName,
  getClassNames
}: settingsRallioProfileContainerTypes.ISettingsCommonCompProps) => {
  return (
    <div className="settings-rallio-profile-new-section wn-businessInfo wnh">
      <div className="wn-top-cnt">
        <h3>
          <span>Business Information used to Personalize Your Posts</span>
        </h3>
        <p>
          When a content creator designs a post that includes customizable fields, they can choose to utilize any combination of these fields.
          <strong>The specific data below that is used will be displayed in your posts.</strong>
        </p>
      </div>
      <div className="content-full-wrp settins-content--wrp">
        <div className="content-left-section">
          <h3 className="global__title--txt">Business Info</h3>
        </div>
        <div className="content-right-section form-elements-new">
          <div className={`form-group form-field-item ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_NAME)}`}>
            <input
              type="text"
              name={RALLIO_PROFILE_TAB_FILEDS.BUSINESS_NAME}
              className={getClassNames(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_NAME)}
              value={values[RALLIO_PROFILE_TAB_FILEDS.BUSINESS_NAME] ? String(values[RALLIO_PROFILE_TAB_FILEDS.BUSINESS_NAME]) : ''}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_NAME, e.target.value);
              }}
            />
            <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_NAME)}`}>Business Name</span>
          </div>

          <div className={`form-group form-field-item m-align-items ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE)}`}>
            <input
              type="text"
              maxLength={(values[RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE] as string)?.length === 16 ? 16 : undefined}
              name={RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE}
              className={getClassNames(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE)}
              value={values[RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE] ? String(values[RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE]) : ''}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE, e.target.value);
              }}
              onKeyPress={(event) => {
                const regexData = new RegExp(VALID_PHONE_NUMBER_REGEX_PROFILE);
                const enteredKey = String.fromCharCode(event.charCode ? event.which : event.charCode);
                if (!regexData.test(enteredKey)) {
                  event.preventDefault();
                  return false;
                }
              }}
            />
            <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE)}`}>
              Phone Number
              {/* {actualValues.required_field === APPROVED_STATUS.YES && <span className="mandatory cursor-help">*</span>} */}
            </span>
          </div>

          <div className={`form-group form-field-item ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_MAILING)}`}>
            <input
              type="text"
              name={RALLIO_PROFILE_TAB_FILEDS.BUSINESS_MAILING}
              className={getClassNames(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_MAILING)}
              value={values[RALLIO_PROFILE_TAB_FILEDS.BUSINESS_MAILING] ? String(values[RALLIO_PROFILE_TAB_FILEDS.BUSINESS_MAILING]) : ''}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_MAILING, e.target.value);
              }}
            />
            <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_MAILING)}`}>Mailing Address</span>
          </div>

          <div className={`form-group form-field-item ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_EMAIL)}`}>
            <input
              type="text"
              name={RALLIO_PROFILE_TAB_FILEDS.BUSINESS_EMAIL}
              className={getClassNames(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_EMAIL)}
              value={values[RALLIO_PROFILE_TAB_FILEDS.BUSINESS_EMAIL] ? String(values[RALLIO_PROFILE_TAB_FILEDS.BUSINESS_EMAIL]) : ''}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_EMAIL, e.target.value);
              }}
            />
            <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_EMAIL)}`}>Email Address</span>
          </div>

          {accountId && (
            <div className={`form-group form-field-item ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.MANAGER)}`}>
              <input
                type="text"
                name={RALLIO_PROFILE_TAB_FILEDS.MANAGER}
                className={getClassNames(RALLIO_PROFILE_TAB_FILEDS.MANAGER)}
                value={String(values[RALLIO_PROFILE_TAB_FILEDS.MANAGER] || '')}
                autoComplete="off"
                onBlur={handleBlur}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(RALLIO_PROFILE_TAB_FILEDS.MANAGER, e.target.value);
                }}
              />
              <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.MANAGER)}`}>Manager Name</span>
            </div>
          )}
        </div>
      </div>

      {/* {optionalParams[2] !== CONNECTION_TABS.RALLIO_PROFILE && !compareProfileObjects(RALLIO_PROFILE_OBJ_TYPE.BUSINESS_INFO, values, actualValues) && (
        <div className="indivual-button-section">
          <div className="stg-item form-configure">
            <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
              <button className="ac-btn ac-secondary-white ac-outline size-xs border-0" onClick={() => handleIndividualCancel(RALLIO_PROFILE_OBJ_TYPE.BUSINESS_INFO)}>
                Cancel
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass={`ripple-unset ac-primary-box r-ml2`} custColor={RIPPLE_COLOR.primary}>
              <button className="ac-btn ac-primary size-xs" onClick={() => handleIndividualSave(RALLIO_PROFILE_OBJ_TYPE.BUSINESS_INFO)}>
                Save
              </button>
            </CustomRippleButton>
          </div>
        </div>
      )} */}
    </div>
  );
};
