import { postsContainerTypes, postsReducerTypes } from 'types';
import { CONNECTION_SORT_TYPES } from '../connections';

export const CONTENT_SUPPLIERS_DROPDOWN_OBJ = { id: 0, value: 'All Brands', label: 'All Content Suppliers', type: 'All' };

export const POSTS_STATUS_TYPE = {
  ALL: 'all',
  APPROVED: 'approved',
  AWAITING_APPROVAL: 'awaiting_approval',
  AA_MY_CONTENT: 'aa_my_content',
  AA_LOCATION_CONTENT: 'aa_location_content',
  DRAFT: 'draft',
  REJECTED: 'rejected',
  AI_GENERATED: 'ai_generated'
};

export enum PostType {
  HAS_PHOTO = 'has_photo',
  HAS_LINK = 'has_link',
  HAS_COPY = 'has_copy'
}

export const POSTS_FILTERS_POST_TYPE_ARRAY = [
  { value: PostType.HAS_PHOTO, label: 'Image' },
  { value: PostType.HAS_LINK, label: 'Link' },
  { value: PostType.HAS_COPY, label: 'Copy' }
];

export enum PostUsageType {
  ALL = 'all',
  USED = '1',
  UNUSED = '0'
}

export const POSTS_FILTER_USAGE_TYPE = [
  { value: PostUsageType.ALL, label: 'All' },
  { value: PostUsageType.USED, label: 'Used' },
  { value: PostUsageType.UNUSED, label: 'Unused' }
];

export enum AIPostType {
  ALL = 'all',
  AI_ASSISTED = '1',
  NON_AI_ASSISTED = '0'
}

export const AI_POSTS_FILTER_TYPE = [
  { value: AIPostType.ALL, label: 'All' },
  { value: AIPostType.AI_ASSISTED, label: 'AI Assisted' },
  { value: AIPostType.NON_AI_ASSISTED, label: 'Non-AI Assisted' }
];

export const POSTS_FILTERS_ACC_STATUS_ARRAY = [
  { value: 'all', label: 'All' },
  { value: 'approved', label: 'Ready' },
  { value: 'awaiting_approval', label: 'Pending' },
  { value: 'ai_generated', label: 'AI Generated' },
  { value: 'draft', label: 'Draft' },
  { value: 'rejected', label: 'Rejected' }
];

export const POSTS_FILTERS_FRAN_STATUS_ARRAY = [
  { value: 'all', label: 'All' },
  { value: 'approved', label: 'Ready' },
  { value: 'aa_my_content', label: 'Awaiting Approval (My Content)' },
  { value: 'aa_location_content', label: 'Awaiting Approval (Location Content)' },
  { value: 'ai_generated', label: 'Awaiting Approval (AI Generated)' },
  { value: 'draft', label: 'Draft' }
];

export const POST_DETAIL_SCHEDULING_TYPES = {
  MANUAL: `Manual Only`,
  AUTO_SCHEDULED: `Auto Scheduling Set - system will automatically schedule this package for you`,
  SYNDICATE_TO_ACC_DB: `Syndicate To Account Databases`
};

export const POSTS_DETAIL_TAB_NAMES = {
  DETAILS: 'details',
  INTERNAL_NOTE: 'comments',
  BOOST_OFFER: 'boost_offer',
  SCHEDULE: 'schedule',
  PUBLISH_STATUS: 'publish_status'
};

export const POSTS_TOOLTIP_NAMES = {
  EDIT: 'Edit',
  SCHEDULE: 'Schedule',
  INTERNAL_NOTES: 'Internal Notes',
  BOOST_OFFER: 'Boost Offer',
  VIEW: 'View',
  LINK: 'Link',
  COPY: 'Copy',
  AI_POST: 'Generate Similar Post',
  AI_MEDIA: 'Generate Post',
  CAMP_POST_ALREADY_EXISTED: 'Already available in this campaign'
};

export const POSTS_EDIT_DETAIL_OBJ = { isPostDetailLoading: false, isGetAccountDetail: false };

export const POSTS_SYN_CONTENT_OBJ = { isShowModal: false, isSyndicateConfirm: false };

export const POSTS_SYNDICATE_CONTENT_MSG = `This content has already been syndicated to multiple accounts - meaning it has been placed into the databases of end users.
It doesn't necessarily mean they have used it, but they have access to it.
Do you want to schedule this content for them now? If so, press OK.`;

export enum PostDateType {
  ALL = 'all',
  SPECIFIC_DATE_RANGE = 'specific_posts'
}

export const POSTS_DATE_RANGE_FILTER = [
  { label: 'Show all posts', value: PostDateType.ALL },
  { label: 'Specific date range', value: PostDateType.SPECIFIC_DATE_RANGE }
];

export enum PostsValidQueryParams {
  TYPE = 'type',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  PLATFORM = 'platform',
  STATUS = 'status',
  AI_POST = 'ai_post',
  USAGE = 'usage',
  HAS_PHOTO = 'has_photo',
  HAS_LINK = 'has_link',
  HAS_COPY = 'has_copy',
  TAGS_LIST = 'tags_list',
  SEARCH = 'text',
  FAVORITE_TAGS = 'favorite_tags',
  LOCATION = 'location',
  SOURCE = 'source',
  SYNDICATED_TO_ANY_FRANCHISOR = 'syndicated_to_any_franchisor',
  SYNDICATED_TO_ANY_ACCOUNT = 'syndicated_to_any_account',
  MULTI_SYNDICATED_TO_ANY_FRANCHISOR = 'multi_syndicated_to_any_franchisor',
  MULTI_SYNDICATED_TO_ANY_ACCOUNT = 'multi_syndicated_to_any_account'
}

export const POSTS_INIT_FILTER_DATA: postsReducerTypes.IPostsFilters = {
  posts: 'all',
  status: 'all',
  ai_post: 'all',
  usage: 'all',
  media_type: [],
  platform: 'all',
  search: '',
  dateRange: {
    fromDate: { selected: null, timeStamp: null },
    toDate: { selected: null, timeStamp: null }
  },
  content_supplier_id: 'all',
  contentSupplierFilterObj: CONTENT_SUPPLIERS_DROPDOWN_OBJ,
  location: '',
  source: 'all'
};

export const POST_DETAIL_TYPE = {
  AI_POST: 'ai_post'
};

export const POST_PUBLISHED_STATUS_SORT_TYPES: postsContainerTypes.IPPSSortTypes = {
  ...CONNECTION_SORT_TYPES,
  INSTAGRAM: 'instagram',
  NOTES: 'notes',
  SUCCESS_RATE: 'success_rate'
};

export enum PostOrImageEditStatus {
  ALLOWED = 'Allowed',
  NOT_ALLOWED = 'Not Allowed'
}

export enum PostSourceType {
  BRAND = 'brand',
  HUB = 'hub'
}
