import { put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import { accountUpdateProfileResponse, exceptionHandlerRequest, franchisorGetOrUpdateProfileResponse, logoutRequest } from 'actions';
import { apiCall, defaultHeader, API, NODE_API } from 'utils/helpers';
import {
  aiPlaybookGetVerticalFailure,
  aiPlaybookGetVerticalResponse,
  settingsAIPlayBookUpdateFailure,
  settingsAIPlayBookGetUpdateResponse,
  aiPlaybookGetDocumentsListResponse,
  aiPlaybookGetDocumentsListFailure,
  aiPlaybookDeleteDocumentFailure,
  aiPlaybookCreateDocumentFailure,
  aiPlaybookCreateDocumentResponse,
  aiPlaybookDeleteDocumentResponse,
  aiPlaybookCustomerPersonaResponse,
  aiPlaybookCustomerPersonaFailure,
  aiPlaybookGetActivityGoalTypesResponse,
  aiPlaybookGetActivityGoalTypesFailure,
  aiPlaybookGetActivityGoalsListResponse,
  aiPlaybookGetActivityGoalsListFailure,
  aiPlaybookCreateUpdateActivityGoalsResponse,
  aiPlaybookCreateUpdateActivityGoalsFailure,
  aiPlaybookDeleteActivityGoalsResponse,
  aiPlaybookDeleteActivityGoalsFailure,
  aiPlaybookGenerateComplianceSummaryResponse,
  aiPlaybookGenerateComplianceSummaryFailure
} from 'actions/modules/settings-ai-playbook';
import { ISagaPayload } from 'types/common/api';
import { alertBoxCall } from 'components';
import {
  AI_PLAYBOOK_ERROR,
  ALERT_BOX_TYPES,
  AI_PLAYBOOK_UPDATE_FAILED,
  AI_PLAYBOOK_UPDATE_SUCCESS,
  USER_OWNERSHIP,
  AI_PLAYBOOK_DOCUMENT_DELETE_SUCCESS_MSG,
  AI_PLAYBOOK_DOCUMENT_CREATE_SUCCESS_MSG,
  API_TYPES,
  ACTIVITY_GOAL_CREATE_SUCCESS_MSG,
  ACTIVITY_GOAL_UPDATE_SUCCESS_MSG,
  ACTIVITY_GOAL_UPDATE_FAILURE_MSG,
  ACTIVITY_GOAL_CREATE_FAILURE_MSG,
  ACTIVITY_GOAL_DELETE_SUCCESS_MSG,
  ACTIVITY_GOAL_DELETE_FAILURE_MSG,
  ComplianceType
  // AI_PLAYBOOK_COMPLIANCE_SUMMARY_HAS_WARNING_MSG
} from 'utils/constants';
import { settingsAIPlayBookReducerTypes } from 'types';

function* sendAIPlayBookVerticalRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, ...API.getAddContentVerticals });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(aiPlaybookGetVerticalResponse(response.data.verticals));
    } else {
      yield put(aiPlaybookGetVerticalFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(AI_PLAYBOOK_ERROR));
  }
}

function* sendAIPlayBookUpdateRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiObj = payload.userOwnership === USER_OWNERSHIP.FRANCHISOR ? API.updateFranchisor : API.accountsUpdateProfile;
    const urlPathObj = { apiPath: apiObj.apiPath.replace(':id', `${payload.id}`), action: apiObj.action };
    const response = yield apiCall({ headers, ...{ data: { [payload.userOwnership]: payload.editedContent } }, ...urlPathObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      const aiPlaybookData: Partial<settingsAIPlayBookReducerTypes.IAIPlayBook> =
        payload.userOwnership === USER_OWNERSHIP.FRANCHISOR && payload.isTopLevelBrand
          ? {
              name: response.data.franchisor?.name,
              questions: response.data.franchisor?.questions,
              business_url: response.data.franchisor?.business_url || '',
              vertical_id: response.data.franchisor?.vertical_id,
              business_description: response.data?.franchisor.business_description,
              customer_persona_text: response.data?.franchisor.customer_persona_text,
              customer_persona_usage_level: response.data?.franchisor.customer_persona_usage_level,
              // ai_compliance_description: response.data?.franchisor.ai_compliance_description,
              ai_compliance_summary: response.data?.franchisor.ai_compliance_summary,
              ai_compliance_image_summary: response.data?.franchisor.ai_compliance_image_summary,
              ai_compliance_words: response.data?.franchisor.ai_compliance_words,
              ai_compliance_categories: response.data?.franchisor.ai_compliance_categories,
              ai_compliance_tone_styles: response.data?.franchisor.ai_compliance_tone_styles,
              ai_compliance_terminology_language: response.data?.franchisor.ai_compliance_terminology_language,
              ai_compliance_formatting_guidelines: response.data?.franchisor.ai_compliance_formatting_guidelines,
              ai_compliance_image_restrictions: response.data?.franchisor.ai_compliance_image_restrictions,
              ai_compliance_additional_feedback: response.data?.franchisor.ai_compliance_additional_feedback,
              auto_generate_post: response.data?.franchisor.auto_generate_post
            }
          : {
              business_url: response.data[payload.userOwnership]?.url || '',
              questions: response.data[payload.userOwnership]?.questions,
              business_description: response.data[payload.userOwnership]?.business_description,
              customer_persona_text: response.data[payload.userOwnership]?.customer_persona_text,
              customer_persona_usage_level: response.data[payload.userOwnership]?.customer_persona_usage_level,
              // ai_compliance_description: response.data[payload.userOwnership]?.ai_compliance_description,
              auto_generate_post: response.data[payload.userOwnership]?.auto_generate_post
            };
      if (payload.userOwnership === USER_OWNERSHIP.ACCOUNT) {
        yield put(accountUpdateProfileResponse(response.data.account));
      } else {
        yield put(franchisorGetOrUpdateProfileResponse(response.data));
      }
      yield put(settingsAIPlayBookGetUpdateResponse(aiPlaybookData));
      if (payload?.showAlert !== false) {
        alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_PLAYBOOK_UPDATE_SUCCESS);
      }
    } else {
      yield put(settingsAIPlayBookUpdateFailure(response.data.error));
      alertBoxCall(ALERT_BOX_TYPES.ERROR, AI_PLAYBOOK_UPDATE_FAILED);
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(AI_PLAYBOOK_ERROR));
  }
}

function* sendAIPlayBookDocumentsListRequest({ payload }: ISagaPayload): any {
  try {
    const params = {
      ...payload,
      per: 100
    };
    const headers = defaultHeader();
    const response = yield apiCall({ headers, params, ...API.getAIPlaybookDocumentsList });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(aiPlaybookGetDocumentsListResponse({ data: response.data.documents, isAIPlaybook: payload.ai_playbook ?? false }));
    } else {
      yield put(aiPlaybookGetDocumentsListFailure({ errMsg: response.data.message, isAIPlaybook: payload.ai_playbook ?? false }));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(AI_PLAYBOOK_ERROR));
  }
}

function* sendAIPlayBookDocumentDeleteRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiObj = API.deleteAIPlaybookDocument;
    const path = apiObj.apiPath.replace(':id', payload.id);
    const response = yield apiCall({ headers, apiPath: path, action: apiObj.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_PLAYBOOK_DOCUMENT_DELETE_SUCCESS_MSG);
      yield put(aiPlaybookDeleteDocumentResponse(payload));
    } else {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, response.data.message);
      yield put(aiPlaybookDeleteDocumentFailure(response.data.message));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(AI_PLAYBOOK_ERROR));
  }
}

function* sendAIPlayBookDocumentCreateRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiObj = API.createAIPlaybookDocument;
    const data = {
      ...payload,
      copy_to_descendants: true
    };
    const response = yield apiCall({ headers, data, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_PLAYBOOK_DOCUMENT_CREATE_SUCCESS_MSG);
      yield put(aiPlaybookCreateDocumentResponse({ data: response.data.document, isAIPlaybook: payload?.ai_playbook ?? false }));
    } else {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, response.data.error);
      yield put(aiPlaybookCreateDocumentFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(AI_PLAYBOOK_ERROR));
  }
}
function* sendAIPlayBookCustomerPersonaRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = payload;
    const response = yield apiCall({ headers, data, ...NODE_API.createCustomerPersona, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(aiPlaybookCustomerPersonaResponse(response.data?.data?.choices?.[0]?.text));
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, response.data.message);
    } else {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, response.data.error);
      yield put(aiPlaybookCustomerPersonaFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(AI_PLAYBOOK_ERROR));
  }
}

function* sendAIPlayBookActivityGoalTypesRequest(): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, ...API.getActivityGoalTypes });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(aiPlaybookGetActivityGoalTypesResponse(response.data.activity_goals));
    } else {
      yield put(aiPlaybookGetActivityGoalTypesFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(AI_PLAYBOOK_ERROR));
  }
}

function* sendAIPlayBookGetActivityGoalsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, params: payload, ...API.getActivityGoalsList });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(aiPlaybookGetActivityGoalsListResponse(response.data.activity_goals));
    } else {
      yield put(aiPlaybookGetActivityGoalsListFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(AI_PLAYBOOK_ERROR));
  }
}

function* sendAIPlayBookCreateUpdateActivityGoalsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiAction = payload?.id ? API.updateActivityGoals : API.createActivityGoals;
    const path = payload?.id ? API.updateActivityGoals.apiPath.replace(':id', payload?.id) : API.createActivityGoals.apiPath;
    const response = yield apiCall({ headers, data: { activity_goal: payload.activity_goal }, apiPath: path, action: apiAction.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, payload?.id ? ACTIVITY_GOAL_UPDATE_SUCCESS_MSG : ACTIVITY_GOAL_CREATE_SUCCESS_MSG);
      yield put(
        aiPlaybookCreateUpdateActivityGoalsResponse({ activity_goal: response.data.activity_goals, message: payload?.id ? ACTIVITY_GOAL_UPDATE_SUCCESS_MSG : ACTIVITY_GOAL_CREATE_SUCCESS_MSG })
      );
    } else {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, payload?.id ? ACTIVITY_GOAL_UPDATE_FAILURE_MSG : ACTIVITY_GOAL_CREATE_FAILURE_MSG);
      yield put(aiPlaybookCreateUpdateActivityGoalsFailure(response.data.error));
    }
  } catch (error) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, payload?.id ? ACTIVITY_GOAL_UPDATE_FAILURE_MSG : ACTIVITY_GOAL_CREATE_FAILURE_MSG);
    yield put(exceptionHandlerRequest(AI_PLAYBOOK_ERROR));
  }
}

function* sendAIPlayBookDeleteActivityGoalsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiObj = API.deleteActivityGoals;
    const path = apiObj.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, data: payload, apiPath: path, action: apiObj.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 204) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, ACTIVITY_GOAL_DELETE_SUCCESS_MSG);
      yield put(aiPlaybookDeleteActivityGoalsResponse(ACTIVITY_GOAL_DELETE_SUCCESS_MSG));
    } else {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, ACTIVITY_GOAL_DELETE_FAILURE_MSG);
      yield put(aiPlaybookDeleteActivityGoalsFailure(response.data.error));
    }
  } catch (error) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, ACTIVITY_GOAL_DELETE_FAILURE_MSG);
    yield put(exceptionHandlerRequest(AI_PLAYBOOK_ERROR));
  }
}

function* sendAIPlayBookGenerateComplianceSummaryRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const isCaptionType = payload.type === ComplianceType.CAPTION;
    const apiObj = isCaptionType ? NODE_API.generateCaptionComplianceSummary : NODE_API.generateImageComplianceSummary;
    const response = yield apiCall({ headers, data: payload?.reqPayload, ...apiObj, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      const complianceSummarySplit = isCaptionType ? response?.data?.data?.choices?.[0]?.text.split(`\n\n`) : response?.data?.data?.choices?.[0]?.text.split(`Warning: `);
      // if (isComplianceSummaryHasWarning) {
      //   alertBoxCall(ALERT_BOX_TYPES.ERROR, AI_PLAYBOOK_COMPLIANCE_SUMMARY_HAS_WARNING_MSG);
      // } else {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, response?.data?.message);
      // }
      yield put(
        aiPlaybookGenerateComplianceSummaryResponse({
          summary: complianceSummarySplit?.[0],
          warningMsg: isCaptionType ? complianceSummarySplit?.[1] : complianceSummarySplit?.[1] ? `Warning: ${complianceSummarySplit?.[1]}` : '',
          type: payload?.type
        })
      );
      if (payload?.onSuccess) {
        payload?.onSuccess(complianceSummarySplit?.[0]);
      }
    } else {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, response?.data?.message);
      yield put(aiPlaybookGenerateComplianceSummaryFailure(response?.data?.message));
    }
  } catch (error) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, ACTIVITY_GOAL_DELETE_FAILURE_MSG);
    yield put(exceptionHandlerRequest(AI_PLAYBOOK_ERROR));
  }
}

export function* takeSettingsAIPlayBookRequest() {
  yield takeEvery(types.AI_PLAYBOOK_GET_DOCUMENTS_LIST_REQUEST, sendAIPlayBookDocumentsListRequest);
  yield takeEvery(types.AI_PLAYBOOK_CREATE_DOCUMENT_REQUEST, sendAIPlayBookDocumentCreateRequest);

  yield takeLatest(types.AI_PLAYBOOK_GET_VERTICAL_REQUEST, sendAIPlayBookVerticalRequest);
  yield takeLatest(types.AI_PLAYBOOK_UPDATE_REQUEST, sendAIPlayBookUpdateRequest);
  yield takeLatest(types.AI_PLAYBOOK_DELETE_DOCUMENT_REQUEST, sendAIPlayBookDocumentDeleteRequest);
  yield takeLatest(types.AI_PLAYBOOK_CREATE_CUSTOMER_PERSONA_REQUEST, sendAIPlayBookCustomerPersonaRequest);
  yield takeLatest(types.AI_PLAYBOOK_GET_ACTIVITY_GOAL_TYPES_REQUEST, sendAIPlayBookActivityGoalTypesRequest);
  yield takeLatest(types.AI_PLAYBOOK_GET_ACTIVITY_GOALS_LIST_REQUEST, sendAIPlayBookGetActivityGoalsListRequest);
  yield takeLatest(types.AI_PLAYBOOK_CREATE_UPDATE_ACTIVITY_GOALS_REQUEST, sendAIPlayBookCreateUpdateActivityGoalsRequest);
  yield takeLatest(types.AI_PLAYBOOK_DELETE_ACTIVITY_GOALS_REQUEST, sendAIPlayBookDeleteActivityGoalsRequest);
  yield takeLatest(types.AI_PLAYBOOK_GENERATE_COMPLIANCE_SUMMARY_REQUEST, sendAIPlayBookGenerateComplianceSummaryRequest);
}
