// SETTINGS AI PLAYBOOK - UPDATE
export const AI_PLAYBOOK_UPDATE_REQUEST = 'aiPlaybook/AI_PLAYBOOK_UPDATE_REQUEST';
export const AI_PLAYBOOK_GET_UPDATE_RESPONSE = 'aiPlaybook/AI_PLAYBOOK_GET_UPDATE_RESPONSE';
export const AI_PLAYBOOK_UPDATE_FAILURE = 'aiPlaybook/AI_PLAYBOOK_UPDATE_FAILURE';

// SETTINGS AI PLAYBOOK VERTICAL
export const AI_PLAYBOOK_GET_VERTICAL_REQUEST = 'aiPlaybook/AI_PLAYBOOK_GET_VERTICAL_REQUEST';
export const AI_PLAYBOOK_GET_VERTICAL_RESPONSE = 'aiPlaybook/AI_PLAYBOOK_GET_VERTICAL_RESPONSE';
export const AI_PLAYBOOK_GET_VERTICAL_FAILURE = 'aiPlaybook/AI_PLAYBOOK_GET_VERTICAL_FAILURE';

// SETTINGS KEYWORDS DOCUMENTS LIST - GET
export const AI_PLAYBOOK_GET_DOCUMENTS_LIST_REQUEST = 'aiPlaybook/AI_PLAYBOOK_GET_DOCUMENTS_LIST_REQUEST';
export const AI_PLAYBOOK_GET_DOCUMENTS_LIST_RESPONSE = 'aiPlaybook/AI_PLAYBOOK_GET_DOCUMENTS_LIST_RESPONSE';
export const AI_PLAYBOOK_GET_DOCUMENTS_LIST_FAILURE = 'aiPlaybook/AI_PLAYBOOK_GET_DOCUMENTS_LIST_FAILURE';

// SETTINGS KEYWORDS DOCUMENT - CREATE
export const AI_PLAYBOOK_CREATE_DOCUMENT_REQUEST = 'aiPlaybook/AI_PLAYBOOK_CREATE_DOCUMENT_REQUEST';
export const AI_PLAYBOOK_CREATE_DOCUMENT_RESPONSE = 'aiPlaybook/AI_PLAYBOOK_CREATE_DOCUMENT_RESPONSE';
export const AI_PLAYBOOK_CREATE_DOCUMENT_FAILURE = 'aiPlaybook/AI_PLAYBOOK_CREATE_DOCUMENT_FAILURE';

// SETTINGS KEYWORDS DOCUMENT - DELETE
export const AI_PLAYBOOK_DELETE_DOCUMENT_REQUEST = 'aiPlaybook/AI_PLAYBOOK_DELETE_DOCUMENT_REQUEST';
export const AI_PLAYBOOK_DELETE_DOCUMENT_RESPONSE = 'aiPlaybook/AI_PLAYBOOK_DELETE_DOCUMENT_RESPONSE';
export const AI_PLAYBOOK_DELETE_DOCUMENT_FAILURE = 'aiPlaybook/AI_PLAYBOOK_DELETE_DOCUMENT_FAILURE';

// SETTINGS CUSTOMER PERSONA
export const AI_PLAYBOOK_CREATE_CUSTOMER_PERSONA_REQUEST = 'aiPlaybook/AI_PLAYBOOK_CREATE_CUSTOMER_PERSONA_REQUEST';
export const AI_PLAYBOOK_CREATE_CUSTOMER_PERSONA_RESPONSE = 'aiPlaybook/AI_PLAYBOOK_CREATE_CUSTOMER_PERSONA_RESPONSE';
export const AI_PLAYBOOK_CREATE_CUSTOMER_PERSONA_FAILURE = 'aiPlaybook/AI_PLAYBOOK_CREATE_CUSTOMER_PERSONA_FAILURE';

// AI PLAYBOOK ACTIVITY GOALS LIST- GET
export const AI_PLAYBOOK_GET_ACTIVITY_GOALS_LIST_REQUEST = 'aiPlaybook/AI_PLAYBOOK_GET_ACTIVITY_GOALS_LIST_REQUEST';
export const AI_PLAYBOOK_GET_ACTIVITY_GOALS_LIST_RESPONSE = 'aiPlaybook/AI_PLAYBOOK_GET_ACTIVITY_GOALS_LIST_RESPONSE';
export const AI_PLAYBOOK_GET_ACTIVITY_GOALS_LIST_FAILURE = 'aiPlaybook/AI_PLAYBOOK_GET_ACTIVITY_GOALS_LIST_FAILURE';

// AI PLAYBOOK ACTIVITY GOALS - CREATE AND UPDATE
export const AI_PLAYBOOK_CREATE_UPDATE_ACTIVITY_GOALS_REQUEST = 'aiPlaybook/AI_PLAYBOOK_CREATE_UPDATE_ACTIVITY_GOALS_REQUEST';
export const AI_PLAYBOOK_CREATE_UPDATE_ACTIVITY_GOALS_RESPONSE = 'aiPlaybook/AI_PLAYBOOK_CREATE_UPDATE_ACTIVITY_GOALS_RESPONSE';
export const AI_PLAYBOOK_CREATE_UPDATE_ACTIVITY_GOALS_FAILURE = 'aiPlaybook/AI_PLAYBOOK_CREATE_UPDATE_ACTIVITY_GOALS_FAILURE';

// AI PLAYBOOK ACTIVITY GOALS - DELETE
export const AI_PLAYBOOK_DELETE_ACTIVITY_GOALS_REQUEST = 'aiPlaybook/AI_PLAYBOOK_DELETE_ACTIVITY_GOALS_REQUEST';
export const AI_PLAYBOOK_DELETE_ACTIVITY_GOALS_RESPONSE = 'aiPlaybook/AI_PLAYBOOK_DELETE_ACTIVITY_GOALS_RESPONSE';
export const AI_PLAYBOOK_DELETE_ACTIVITY_GOALS_FAILURE = 'aiPlaybook/AI_PLAYBOOK_DELETE_ACTIVITY_GOALS_FAILURE';

// AI PLAYBOOK ACTIVITY GOALS TYPES- GET
export const AI_PLAYBOOK_GET_ACTIVITY_GOAL_TYPES_REQUEST = 'aiPlaybook/AI_PLAYBOOK_GET_ACTIVITY_GOAL_TYPES_REQUEST';
export const AI_PLAYBOOK_GET_ACTIVITY_GOAL_TYPES_RESPONSE = 'aiPlaybook/AI_PLAYBOOK_GET_ACTIVITY_GOAL_TYPES_RESPONSE';
export const AI_PLAYBOOK_GET_ACTIVITY_GOAL_TYPES_FAILURE = 'aiPlaybook/AI_PLAYBOOK_GET_ACTIVITY_GOAL_TYPES_FAILURE';

// SETTINGS GENERATE COMPLIANCE SUMMARY
export const AI_PLAYBOOK_GENERATE_COMPLIANCE_SUMMARY_REQUEST = 'aiPlaybook/AI_PLAYBOOK_GENERATE_COMPLIANCE_SUMMARY_REQUEST';
export const AI_PLAYBOOK_GENERATE_COMPLIANCE_SUMMARY_RESPONSE = 'aiPlaybook/AI_PLAYBOOK_GENERATE_COMPLIANCE_SUMMARY_RESPONSE';
export const AI_PLAYBOOK_GENERATE_COMPLIANCE_SUMMARY_FAILURE = 'aiPlaybook/AI_PLAYBOOK_GENERATE_COMPLIANCE_SUMMARY_FAILURE';

// AI PLAYBOOK RESET MESSAGE
export const AI_PLAYBOOK_RESET_MESSAGE = 'aiPlaybook/AI_PLAYBOOK_RESET_MESSAGE';

// SETTINGS DOCUMENT RESPONSE- RESET
export const AI_PLAYBOOK_RESET = 'aiPlaybook/AI_PLAYBOOK_RESET';

// SETTINGS DOCUMENT UPLOAD TYPE
export const AI_PLAYBOOK_SET_DOC_UPLOAD_TYPE = 'aiPlaybook/AI_PLAYBOOK_SET_DOC_UPLOAD_TYPE';

// AI PLAYBOOK RESET GENERATION
export const AI_PLAYBOOK_RESET_SUMMARY_GENERATION = 'aiPlaybook/AI_PLAYBOOK_RESET_SUMMARY_GENERATION';
