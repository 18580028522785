import { apAccountContainerTypes, calendarApiTypes, commonProps, postsReducerTypes } from 'types';

export enum PostPublishedStatus {
  PUBLISHED = 'PUBLISHED',
  PLATFORM_NOT_CONNECTED = 'PLATFORM_NOT_CONNECTED',
  PLATFORM_NOT_SELECTED = 'PLATFORM_NOT_SELECTED',
  POST_MISSING_ATTRIBUTES = 'POST_MISSING_ATTRIBUTES',
  POST_NOT_APPROVED = 'POST_NOT_APPROVED',
  PLATFORM_NEEDS_RECONNECTION = 'PLATFORM_NEEDS_RECONNECTION',
  SCHEDULED_SUCCESSFULLY = 'SCHEDULED_SUCCESSFULLY',
  POST_FAILED = 'POST_FAILED',
  POST_CANCELLED = 'POST_CANCELLED',
  POST_UNPUBLISHABLE = 'POST_UNPUBLISHABLE',
  PUBLISHING = 'PUBLISHING',
  RETRYING = 'RETRYING'
}
export const ADD_POST_IMAGES_ACTIVE_TAB = {
  ADD_NEW_IMAGE: 'add_new_image',
  MY_IMAGES: 'my_images',
  BRAND_IMAGES: 'brand_images',
  BROWSE_IMAGES: 'browse_images',
  LOCAL_IMAGES: 'local_images'
};

export const ADD_POST_IMAGES_ACTIVE_TAB_NAME = {
  ADD_NEW_IMAGE: 'Add New Image',
  MY_IMAGES: 'My Images',
  BRAND_IMAGES: 'Brand Images',
  BROWSE_IMAGES: 'Browse Images',
  LOCAL_IMAGES: 'Local Images'
};

export const ADD_POST_VIDEO_ACTIVE_TAB = {
  ADD_NEW_VIDEO: 'add_new_video',
  MY_VIDEOS: 'my_videos',
  BRAND_VIDEOS: 'brand_videos',
  BROWSE_VIDEOS: 'browse_videos',
  LOCAL_VIDEOS: 'local_videos'
};

export const ADD_POST_VIDEO_ACTIVE_TAB_NAME = {
  ADD_NEW_VIDEO: 'Add New Video',
  MY_VIDEOS: 'My Videos',
  BRAND_VIDEOS: 'Brand Videos',
  BROWSE_VIDEOS: 'Browse Videos',
  LOCAL_VIDEOS: 'Local Videos'
};

export const ADD_POST_ACTION_TYPE = {
  POST_NOW: 'post_now',
  SCHEDULE_POST: 'schedule_post',
  SAVE_TO_DATABASE: 'save_to_database',
  SEND_FOR_APPROVAL: 'send_for_approval',
  UPDATE_SAVED_POST: 'update_saved_post',
  SAVE_AS_DRAFT: 'save_as_draft',
  SINGLE_CAMPAIGN_POST: 'campaign_post',
  MULTIPLE_CAMPAIGN_POSTS: 'multiple_campaign_posts'
};

export const AP_ACTION_TYPES = {
  ADD: 'add_post',
  EDIT: 'edit_post'
};

export const AP_INTERNAL_NOTES_TYPES = {
  SAVED_POST: 'saved_post_comments'
};

export const ADD_POST_CONTENT_MAX_TEXT_LENGTH: commonProps.ICommonObj<number> = {
  TWITTER: 280,
  LINKEDIN: 3000,
  INSTAGRAM: 2200,
  GOOGLE: 1500
};

export const ADD_POST_STATUS_NAME = {
  REJECTED: 'Rejected',
  DRAFT: 'Draft',
  AWAITING_APPROVAL: 'Awaiting Approval',
  APPROVED: 'Approved',
  PENDING_APPROVAL: 'Pending Approval',
  AWAITING_APPROVAL_AI_GENERATED: 'Awaiting Approval - AI Generated'
};

export const AP_MEDIA_USED = {
  USE_IMAGE: 'Use Image',
  USE_IMAGES: 'Use Images',
  USE_VIDEO: 'Use Video'
};

export const AP_ADD_BOOST_DURATION_DROPDOWN: apAccountContainerTypes.IAddPostDurationObj[] = [...new Array(30)].map((_1, index) => {
  const value = index + 1;
  const dayString = value === 1 ? 'Day' : 'Days';
  return { id: value, value: `${value} ${dayString}`, label: `${value} ${dayString}` };
});

export const AP_EXTRA_INFO_TAB_NAMES = {
  SETTINGS: 'settings',
  INTERNAL_TAGS: 'internal_tags',
  INTERNAL_NOTES: 'internal_notes',
  CAMPAIGNS: 'campaigns'
};

export const AP_EMPLOYEE_ADVOCATES_TOOLTIP = `Checking this option provides your Employee Advocates the ability to post
this content to their personal profiles.`;

export const AP_LOCK_POST_TOOLTIP = `Checking this option locks the post from being edited. Only you can unlock it.`;

export const AP_ADD_TO_POST_TAB_NAMES = {
  TAG_PAGES: 'tag_pages',
  PHOTOS: 'photos',
  VIDEOS: 'videos',
  COUPON: 'coupon',
  LINK: 'link',
  BOOST: 'boost',
  SCHEDULE: 'schedule',
  POST_NOW: 'post_now',
  VIEW_CALENDAR: 'view_calendar',
  CAMPAIGN: 'campaign',
  CALL_TO_ACTION: 'call_to_action',
  FILES: 'files',
  HASHTAGS: 'hashtags',
  THUMBNAIL: 'thumbnail'
};

export const AP_ADD_TO_POST_TAB_TOOLTIP = {
  EMOJI: 'Emoji',
  TAG_PAGES: 'Tags',
  IMAGES: 'Images',
  VIDEOS: 'Videos',
  COUPONS: 'Coupons',
  LINKS: 'Links',
  BOOST: 'Boost',
  CALL_TO_ACTION: 'Call To Action',
  AI_ASSISTANT: 'AI Assistant',
  HASHTAGS: 'Hashtags'
};

export const AP_UPCOMING_DAYS_TOOLTIP_INIT_OBJ = { classname: `day-0${0}`, postCount: 0, approvalPostsCount: 0 };

export const AP_INSTA_PHOTO_PREVIEW_LIST: commonProps.IMainPageRouteData[] = [
  { name: 'pad', label: 'Pad Instagram Image' },
  { name: 'crop', label: 'Crop Instagram Image' }
];

export enum CreatorInstaPhotoType {
  PAD = 'pad',
  CROP = 'crop'
}

export const AP_PREVIEW_TYPES = {
  COUPON: 'COUPON',
  LINK: 'LINK'
};

export const CREATOR_USER_OWNERSHIP = {
  ACCOUNT: 'account',
  LOCATION_CONTENT: 'location-content',
  FRANCHISOR: 'franchisor'
};

export const POST_STATUS_IMAGE_NAME = {
  GREEN: 'active-green',
  YELLOW: 'yellowCircle',
  RED: 'warning',
  GREY: 'deactive-grey'
};

export const CREATOR_EDIT_INIT_OBJ: postsReducerTypes.IPostDetailsData = {
  account: null,
  boost_offer_id: null,
  cloudinary_resource_type: null,
  cloudinary_video_id: null,
  created_at: '',
  created_user: {
    id: 0,
    name: '',
    profile_photo_url: null
  },
  end_date: null,
  facebook_text: '',
  franchisor: {
    id: 0,
    name: ''
  },
  google_text: '',
  id: 0,
  instagram_text: '',
  link_preview_description: '',
  link_preview_image_url: '',
  link_preview_image_url_choices: [],
  link_preview_title: '',
  linkedin_text: '',
  link_preview_customized: false,
  locked: false,
  mobile_app_only_enabled: true,
  pad_instagram_photos: false,
  page_tag_details: {},
  photo_url: '',
  photo_urls: [],
  post_published_count: 0,
  preview_facebook_text: '',
  preview_long_text: '',
  preview_short_text: '',
  rejected: false,
  start_date: null,
  status_id: 0,
  syndicated_from_content_id: null,
  tags_list: '',
  trashed: false,
  twitter_text: '',
  tiktok_text: '',
  updated_at: '',
  url: '',
  use_facebook: false,
  use_google: false,
  use_instagram: false,
  use_linkedin: false,
  use_twitter: false,
  use_twitter_photo: false,
  use_tiktok: false,
  video_url: null,
  instagram_photo_url: null,
  instagram_reel: false,
  facebook_reel: false,
  google_call_to_action_type: null,
  google_call_to_action_url: null,
  facebook_call_to_action_type: null,
  facebook_call_to_action_link: null,
  video_cover_cloudinary_id: null,
  facebook_story: false,
  instagram_story: false
};

export const CREATOR_PAGE_TAG_INFO_TOOLTIP = 'Use “@” followed by handle name to mention accounts or users in a post.';

export const PostPublishStatusNotes = {
  ERROR: 'Error posting on <platform>',
  SUCCESS: 'Post Successful',
  NOT_APPROVED_FUTURE: 'Post has not been approved',
  NOT_APPROVED_PAST: 'Post was not approved',
  POST_SCHEDULED: 'Post Scheduled',
  PLATFORM_NOT_CONNECTED: 'Platform not connected'
};

export const POST_PUBLISHED_STATUS_ARR = [
  { status: PostPublishedStatus.PUBLISHED, imageName: POST_STATUS_IMAGE_NAME.GREEN, tooltipText: 'Post published successfully to <platform>  <published_at>' },
  { status: PostPublishedStatus.PUBLISHING, imageName: POST_STATUS_IMAGE_NAME.GREEN, tooltipText: 'The post is currently being published.' },
  { status: PostPublishedStatus.SCHEDULED_SUCCESSFULLY, imageName: POST_STATUS_IMAGE_NAME.GREEN, tooltipText: 'The post is scheduled.' },
  { status: PostPublishedStatus.PLATFORM_NOT_CONNECTED, imageName: POST_STATUS_IMAGE_NAME.GREY, tooltipText: 'Location not connected to <platform>.' },
  { status: PostPublishedStatus.PLATFORM_NOT_SELECTED, imageName: POST_STATUS_IMAGE_NAME.GREY, tooltipText: '<platform> not enabled for this post.' },
  { status: PostPublishedStatus.POST_UNPUBLISHABLE, imageName: POST_STATUS_IMAGE_NAME.GREY, tooltipText: 'The post was not published.' },
  { status: PostPublishedStatus.POST_MISSING_ATTRIBUTES, imageName: POST_STATUS_IMAGE_NAME.GREY, tooltipText: '<platform> not enabled for this post.' },
  { status: PostPublishedStatus.POST_NOT_APPROVED, imageName: POST_STATUS_IMAGE_NAME.YELLOW, tooltipText: 'The post was rejected or is awaiting approval.' },
  {
    status: PostPublishedStatus.PLATFORM_NEEDS_RECONNECTION,
    imageName: POST_STATUS_IMAGE_NAME.YELLOW,
    tooltipText: 'The post is scheduled but your <platform> connection needs to be re-established. Please reconnect <platform> for this location.'
  },
  { status: PostPublishedStatus.POST_FAILED, imageName: POST_STATUS_IMAGE_NAME.RED, tooltipText: 'The post failed to publish to <platform> and will no longer be retried.' },
  { status: PostPublishedStatus.POST_CANCELLED, imageName: POST_STATUS_IMAGE_NAME.RED, tooltipText: 'The post was cancelled by a user.' },
  { status: PostPublishedStatus.RETRYING, imageName: POST_STATUS_IMAGE_NAME.RED, tooltipText: 'The post failed to publish, but will be retried.' }
];

export const ADD_CUSTOMIZE_LINK_FIELDS: Record<string, keyof Omit<calendarApiTypes.ICouponLinks, 'id'>> = {
  URL: 'url',
  LINK_PREVIEW_TITLE: 'link_preview_title',
  LINK_PREVIEW_DESCRIPTION: 'link_preview_description',
  LINK_PREVIEW_IMAGE_URL: 'link_preview_image_url',
  LINK_PREVIEW_IMAGE_URL_CHOICES: 'link_preview_image_url_choices'
};
