import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';

import { aiImageGenertaionReducerTypes as aiImageGenerationReducerTypes } from 'types';

// CREATOR - GET AI CONTENT WRITER DATA
export const updateAIImageGenerationSearchResponse = (payload: aiImageGenerationReducerTypes.IAIImageGenerationRequestPayload) =>
  createAction(actionTypes.AI_IMAGE_GENERATION_FIELD_UPDATE_RESPONSE, payload);

// GET AI GENERATED IMAGES
export const getAIImageGenerationImagesRequest = (payload: aiImageGenerationReducerTypes.IAIImageGenerationRequestPayload) => createAction(actionTypes.AI_IMAGE_GENERATION_REQUEST, payload);
export const getAIImageGenerationImagesResponse = (payload: aiImageGenerationReducerTypes.IAIGeneratedImages[]) => createAction(actionTypes.AI_IMAGE_GENERATION_RESPONSE, payload);
export const getAIImageGenerationImagesFailure = (error: null | string) => createAction(actionTypes.AI_IMAGE_GENERATION_FAILURE, error);

// GET AI STABLE DIFFUSION GENERATED IMAGES
export const getAIStableDiffusionImageGenerationRequest = (payload: aiImageGenerationReducerTypes.IAIImageRequestPayload) =>
  createAction(actionTypes.AI_STABLE_DIFFUSION_IMAGE_GENERATION_REQUEST, payload);
export const getAIStableDiffusionImageGenerationResponse = (payload: aiImageGenerationReducerTypes.IAIImageResponsPayload) =>
  createAction(actionTypes.AI_STABLE_DIFFUSION_IMAGE_GENERATION_RESPONSE, payload);
export const getAIStableDiffusionImageGenerationFailure = (error: null | string) => createAction(actionTypes.AI_STABLE_DIFFUSION_IMAGE_GENERATION_FAILURE, error);

// GET AI STABLE DIFFUSION GENERATED IMAGES BY ID
export const getAIStableDiffusionImageGenerationRequestById = (payload: { Id: string }) => createAction(actionTypes.AI_STABLE_DIFFUSION_IMAGE_GENERATION_REQUEST_BY_ID, payload);
export const getAIStableDiffusionImageGenerationResponseById = (payload: aiImageGenerationReducerTypes.IAIImageResponsPayload) =>
  createAction(actionTypes.AI_STABLE_DIFFUSION_IMAGE_GENERATION_RESPONSE_BY_ID, payload);

// RESET AI IMAGE GENERATION
export const resetAIImageGenerationImages = () => createAction(actionTypes.AI_IMAGE_GENERATION_RESET);

// RESET STABLE DIFFUSION AI IMAGE GENERATION
export const resetAIStableDiffusionImageGenerationImages = () => createAction(actionTypes.AI_STABLE_DIFFUSION_IMAGE_GENERATION_REQUEST_BY_ID_RESET);

const AI_IMAGE_GENERATION_ACTIONS = {
  updateAIImageGenerationSearchResponse,
  getAIImageGenerationImagesRequest,
  getAIImageGenerationImagesResponse,
  getAIImageGenerationImagesFailure,
  resetAIImageGenerationImages,
  getAIStableDiffusionImageGenerationRequest,
  getAIStableDiffusionImageGenerationResponse,
  getAIStableDiffusionImageGenerationFailure,
  getAIStableDiffusionImageGenerationRequestById,
  getAIStableDiffusionImageGenerationResponseById,
  resetAIStableDiffusionImageGenerationImages
};

export type AiImageGenerationActions = ActionsUnion<typeof AI_IMAGE_GENERATION_ACTIONS>;
