import { FormikErrors } from 'formik';

import { RALLIO_PROFILE_TAB_FILEDS, RALLIO_PROFILE_OBJ_TYPE, APPROVED_STATUS, RALLIO_PROFIILE_CUSTOM_FIELDS } from 'utils/constants';
import { accountApiTypes, directoryListingsContainerTypes, settingsRallioProfileContainerTypes, settingsRallioProfileReducerTypes } from 'types';
import { compareTwoObjects } from 'utils/helpers';

export const formatRallioProfileRequestPayload = (payload: settingsRallioProfileContainerTypes.IRPTabFeildValue) => {
  const tempPayload: any = {
    ...payload,
    custom_fields: payload.custom_fields
  };
  return tempPayload;
};

export const addAccountProfileReqPayload = (rallioProfileData: settingsRallioProfileContainerTypes.IRPTabFeildValue, hubId: number): accountApiTypes.IAddAccountProfileReqPayload => {
  return {
    name: rallioProfileData.name,
    street: rallioProfileData.street || null,
    short_name: rallioProfileData.short_name || '',
    city: rallioProfileData.city || null,
    phone: rallioProfileData.phone || null,
    zip: rallioProfileData.zip || null,
    business_name: rallioProfileData.business_name || '',
    phone_number: rallioProfileData.phone_number || '',
    mailing_address: rallioProfileData.mailing_address || '',
    business_email: rallioProfileData.business_email || '',
    url: rallioProfileData.url || null,
    state: rallioProfileData.state || null,
    custom_fields: rallioProfileData.custom_fields,
    country_code: rallioProfileData.country_code,
    time_zone: rallioProfileData.time_zone,
    job_page_url: rallioProfileData.job_page_url || '',
    account_profile_id: null,
    activated: false,
    calendar_scheduling_feature: false,
    calendar_scheduling_feature_enabled: false,
    created_at: null,
    description: null,
    directory_listing_feature: false,
    directory_listings_feature_enabled: false,
    dlm_city: null,
    dlm_country_code: null,
    dlm_facebook_url: null,
    dlm_hide_address: false,
    dlm_linkedin_url: null,
    dlm_name: null,
    dlm_phone: null,
    dlm_postal_code: null,
    dlm_state: null,
    dlm_store_code: null,
    dlm_street: null,
    dlm_twitter_url: null,
    dlm_url: null,
    employee_leaderboard_feature: false,
    employee_leaderboard_feature_enabled: false,
    employee_login: false,
    employee_login_enabled: false,
    franchisor_id: hubId,
    friendly_name: null,
    gmb_feature: false,
    gmb_feature_enabled: false,
    google_places_account_address: null,
    google_places_account_image_url: null,
    google_places_account_maps_url: null,
    google_places_account_name: null,
    google_places_account_phone: null,
    google_places_google_plus_id: null,
    latitude: null,
    longitude: null,
    media_asset_like_dislike_feature: false,
    media_asset_like_dislike_feature_enabled: false,
    news_rss_feed_urls: [],
    owner_email: null,
    owner_name: null,
    payment_methods: [],
    person_to_reach: rallioProfileData.person_to_reach,
    personal_profiles_feature_enabled: false,
    place_failure_count: false,
    place_id: null,
    reviews_approval_feature: false,
    reviews_approval_feature_enabled: false,
    synup_additional_category_ids: [],
    synup_location_id: null,
    synup_sub_category_id: null,
    synup_sub_category_name: null,
    tagline: null,
    tone_cautionary: 0,
    tone_educational: 0,
    tone_empathetic: 0,
    tone_engaging: 0,
    tone_humorous: 0,
    tone_inspirational: 0,
    tone_professional: 0,
    tone_quirky: 0,
    tz_offset: null,
    tzinfo_name: null,
    use_synup: false,
    vertical_id: null,
    year_of_incorporation: null,
    yelp_account_id: null,
    yelp_account_image_url: null,
    yelp_account_name: null,
    yelp_account_phone: null,
    yelp_business_id: null,
    enable_automatic_post_translations: rallioProfileData.enable_automatic_post_translations || false
  };
};

export const compareProfileObjects = (objType: string, values: settingsRallioProfileContainerTypes.IRPTabFeildValue, rallioProfile: settingsRallioProfileReducerTypes.IRallioProfileValue) => {
  /* if (objType === RALLIO_PROFILE_OBJ_TYPE.PROFILE_INFO) {
    let isSame = false;
    const existingVal = {
      [RALLIO_PROFILE_TAB_FILEDS.NAME]: rallioProfile.name,
      [RALLIO_PROFILE_TAB_FILEDS.STORE_CODE]: rallioProfile.short_name
    };
    const currentValObj = {
      [RALLIO_PROFILE_TAB_FILEDS.NAME]: values.name,
      [RALLIO_PROFILE_TAB_FILEDS.STORE_CODE]: values.short_name,
    };
    isSame = compareTwoObjects(existingVal, currentValObj);
    return isSame;
  } else  */ if (objType === RALLIO_PROFILE_OBJ_TYPE.ADDRESS) {
    let isSame = false;
    const existingVal = {
      [RALLIO_PROFILE_TAB_FILEDS.NAME]: rallioProfile.name,
      [RALLIO_PROFILE_TAB_FILEDS.STORE_CODE]: rallioProfile.short_name,
      [RALLIO_PROFILE_TAB_FILEDS.ADDRESS]: rallioProfile.street,
      [RALLIO_PROFILE_TAB_FILEDS.CITY]: rallioProfile.city,
      [RALLIO_PROFILE_TAB_FILEDS.STATE_OR_PROVINCE]: rallioProfile.state,
      [RALLIO_PROFILE_TAB_FILEDS.ZIP_OR_POSTAL_CODE]: rallioProfile.zip,
      [RALLIO_PROFILE_TAB_FILEDS.COUNTRY]: rallioProfile.country_code,
      [RALLIO_PROFILE_TAB_FILEDS.PHONE_NUMBER]: rallioProfile.phone,
      [RALLIO_PROFILE_TAB_FILEDS.TIME_ZONE]: rallioProfile.time_zone
    };
    const currentValObj = {
      [RALLIO_PROFILE_TAB_FILEDS.NAME]: values.name,
      [RALLIO_PROFILE_TAB_FILEDS.STORE_CODE]: values.short_name,
      [RALLIO_PROFILE_TAB_FILEDS.ADDRESS]: values.street,
      [RALLIO_PROFILE_TAB_FILEDS.CITY]: values.city,
      [RALLIO_PROFILE_TAB_FILEDS.STATE_OR_PROVINCE]: values.state,
      [RALLIO_PROFILE_TAB_FILEDS.ZIP_OR_POSTAL_CODE]: values.zip,
      [RALLIO_PROFILE_TAB_FILEDS.COUNTRY]: values.country_code,
      [RALLIO_PROFILE_TAB_FILEDS.PHONE_NUMBER]: values.phone,
      [RALLIO_PROFILE_TAB_FILEDS.TIME_ZONE]: values.time_zone
    };
    isSame = compareTwoObjects(existingVal, currentValObj);
    return isSame;
  } else if (objType === RALLIO_PROFILE_OBJ_TYPE.PREFERRED_LANGUAGES) {
    const existingVal = {
      [RALLIO_PROFILE_TAB_FILEDS.PREFERRED_LANGUAGE]: rallioProfile.preferred_language,
      [RALLIO_PROFILE_TAB_FILEDS.ENABLE_AUTOMATIC_POST_TRANSLATIONS]: rallioProfile.enable_automatic_post_translations
    };
    const currentValObj = {
      [RALLIO_PROFILE_TAB_FILEDS.PREFERRED_LANGUAGE]: values.preferred_language,
      [RALLIO_PROFILE_TAB_FILEDS.ENABLE_AUTOMATIC_POST_TRANSLATIONS]: values.enable_automatic_post_translations
    };
    return compareTwoObjects(existingVal, currentValObj);
  } else if (objType === RALLIO_PROFILE_OBJ_TYPE.URL) {
    let isSame = false;
    const existingVal = {
      [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_NAME]: rallioProfile.business_name,
      [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE]: rallioProfile.phone_number,
      [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_MAILING]: rallioProfile.mailing_address,
      [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_EMAIL]: rallioProfile.business_email,
      [RALLIO_PROFILE_TAB_FILEDS.MANAGER]: rallioProfile.person_to_reach,
      [RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL]: rallioProfile.job_page_url,
      [RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL]: rallioProfile.url
    };
    const currentValObj = {
      [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_NAME]: values.business_name,
      [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE]: values.phone_number,
      [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_MAILING]: values.mailing_address,
      [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_EMAIL]: values.business_email,
      [RALLIO_PROFILE_TAB_FILEDS.MANAGER]: values.person_to_reach,
      [RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL]: values.job_page_url,
      [RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL]: values.url
    };
    isSame = compareTwoObjects(existingVal, currentValObj);
    return isSame;
  } else if (objType === RALLIO_PROFILE_OBJ_TYPE.CUSTOM_FIELDS) {
    const rallioProfileByCustomFields = RALLIO_PROFIILE_CUSTOM_FIELDS.reduce((acc: Record<string, unknown>, curr: string) => {
      acc[curr] = rallioProfile.custom_fields[curr] || '';
      return acc;
    }, {});
    const valuesByCustomFields = RALLIO_PROFIILE_CUSTOM_FIELDS.reduce((acc: Record<string, unknown>, curr: string) => {
      acc[curr] = values.custom_fields[curr];
      return acc;
    }, {});
    let isSame = false;
    isSame = compareTwoObjects(rallioProfileByCustomFields, valuesByCustomFields);
    return isSame;
  } else if (objType === RALLIO_PROFILE_OBJ_TYPE.CTA_FIELDS) {
    const updatedCTAFields = Object.keys(values.custom_fields).filter((field) => {
      return !RALLIO_PROFIILE_CUSTOM_FIELDS.includes(field);
    });
    const initialCTAFields = Object.keys(rallioProfile.custom_fields).filter((field) => {
      return !RALLIO_PROFIILE_CUSTOM_FIELDS.includes(field);
    });
    const rallioProfileByCTAFields = initialCTAFields.reduce((acc: Record<string, string>, curr: string) => {
      acc[curr] = rallioProfile.custom_fields[curr];
      return acc;
    }, {});
    const valuesByCTAFields = updatedCTAFields.reduce((acc: Record<string, string>, curr: string) => {
      acc[curr] = values.custom_fields[curr];
      return acc;
    }, {});
    let isSame = false;
    isSame = compareTwoObjects(rallioProfileByCTAFields, valuesByCTAFields);
    return isSame;
  } else return false;
};

export const getIndividualSaveStatus = (objType: string, errors: FormikErrors<settingsRallioProfileContainerTypes.IRPTabFeildValue>, requiredField: string) => {
  /* if (objType === RALLIO_PROFILE_OBJ_TYPE.PROFILE_INFO) {
    return errors.name || errors.short_name ? true : false;
  } else */ if (objType === RALLIO_PROFILE_OBJ_TYPE.ADDRESS) {
    return requiredField === APPROVED_STATUS.YES
      ? errors.street || errors.city || errors.state || errors.zip || errors.country_code || errors.phone || errors.time_zone
        ? true
        : false
      : errors.city || errors.country_code || errors.time_zone
      ? true
      : false;
  } else if (objType === RALLIO_PROFILE_OBJ_TYPE.URL) {
    return errors.business_name || errors.phone_number || errors.mailing_address || errors.business_email || errors.person_to_reach || errors.url ? true : false;
  } else return false;
};

export const getUpdatedValues = (objType: string, values: settingsRallioProfileContainerTypes.IRPTabFeildValue) => {
  let val: Partial<settingsRallioProfileContainerTypes.IRPTabFeildValue> = { ...values };
  /*  if (objType === RALLIO_PROFILE_OBJ_TYPE.PROFILE_INFO) {
    val = {
      [RALLIO_PROFILE_TAB_FILEDS.NAME]: values.name,
      [RALLIO_PROFILE_TAB_FILEDS.STORE_CODE]: values.short_name
    };
  } else */ if (objType === RALLIO_PROFILE_OBJ_TYPE.ADDRESS) {
    val = {
      [RALLIO_PROFILE_TAB_FILEDS.NAME]: values.name,
      [RALLIO_PROFILE_TAB_FILEDS.STORE_CODE]: values.short_name,
      [RALLIO_PROFILE_TAB_FILEDS.ADDRESS]: values.street,
      [RALLIO_PROFILE_TAB_FILEDS.CITY]: values.city,
      [RALLIO_PROFILE_TAB_FILEDS.STATE_OR_PROVINCE]: values.state,
      [RALLIO_PROFILE_TAB_FILEDS.ZIP_OR_POSTAL_CODE]: values.zip,
      [RALLIO_PROFILE_TAB_FILEDS.COUNTRY]: values.country_code,
      [RALLIO_PROFILE_TAB_FILEDS.PHONE_NUMBER]: values.phone,
      [RALLIO_PROFILE_TAB_FILEDS.TIME_ZONE]: values.time_zone
    };
  } /* else if (objType === RALLIO_PROFILE_OBJ_TYPE.BUSINESS_INFO) {
    val = {
      [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_NAME]: values.business_name,
      [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE]: values.phone_number,
      [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_MAILING]: values.mailing_address,
      [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_EMAIL]: values.business_email,
      [RALLIO_PROFILE_TAB_FILEDS.MANAGER]: values.person_to_reach
    };
  } */ else if (objType === RALLIO_PROFILE_OBJ_TYPE.URL) {
    val = {
      [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_NAME]: values.business_name,
      [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE]: values.phone_number,
      [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_MAILING]: values.mailing_address,
      [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_EMAIL]: values.business_email,
      [RALLIO_PROFILE_TAB_FILEDS.MANAGER]: values.person_to_reach,
      [RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL]: values.job_page_url,
      [RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL]: values.url
    };
  } else if (objType === RALLIO_PROFILE_OBJ_TYPE.PREFERRED_LANGUAGES) {
    val = {
      [RALLIO_PROFILE_TAB_FILEDS.PREFERRED_LANGUAGE]: values.preferred_language,
      [RALLIO_PROFILE_TAB_FILEDS.ENABLE_AUTOMATIC_POST_TRANSLATIONS]: values.enable_automatic_post_translations
    };
  } else if (objType === RALLIO_PROFILE_OBJ_TYPE.CUSTOM_FIELDS) {
    val = { custom_fields: values.custom_fields };
  }
  return val;
};

export const getTimeZonesForDropdown = (timeZones: settingsRallioProfileReducerTypes.ITimeZoneResponse[]): directoryListingsContainerTypes.ICountriesCodeObj[] => {
  return timeZones.reduce((acc: directoryListingsContainerTypes.ICountriesCodeObj[], curr) => {
    const timeZonesObj = {
      id: curr.value,
      value: curr.value,
      label: curr.label
    };
    acc = [...acc, timeZonesObj];
    return acc;
  }, []);
};

export const getInitilalRallioProfileDetails = (accounts: accountApiTypes.IAccount) => {
  const initialProfileValue = {
    name: accounts.name,
    street: accounts.street,
    short_name: accounts.short_name, // store_code
    city: accounts.city,
    phone: accounts.phone,
    business_name: accounts.business_name,
    phone_number: accounts.phone_number,
    mailing_address: accounts.mailing_address,
    business_email: accounts.business_email,
    zip: accounts.zip,
    url: accounts.url,
    state: accounts.state,
    custom_fields: accounts.custom_fields,
    country_code: accounts.country_code,
    time_zone: accounts.time_zone,
    job_page_url: accounts.job_page_url,
    person_to_reach: accounts.person_to_reach || '',
    required_field: accounts.directory_listings_feature_enabled ? APPROVED_STATUS.YES : APPROVED_STATUS.NO,
    preferred_language: accounts.preferred_language || null,
    enable_automatic_post_translations: accounts.enable_automatic_post_translations || false
  };
  return initialProfileValue;
};
