import { useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry from 'react-masonry-css';
import { Tag } from 'react-tag-autocomplete';

import { apAccountContainerTypes, IStore, mgApiTypes } from 'types';
import { Loading, NoDataFound } from 'components';
import { BREAK_POINT_ADD_IMAGE, NoDataFoundProps, SCROLL_THRESHOLD, MG_MEDIA_TYPE, ADD_POST_IMAGES_ACTIVE_TAB, MOMENT_DATE_FORMAT, MgMediaType, USER_OWNERSHIP } from 'utils/constants';
import { ImageValidation, Video } from 'widgets/Media';
import { dateFormatByRequestedFormat, debounceMethod, formNewInternalTag } from 'utils/helpers';
import { CustomReactTags } from 'widgets/CustomReactTags';
import { postsTagsRequest } from 'actions';

export const AddMediaList = ({
  searchValue,
  setSearchValue,
  mediaListRequest,
  mediaTabName,
  selectedTags,
  setSelectedTags,
  selectedMediaArr,
  setSelectedMediaArr,
  tagSearchValue,
  setTagSearchValue,
  userOwnership
}: apAccountContainerTypes.IAddPostMediaListCompProps) => {
  const dispatch = useDispatch();

  const mediaListApiTimeout = useRef<null | NodeJS.Timeout>(null);
  const tagListApiTimeout = useRef<null | NodeJS.Timeout>(null);

  const isListFetching = useSelector((state: IStore) => state.mediaGallery.isListFetching);
  const mediaList = useSelector((state: IStore) => state.mediaGallery.assetsList);
  const mgTagsList = useSelector((state: IStore) => state.mediaGallery.mgTagsList);
  const currentPageCount = useSelector((state: IStore) => state.mediaGallery.currentPageCount);
  const internalTagsList = useSelector((state: IStore) => state.posts.postsTagsList);

  const isBrandMedia = ADD_POST_IMAGES_ACTIVE_TAB.BRAND_IMAGES === mediaTabName || (mediaTabName === ADD_POST_IMAGES_ACTIVE_TAB.MY_IMAGES && userOwnership === USER_OWNERSHIP.FRANCHISOR);

  const tagsList = useMemo(() => {
    return formNewInternalTag(internalTagsList, tagSearchValue);
  }, [internalTagsList, tagSearchValue]);

  const handleLoadMoreItems = () => {
    mediaListRequest(currentPageCount + 1);
  };

  const handleSelectMedia = (mediaItem: mgApiTypes.IMGListResponseObj) => {
    if (mediaItem.media_type === MG_MEDIA_TYPE.PHOTO) {
      if (selectedMediaArr.filter((datum) => datum.id === mediaItem.id).length) setSelectedMediaArr(selectedMediaArr.filter((datum) => datum.id !== mediaItem.id));
      else setSelectedMediaArr([...selectedMediaArr, mediaItem]);
    } else {
      if (selectedMediaArr.filter((datum) => datum.id === mediaItem.id).length) setSelectedMediaArr([]);
      else setSelectedMediaArr([mediaItem]);
    }
  };

  const handleAddOrDeleteTag = (selectedTagObj: apAccountContainerTypes.IAddPostSelectedTag) => {
    if (!selectedTags.map((datum) => datum.id).includes(selectedTagObj.id)) {
      setSelectedTags([...selectedTags, selectedTagObj]);
    } else {
      setSelectedTags(selectedTags.filter((datum) => datum.id !== selectedTagObj.id));
    }
  };

  const renderMediaList = () => {
    return (
      <div id="scrollTarget-cp" className={`masonry-list-view-group`}>
        <Masonry breakpointCols={BREAK_POINT_ADD_IMAGE} className="masonry-grid" columnClassName="masonry-grid_column">
          {mediaList.map((assetsItem, index: number) => {
            return (
              <div
                key={`ap-assets-item-${index}`}
                className={`m-item${selectedMediaArr.filter((datum) => datum.id === assetsItem.id).length ? ` active` : ``}${assetsItem.media_type === MG_MEDIA_TYPE.VIDEO ? ` video-element` : ``}`}
                onClick={() => handleSelectMedia(assetsItem)}
              >
                <div className="m-ast-dtls">
                  <div className="mastg-main">
                    <div className="mast-prime-dtls">
                      <div className="mast-prof-txt">
                        <div className="">
                          <div className="sm-txt-top" title={assetsItem.name}>
                            {assetsItem.name}
                          </div>
                        </div>
                        <div className="creator-image-grid-section">
                          <div className="left-section">
                            <span className="mlg-count">{`${dateFormatByRequestedFormat(assetsItem.created_at, MOMENT_DATE_FORMAT)}`}</span>
                          </div>

                          <div className="right-section">
                            {mediaTabName !== MgMediaType.DOCUMENTS ? (
                              <span className="lg-txt-base">
                                <span className="mlg-label">Used</span>
                                <span className="mlg-count"> {assetsItem.used_count || 0}</span>
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="m-ast">
                  {assetsItem.media_type === MG_MEDIA_TYPE.PHOTO ? (
                    <ImageValidation imgUrl={assetsItem.medium_size_url} customName={'Add media asset'} customClassname="m-ast-itm m-ast-img" />
                  ) : (
                    <Video videoUrl={assetsItem.medium_size_url || ''} light={assetsItem?.thumbnail_url} customClassname="m-ast-itm m-ast-video" />
                  )}
                </div>
              </div>
            );
          })}
        </Masonry>
      </div>
    );
  };

  return (
    <div className="at-cnt ap-bi-main creator-post-media-wrp content-creator-media-section-wrp creator-image-modal__main">
      <div className={`search-tk${![ADD_POST_IMAGES_ACTIVE_TAB.BRAND_IMAGES].includes(mediaTabName) ? ` mymedia-fullsearch` : ``}`}>
        <div className="react-tags">
          <div className="react-tags__search">
            <div className="react-tags__search-input">
              <input
                type="text"
                placeholder="Search Keywords"
                autoComplete="off"
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
                onKeyUp={() => {
                  mediaListApiTimeout.current = debounceMethod(searchValue.trim(), mediaListApiTimeout.current, () => mediaListRequest(1));
                }}
              />
            </div>
          </div>
        </div>
        {isBrandMedia && (
          <CustomReactTags
            key={`creator-tag-${mediaTabName}`}
            handleInputChange={(searchText: string) => {
              tagListApiTimeout.current = debounceMethod(searchText.trim(), tagListApiTimeout.current, () => {
                setTagSearchValue(searchText);
                dispatch(postsTagsRequest({ searchTag: searchText || '' }));
              });
            }}
            suggestions={tagsList}
            tags={selectedTags}
            maxSuggestionsLength={tagsList.length}
            handleAddition={(tag: Tag) => handleAddOrDeleteTag(tag)}
            handleDelete={(index) => handleAddOrDeleteTag(selectedTags[index])}
            placeholder={'Search Tags'}
            autofocus={false}
          />
        )}
      </div>
      {isBrandMedia && mgTagsList.length ? (
        <div className={`fts-tag`}>
          <h3>Favorite Tags:</h3>
          <div className="fts">
            {mgTagsList.map((tagDatum, index) => {
              return tagDatum.favourite ? (
                <span
                  key={`creator-media-tag-${index}`}
                  onClick={() => handleAddOrDeleteTag(tagDatum)}
                  className={`fav-tags${selectedTags.map((datum) => datum.name).includes(tagDatum.name) ? ` active` : ``}`}
                >
                  {tagDatum.name}
                </span>
              ) : null;
            })}
          </div>
        </div>
      ) : null}
      {isListFetching && currentPageCount === 1 ? (
        <Loading />
      ) : mediaList.length ? (
        <InfiniteScroll
          scrollThreshold={SCROLL_THRESHOLD}
          next={handleLoadMoreItems}
          hasMore
          dataLength={mediaList.length}
          loader={isListFetching ? <Loading /> : null}
          className="local-ini myAssets"
          scrollableTarget="scrollTarget-cp"
          height={630}
        >
          {renderMediaList()}
        </InfiniteScroll>
      ) : !isListFetching && !mediaList.length ? (
        <NoDataFound size={NoDataFoundProps.FULL} />
      ) : (
        <Loading />
      )}
    </div>
  );
};
