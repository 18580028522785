import { ACCORD_EVENT_NAMES, PERMISSION_TYPES, PERMISSION_FIELD_TYPE, PERMISSION_VIEW } from 'utils/constants';
import { compareTwoObjects } from 'utils/helpers';
import { permissionsContainerTypes, franchisorReducerTypes } from 'types';

/** Get Permission status Name based on accordian id */
export const getCurrentAccordName = (currentAccord: string) => {
  const accordName = Object.keys(ACCORD_EVENT_NAMES).filter((name: string) => ACCORD_EVENT_NAMES[name] === currentAccord);
  return accordName[0];
};

/** Get matched post from Franchisor permission list based on given locatoin id */
export const getmatchPostBasedOnId = (
  paramType: keyof permissionsContainerTypes.IPermissionStatus,
  locId: number,
  franchisorsPermissionList: franchisorReducerTypes.IFranchisorsPermissionList | null
) => {
  if (paramType === PERMISSION_TYPES.COPIED_CONTENT) {
    const matchLoc = franchisorsPermissionList?.child_franchisor_permissions.filter((item) => item.id === locId);
    return matchLoc;
  } else {
    const matchLoc = franchisorsPermissionList?.franchisee_permissions.filter((item) => item.id === locId);
    return matchLoc;
  }
};

/** Get Updated child_franchisor_permissions based on toggle changes */
export const getUpdatedContentChildPermissions = (
  paramName: keyof franchisorReducerTypes.IFranchisorsPermissionList | keyof franchisorReducerTypes.IChildFranchisorPermissionItem,
  val: boolean,
  locId: number,
  franchisor: franchisorReducerTypes.IFranchisorsPermissionList
) => {
  const temp: franchisorReducerTypes.IFranchisorsPermissionList = { ...franchisor };
  const matchLoc = temp.child_franchisor_permissions.filter((item) => item.id === locId);
  let updatedChildPermissions = [...franchisor?.child_franchisor_permissions];
  updatedChildPermissions = franchisor?.child_franchisor_permissions.reduce(
    (acc: franchisorReducerTypes.IChildFranchisorPermissionItem[], curr: franchisorReducerTypes.IChildFranchisorPermissionItem) => {
      acc = [...acc, curr.id === matchLoc[0].id ? { ...matchLoc[0], [paramName]: val } : { ...curr }];
      return acc;
    },
    []
  );
  return updatedChildPermissions;
};

/** Get Updated child_franchisor_permissions based on toggle changes */
export const getUpdatedChildPermissions = (
  paramName: keyof franchisorReducerTypes.IFranchisorsPermissionList | keyof franchisorReducerTypes.IChildFranchisorPermissionItem,
  val: boolean,
  locId: number,
  franchisor: franchisorReducerTypes.IFranchisorsPermissionList
) => {
  const temp: franchisorReducerTypes.IFranchisorsPermissionList = { ...franchisor };
  const matchLoc = temp.franchisee_permissions.filter((item) => item.id === locId);
  let updatedChildPermissions = [...franchisor?.franchisee_permissions];
  updatedChildPermissions = franchisor?.franchisee_permissions.reduce((acc: franchisorReducerTypes.IFranchiseePermissionsItem[], curr: franchisorReducerTypes.IFranchiseePermissionsItem) => {
    const temp: any = {};
    if (paramName === PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_APPROVE) {
      temp[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_APPROVE] = true;
      temp[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_POSTED] = true;
    } else if (paramName === PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_POSTED) {
      temp[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_APPROVE] = false;
      temp[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_POSTED] = false;
    } else if (paramName === PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_OPTIONAL) {
      temp[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_APPROVE] = false;
      temp[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_POSTED] = true;
    }
    acc = [
      ...acc,
      curr.id === matchLoc[0].id
        ? {
            ...matchLoc[0],
            ...([PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_APPROVE, PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_POSTED, PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_OPTIONAL].includes(paramName)
              ? null
              : { [paramName]: val }),
            ...temp
          }
        : { ...curr }
    ];
    return acc;
  }, []);
  return updatedChildPermissions;
};

/** Get default values based on permission view changes(all location/custom) */
export const getViewChangeDefaultValues = (setName: keyof permissionsContainerTypes.IPermissionStatus, setVal: string, franchisor: franchisorReducerTypes.IFranchisorsPermissionList | null) => {
  const temp: any = { ...franchisor };
  if (setVal === PERMISSION_VIEW.CUSTOM) {
    if (setName === PERMISSION_TYPES.SCH_POST) {
      temp[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_APPROVE] = null;
      temp[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_POSTED] = null;
    } else if (setName === PERMISSION_TYPES.CORP_CONTENT) temp[PERMISSION_FIELD_TYPE.CORPORATE_POSTS_LOCKED] = null;
    else if (setName === PERMISSION_TYPES.LOC_CONTENT) temp[PERMISSION_FIELD_TYPE.CUSTOM_POSTS_LOCKED] = null;
    else if (setName === PERMISSION_TYPES.REVIEW) temp[PERMISSION_FIELD_TYPE.REVIEW_RESPONSE_LOCK] = null;
    else if (setName === PERMISSION_TYPES.INBOX) temp[PERMISSION_FIELD_TYPE.INBOX_RESPONSES_LOCKED] = null;
    else if (setName === PERMISSION_TYPES.COPIED_CONTENT) temp[PERMISSION_FIELD_TYPE.COPIED_CHILD_CONTENT_AUTO_APPROVE] = null;
    else if (setName === PERMISSION_TYPES.PROFILE_EDIT) temp[PERMISSION_FIELD_TYPE.PROFILE_EDIT_LOCKED] = null;
    else if (setName === PERMISSION_TYPES.CONTENT_SUPPLIER) temp[PERMISSION_FIELD_TYPE.COPIED_PARENT_CONTENT_AUTO_APPROVE] = null;
    else if (setName === PERMISSION_TYPES.MANULA_REVIEW_RESPONDER) temp[PERMISSION_FIELD_TYPE.AI_MANUAL_REVIEW_RESPONDER] = null;
    else if (setName === PERMISSION_TYPES.AUTO_REVIEW_RESPONDER) temp[PERMISSION_FIELD_TYPE.AI_AUTO_REVIEW_RESPONDER] = null;
  } else if (setVal === PERMISSION_VIEW.DEFAULT) {
    if (setName === PERMISSION_TYPES.SCH_POST) {
      temp[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_APPROVE] = false;
      temp[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_POSTED] = false;
    } else if (setName === PERMISSION_TYPES.CORP_CONTENT) temp[PERMISSION_FIELD_TYPE.CORPORATE_POSTS_LOCKED] = true;
    else if (setName === PERMISSION_TYPES.LOC_CONTENT) temp[PERMISSION_FIELD_TYPE.CUSTOM_POSTS_LOCKED] = false;
    else if (setName === PERMISSION_TYPES.REVIEW) temp[PERMISSION_FIELD_TYPE.REVIEW_RESPONSE_LOCK] = false;
    else if (setName === PERMISSION_TYPES.INBOX) temp[PERMISSION_FIELD_TYPE.INBOX_RESPONSES_LOCKED] = true;
    else if (setName === PERMISSION_TYPES.COPIED_CONTENT) temp[PERMISSION_FIELD_TYPE.COPIED_CHILD_CONTENT_AUTO_APPROVE] = false;
    else if (setName === PERMISSION_TYPES.PROFILE_EDIT) temp[PERMISSION_FIELD_TYPE.PROFILE_EDIT_LOCKED] = false;
    else if (setName === PERMISSION_TYPES.CONTENT_SUPPLIER) temp[PERMISSION_FIELD_TYPE.COPIED_PARENT_CONTENT_AUTO_APPROVE] = false;
    else if (setName === PERMISSION_TYPES.MANULA_REVIEW_RESPONDER) temp[PERMISSION_FIELD_TYPE.AI_MANUAL_REVIEW_RESPONDER] = false;
    else if (setName === PERMISSION_TYPES.AUTO_REVIEW_RESPONDER) temp[PERMISSION_FIELD_TYPE.AI_AUTO_REVIEW_RESPONDER] = false;
  }
  return temp;
};

/** Get respective PERMISSION_FIELD_TYPE based on  PERMISSION_TYPES */
export const getRespectiveParamName = (paramType: keyof permissionsContainerTypes.IPermissionStatus) => {
  if (paramType === PERMISSION_TYPES.SCH_POST) return PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_APPROVE;
  if (paramType === PERMISSION_TYPES.CORP_CONTENT) return PERMISSION_FIELD_TYPE.CORPORATE_POSTS_LOCKED;
  if (paramType === PERMISSION_TYPES.LOC_CONTENT) return PERMISSION_FIELD_TYPE.CUSTOM_POSTS_LOCKED;
  if (paramType === PERMISSION_TYPES.REVIEW) return PERMISSION_FIELD_TYPE.REVIEW_RESPONSE_LOCK;
  if (paramType === PERMISSION_TYPES.INBOX) return PERMISSION_FIELD_TYPE.INBOX_RESPONSES_LOCKED;
  if (paramType === PERMISSION_TYPES.COPIED_CONTENT) return PERMISSION_FIELD_TYPE.COPIED_CHILD_CONTENT_AUTO_APPROVE;
  if (paramType === PERMISSION_TYPES.PROFILE_EDIT) return PERMISSION_FIELD_TYPE.PROFILE_EDIT_LOCKED;
  if (paramType === PERMISSION_TYPES.CONTENT_SUPPLIER) return PERMISSION_FIELD_TYPE.COPIED_PARENT_CONTENT_AUTO_APPROVE;
};

/** Get permission status value for show all location/custom initially */
export const getDefaultViewInitialValue = (
  permissionSet: permissionsContainerTypes.IPermissionStatus,
  franchisor: franchisorReducerTypes.IFranchisorsPermissionList
): permissionsContainerTypes.IPermissionStatus => {
  const tempPermissionStatus = { ...permissionSet };
  if (franchisor[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_APPROVE] === null && franchisor[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_POSTED] === null) {
    tempPermissionStatus[PERMISSION_TYPES.SCH_POST] = { ...tempPermissionStatus[PERMISSION_TYPES.SCH_POST], currentView: PERMISSION_VIEW.CUSTOM };
  }
  if (franchisor[PERMISSION_FIELD_TYPE.CORPORATE_POSTS_LOCKED] === null) {
    tempPermissionStatus[PERMISSION_TYPES.CORP_CONTENT] = { ...tempPermissionStatus[PERMISSION_TYPES.CORP_CONTENT], currentView: PERMISSION_VIEW.CUSTOM };
  }
  if (franchisor[PERMISSION_FIELD_TYPE.CUSTOM_POSTS_LOCKED] === null) {
    tempPermissionStatus[PERMISSION_TYPES.LOC_CONTENT] = { ...tempPermissionStatus[PERMISSION_TYPES.LOC_CONTENT], currentView: PERMISSION_VIEW.CUSTOM };
  }
  if (franchisor[PERMISSION_FIELD_TYPE.INBOX_RESPONSES_LOCKED] === null) {
    tempPermissionStatus[PERMISSION_TYPES.INBOX] = { ...tempPermissionStatus[PERMISSION_TYPES.INBOX], currentView: PERMISSION_VIEW.CUSTOM };
  }
  if (franchisor[PERMISSION_FIELD_TYPE.REVIEW_RESPONSE_LOCK] === null) {
    tempPermissionStatus[PERMISSION_TYPES.REVIEW] = { ...tempPermissionStatus[PERMISSION_TYPES.REVIEW], currentView: PERMISSION_VIEW.CUSTOM };
  }
  if (franchisor[PERMISSION_FIELD_TYPE.COPIED_CHILD_CONTENT_AUTO_APPROVE] === null) {
    tempPermissionStatus[PERMISSION_TYPES.COPIED_CONTENT] = { ...tempPermissionStatus[PERMISSION_TYPES.COPIED_CONTENT], currentView: PERMISSION_VIEW.CUSTOM };
  }
  if (franchisor[PERMISSION_FIELD_TYPE.PROFILE_EDIT_LOCKED] === null) {
    tempPermissionStatus[PERMISSION_TYPES.PROFILE_EDIT] = { ...tempPermissionStatus[PERMISSION_TYPES.PROFILE_EDIT], currentView: PERMISSION_VIEW.CUSTOM };
  }
  if (franchisor[PERMISSION_FIELD_TYPE.COPIED_PARENT_CONTENT_AUTO_APPROVE] === null) {
    tempPermissionStatus[PERMISSION_TYPES.CONTENT_SUPPLIER] = { ...tempPermissionStatus[PERMISSION_TYPES.CONTENT_SUPPLIER], currentView: PERMISSION_VIEW.DEFAULT };
  }
  if (franchisor[PERMISSION_FIELD_TYPE.AI_MANUAL_REVIEW_RESPONDER] === null) {
    tempPermissionStatus[PERMISSION_TYPES.MANULA_REVIEW_RESPONDER] = { ...tempPermissionStatus[PERMISSION_TYPES.MANULA_REVIEW_RESPONDER], currentView: PERMISSION_VIEW.CUSTOM };
  }
  if (franchisor[PERMISSION_FIELD_TYPE.AI_AUTO_REVIEW_RESPONDER] === null) {
    tempPermissionStatus[PERMISSION_TYPES.AUTO_REVIEW_RESPONDER] = { ...tempPermissionStatus[PERMISSION_TYPES.AUTO_REVIEW_RESPONDER], currentView: PERMISSION_VIEW.CUSTOM };
  }
  return tempPermissionStatus;
};

/** Get valid permission keys based on current accordion */
const getPermissionKeysBasedOnAccordion = (currentAccord: string): string[] => {
  const currentTab = getCurrentAccordName(currentAccord);
  if (currentTab === PERMISSION_TYPES.SCH_POST) return [PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_APPROVE, PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_POSTED];
  else if (currentTab === PERMISSION_TYPES.CORP_CONTENT) return [PERMISSION_FIELD_TYPE.CORPORATE_POSTS_LOCKED];
  else if (currentTab === PERMISSION_TYPES.LOC_CONTENT) return [PERMISSION_FIELD_TYPE.CUSTOM_POSTS_LOCKED];
  else if (currentTab === PERMISSION_TYPES.INBOX) return [PERMISSION_FIELD_TYPE.INBOX_RESPONSES_LOCKED];
  else if (currentTab === PERMISSION_TYPES.REVIEW) return [PERMISSION_FIELD_TYPE.REVIEW_RESPONSE_LOCK];
  else if (currentTab === PERMISSION_TYPES.COPIED_CONTENT) return [PERMISSION_FIELD_TYPE.COPIED_CHILD_CONTENT_AUTO_APPROVE];
  else if (currentTab === PERMISSION_TYPES.PROFILE_EDIT) return [PERMISSION_FIELD_TYPE.PROFILE_EDIT_LOCKED];
  else if (currentTab === PERMISSION_TYPES.CONTENT_SUPPLIER) return [PERMISSION_FIELD_TYPE.COPIED_PARENT_CONTENT_AUTO_APPROVE];
  else if (currentTab === PERMISSION_TYPES.MANULA_REVIEW_RESPONDER) return [PERMISSION_FIELD_TYPE.AI_MANUAL_REVIEW_RESPONDER];
  else if (currentTab === PERMISSION_TYPES.AUTO_REVIEW_RESPONDER) return [PERMISSION_FIELD_TYPE.AI_AUTO_REVIEW_RESPONDER];
  return [];
};

/** Get updated permission data based on key */
export const getPermissionDataBasedOnKey = (data: Partial<franchisorReducerTypes.IFranchisorsPermissionList>, currentAccord: string) => {
  const validKeys = getPermissionKeysBasedOnAccordion(currentAccord);
  return Object.entries(data).reduce((acc: Partial<franchisorReducerTypes.IFranchisorsPermissionList>, curr) => {
    if (validKeys.includes(curr[0])) acc = { ...acc, [curr[0]]: curr[1] };
    return acc;
  }, {});
};

/** Get permission save status - default view */
export const getDefaultPermissionSaveStatus = (
  franchisorsPermissionList: null | franchisorReducerTypes.IFranchisorsPermissionList,
  updatedData: null | Partial<franchisorReducerTypes.IFranchisorsPermissionList>,
  currentAccord: string
) => {
  let saveStatus = false;
  if (franchisorsPermissionList && updatedData) {
    const originalData = getPermissionDataBasedOnKey(franchisorsPermissionList, currentAccord);
    const modifiedData = getPermissionDataBasedOnKey(updatedData, currentAccord);
    saveStatus = !compareTwoObjects(originalData, modifiedData);
  }
  return saveStatus;
};

/** Get updated location data - custom view */
export const getCustomPermissionLocUpdates = (
  paramType: keyof permissionsContainerTypes.IPermissionStatus,
  paramName: keyof franchisorReducerTypes.IFranchisorsPermissionList | keyof franchisorReducerTypes.IChildFranchisorPermissionItem,
  val: boolean,
  locId: number,
  franchisorData: franchisorReducerTypes.IFranchisorsPermissionList
) => {
  return {
    ...(paramType === PERMISSION_TYPES.COPIED_CONTENT
      ? { child_franchisor_permissions: getUpdatedContentChildPermissions(paramName, val, locId, franchisorData) }
      : { franchisee_permissions: getUpdatedChildPermissions(paramName, val, locId, franchisorData) })
  };
};

/** Get permission save status - custom view */
export const getCustomPermissionSaveStatus = (
  franchisorsPermissionList: null | franchisorReducerTypes.IFranchisorsPermissionList,
  locationData: Partial<franchisorReducerTypes.IFranchisorsPermissionList>,
  updatedCustomData: franchisorReducerTypes.IFranchisorsPermissionList,
  currentAccord: string
) => {
  let saveEnable = false;
  if (franchisorsPermissionList && locationData.franchisee_permissions?.length) {
    const customLocationEdited = !compareTwoObjects(franchisorsPermissionList?.franchisee_permissions, locationData.franchisee_permissions);
    saveEnable = customLocationEdited ? customLocationEdited : getDefaultPermissionSaveStatus(franchisorsPermissionList, updatedCustomData, currentAccord);
  }
  if (franchisorsPermissionList && locationData.child_franchisor_permissions?.length) {
    const customChildLocationEdited = !compareTwoObjects(franchisorsPermissionList?.child_franchisor_permissions, locationData.child_franchisor_permissions);
    saveEnable = customChildLocationEdited ? customChildLocationEdited : getDefaultPermissionSaveStatus(franchisorsPermissionList, updatedCustomData, currentAccord);
  }
  return saveEnable;
};

/** Get permission field modified status */
export const getPermissionModifiedStatus = (permissionStatus: permissionsContainerTypes.IPermissionStatus) => {
  return Object.values(permissionStatus).reduce((acc, curr) => {
    if (curr.saveEnable) acc = curr.saveEnable;
    return acc;
  }, false);
};

/** Get initial permission data based on individual cancel options - default view */
export const getInitialPermissionOnCancel = (
  paramType: keyof permissionsContainerTypes.IPermissionStatus,
  franchisor: franchisorReducerTypes.IFranchisorsPermissionList,
  franchisorsPermissionList: franchisorReducerTypes.IFranchisorsPermissionList
) => {
  const updatedFranchisor: franchisorReducerTypes.IFranchisorsPermissionList | any = { ...franchisor };
  if (paramType === PERMISSION_TYPES.SCH_POST) {
    updatedFranchisor[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_APPROVE] = franchisorsPermissionList[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_APPROVE];
    updatedFranchisor[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_POSTED] = franchisorsPermissionList[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_POSTED];
  } else if (paramType === PERMISSION_TYPES.CORP_CONTENT) {
    updatedFranchisor[PERMISSION_FIELD_TYPE.CORPORATE_POSTS_LOCKED] = franchisorsPermissionList[PERMISSION_FIELD_TYPE.CORPORATE_POSTS_LOCKED];
  } else if (paramType === PERMISSION_TYPES.LOC_CONTENT) {
    updatedFranchisor[PERMISSION_FIELD_TYPE.CUSTOM_POSTS_LOCKED] = franchisorsPermissionList[PERMISSION_FIELD_TYPE.CUSTOM_POSTS_LOCKED];
  } else if (paramType === PERMISSION_TYPES.INBOX) {
    updatedFranchisor[PERMISSION_FIELD_TYPE.INBOX_RESPONSES_LOCKED] = franchisorsPermissionList[PERMISSION_FIELD_TYPE.INBOX_RESPONSES_LOCKED];
  } else if (paramType === PERMISSION_TYPES.REVIEW) {
    updatedFranchisor[PERMISSION_FIELD_TYPE.REVIEW_RESPONSE_LOCK] = franchisorsPermissionList[PERMISSION_FIELD_TYPE.REVIEW_RESPONSE_LOCK];
  } else if (paramType === PERMISSION_TYPES.COPIED_CONTENT) {
    updatedFranchisor[PERMISSION_FIELD_TYPE.COPIED_CHILD_CONTENT_AUTO_APPROVE] = franchisorsPermissionList[PERMISSION_FIELD_TYPE.COPIED_CHILD_CONTENT_AUTO_APPROVE];
  } else if (paramType === PERMISSION_TYPES.PROFILE_EDIT) {
    updatedFranchisor[PERMISSION_FIELD_TYPE.PROFILE_EDIT_LOCKED] = franchisorsPermissionList[PERMISSION_FIELD_TYPE.PROFILE_EDIT_LOCKED];
  } else if (paramType === PERMISSION_TYPES.CONTENT_SUPPLIER) {
    updatedFranchisor[PERMISSION_FIELD_TYPE.COPIED_PARENT_CONTENT_AUTO_APPROVE] = franchisorsPermissionList[PERMISSION_FIELD_TYPE.COPIED_PARENT_CONTENT_AUTO_APPROVE];
  } else if (paramType === PERMISSION_TYPES.MANULA_REVIEW_RESPONDER) {
    updatedFranchisor[PERMISSION_FIELD_TYPE.AI_MANUAL_REVIEW_RESPONDER] = franchisorsPermissionList[PERMISSION_FIELD_TYPE.AI_MANUAL_REVIEW_RESPONDER];
  } else if (paramType === PERMISSION_TYPES.AUTO_REVIEW_RESPONDER) {
    updatedFranchisor[PERMISSION_FIELD_TYPE.AI_AUTO_REVIEW_RESPONDER] = franchisorsPermissionList[PERMISSION_FIELD_TYPE.AI_AUTO_REVIEW_RESPONDER];
  }
  return updatedFranchisor;
};

/** Get updated franchisor permissions based on individual cancel options - custom view */
export const getInitialChildPermissionOnCancel = (
  paramType: keyof permissionsContainerTypes.IPermissionStatus,
  franchisor: franchisorReducerTypes.IFranchisorsPermissionList,
  franchisorsPermissionList: franchisorReducerTypes.IFranchisorsPermissionList
) => {
  let updatedChildPermissions = [...franchisor?.franchisee_permissions];
  updatedChildPermissions = franchisor?.franchisee_permissions.reduce((acc: franchisorReducerTypes.IFranchiseePermissionsItem[], curr: franchisorReducerTypes.IFranchiseePermissionsItem) => {
    const matchLoc = franchisorsPermissionList.franchisee_permissions.filter((item) => item.id === curr.id);
    const temp: any = {};
    if (paramType === PERMISSION_TYPES.SCH_POST) {
      temp[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_POSTED] = matchLoc[0].corporate_posts_auto_posted;
      temp[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_APPROVE] = matchLoc[0].corporate_posts_auto_approved;
    } else if (paramType === PERMISSION_TYPES.CORP_CONTENT) {
      temp[PERMISSION_FIELD_TYPE.CORPORATE_POSTS_LOCKED] = matchLoc[0].corporate_posts_locked;
    } else if (paramType === PERMISSION_TYPES.LOC_CONTENT) {
      temp[PERMISSION_FIELD_TYPE.CUSTOM_POSTS_LOCKED] = matchLoc[0].custom_posts_locked;
    } else if (paramType === PERMISSION_TYPES.INBOX) {
      temp[PERMISSION_FIELD_TYPE.INBOX_RESPONSES_LOCKED] = matchLoc[0].inbox_responses_locked;
    } else if (paramType === PERMISSION_TYPES.REVIEW) {
      temp[PERMISSION_FIELD_TYPE.REVIEW_RESPONSE_LOCK] = matchLoc[0].review_responses_locked;
    } else if (paramType === PERMISSION_TYPES.PROFILE_EDIT) {
      temp[PERMISSION_FIELD_TYPE.PROFILE_EDIT_LOCKED] = matchLoc[0].profile_edit_locked;
    } else if (paramType === PERMISSION_TYPES.MANULA_REVIEW_RESPONDER) {
      temp[PERMISSION_FIELD_TYPE.AI_MANUAL_REVIEW_RESPONDER] = matchLoc[0].ai_review_response_setup_permission;
    } else if (paramType === PERMISSION_TYPES.AUTO_REVIEW_RESPONDER) {
      temp[PERMISSION_FIELD_TYPE.AI_AUTO_REVIEW_RESPONDER] = matchLoc[0].ai_auto_review_responder;
    }
    acc = [...acc, { ...curr, ...temp }];
    return acc;
  }, []);
  return updatedChildPermissions;
};

/** Get updated content child permissions based on individual cancel options - custom view */
export const getInitialContentChildPermissionOnCancel = (
  paramType: keyof permissionsContainerTypes.IPermissionStatus,
  franchisor: franchisorReducerTypes.IFranchisorsPermissionList,
  franchisorsPermissionList: franchisorReducerTypes.IFranchisorsPermissionList
) => {
  let updatedChildPermissions = [...franchisor?.child_franchisor_permissions];
  updatedChildPermissions = franchisor?.child_franchisor_permissions.reduce(
    (acc: franchisorReducerTypes.IChildFranchisorPermissionItem[], curr: franchisorReducerTypes.IChildFranchisorPermissionItem) => {
      const temp: any = {};
      const matchLoc = franchisorsPermissionList.child_franchisor_permissions.filter((item) => item.id === curr.id);
      if (paramType === PERMISSION_TYPES.COPIED_CONTENT) {
        temp[PERMISSION_FIELD_TYPE.COPIED_PARENT_CONTENT_AUTO_APPROVE] = matchLoc[0].copied_parent_contents_auto_approved;
      }
      acc = [...acc, { ...curr, ...temp }];
      return acc;
    },
    []
  );
  return updatedChildPermissions;
};

export const getDefaultStatusBasedOnTab = (currentTab: keyof permissionsContainerTypes.IPermissionStatus, condition: boolean, tempPermissionStatus: permissionsContainerTypes.IPermissionStatus) => {
  if (condition) {
    tempPermissionStatus[currentTab] = { saveEnable: false, currentView: PERMISSION_VIEW.CUSTOM };
  } else {
    tempPermissionStatus[currentTab] = { saveEnable: false, currentView: PERMISSION_VIEW.DEFAULT };
  }
};

/** Get permission status value based on individual cancel options */
export const getDefaultPermissionStatusOnCancel = (
  currentTab: keyof permissionsContainerTypes.IPermissionStatus,
  permissionStatus: permissionsContainerTypes.IPermissionStatus,
  franchisorPermissionList: franchisorReducerTypes.IFranchisorsPermissionList
): permissionsContainerTypes.IPermissionStatus => {
  const tempPermissionStatus = { ...permissionStatus };
  if (currentTab === PERMISSION_TYPES.SCH_POST) {
    getDefaultStatusBasedOnTab(
      currentTab,
      franchisorPermissionList[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_APPROVE] === null && franchisorPermissionList[PERMISSION_FIELD_TYPE.CORPORATE_POST_AUTO_POSTED] === null,
      tempPermissionStatus
    );
  }
  if (currentTab === PERMISSION_TYPES.CORP_CONTENT) {
    getDefaultStatusBasedOnTab(currentTab, franchisorPermissionList[PERMISSION_FIELD_TYPE.CORPORATE_POSTS_LOCKED] === null, tempPermissionStatus);
  }
  if (currentTab === PERMISSION_TYPES.LOC_CONTENT) {
    getDefaultStatusBasedOnTab(currentTab, franchisorPermissionList[PERMISSION_FIELD_TYPE.CUSTOM_POSTS_LOCKED] === null, tempPermissionStatus);
  }
  if (currentTab === PERMISSION_TYPES.INBOX) {
    getDefaultStatusBasedOnTab(currentTab, franchisorPermissionList[PERMISSION_FIELD_TYPE.INBOX_RESPONSES_LOCKED] === null, tempPermissionStatus);
  }
  if (currentTab === PERMISSION_TYPES.REVIEW) {
    getDefaultStatusBasedOnTab(currentTab, franchisorPermissionList[PERMISSION_FIELD_TYPE.REVIEW_RESPONSE_LOCK] === null, tempPermissionStatus);
  }
  if (currentTab === PERMISSION_TYPES.COPIED_CONTENT) {
    getDefaultStatusBasedOnTab(currentTab, franchisorPermissionList[PERMISSION_FIELD_TYPE.COPIED_CHILD_CONTENT_AUTO_APPROVE] === null, tempPermissionStatus);
  }
  if (currentTab === PERMISSION_TYPES.PROFILE_EDIT) {
    getDefaultStatusBasedOnTab(currentTab, franchisorPermissionList[PERMISSION_FIELD_TYPE.PROFILE_EDIT_LOCKED] === null, tempPermissionStatus);
  }
  if (currentTab === PERMISSION_TYPES.CONTENT_SUPPLIER) {
    getDefaultStatusBasedOnTab(currentTab, franchisorPermissionList[PERMISSION_FIELD_TYPE.COPIED_PARENT_CONTENT_AUTO_APPROVE] === null, tempPermissionStatus);
  }
  if (currentTab === PERMISSION_TYPES.MANULA_REVIEW_RESPONDER) {
    getDefaultStatusBasedOnTab(currentTab, franchisorPermissionList[PERMISSION_FIELD_TYPE.AI_MANUAL_REVIEW_RESPONDER] === null, tempPermissionStatus);
  }
  if (currentTab === PERMISSION_TYPES.AUTO_REVIEW_RESPONDER) {
    getDefaultStatusBasedOnTab(currentTab, franchisorPermissionList[PERMISSION_FIELD_TYPE.AI_AUTO_REVIEW_RESPONDER] === null, tempPermissionStatus);
  }
  return tempPermissionStatus;
};
