// CLOUDINARY SIGN
export const CLOUD_SIGN_REQUEST = 'common/CLOUD_SIGN_REQUEST';
export const CLOUD_SIGN_FAILURE = 'common/CLOUD_SIGN_FAILURE';

export const LOAD_ENV_CONFIGURATION_REQUEST = 'common/LOAD_ENV_CONFIGURATION_REQUEST';
export const LOAD_ENV_CONFIGURATION_RESPONSE = 'common/LOAD_ENV_CONFIGURATION_RESPONSE';

// GET CURRENT USER DETAIL
export const GET_CURRENT_USER_DETAILS_REQUEST = 'common/GET_CURRENT_USER_DETAILS_REQUEST';
export const GET_CURRENT_USER_DETAILS_RESPONSE = 'common/GET_CURRENT_USER_DETAILS_RESPONSE';
export const GET_CURRENT_USER_DETAILS_FAILURE = 'common/GET_CURRENT_USER_DETAILS_FAILURE';

// GET CURRENT USER TAB LIST
export const GET_CURRENT_USER_TAB_LIST_REQUEST = 'common/GET_CURRENT_USER_TAB_LIST_REQUEST';
export const GET_CURRENT_USER_TAB_LIST_RESPONSE = 'common/GET_CURRENT_USER_TAB_LIST_RESPONSE';
export const GET_CURRENT_USER_TAB_LIST_FAILURE = 'common/GET_CURRENT_USER_TAB_LIST_FAILURE';

// UPDATE CURRENT USER DETAIL
export const UPDATE_CURRENT_USER_DETAILS_REQUEST = 'common/UPDATE_CURRENT_USER_DETAILS_REQUEST';
export const UPDATE_CURRENT_USER_DETAILS_RESPONSE = 'common/UPDATE_CURRENT_USER_DETAILS_RESPONSE';
export const UPDATE_CURRENT_USER_DETAILS_FAILURE = 'common/UPDATE_CURRENT_USER_DETAILS_FAILURE';

// UPLOAD CURRENT USER PROFILE IMG
export const UPLOAD_CURRENT_USER_CLOUD_PROFILE_IMG_REQUEST = 'common/UPLOAD_CURRENT_USER_CLOUD_PROFILE_IMG_REQUEST';
export const UPLOAD_CURRENT_USER_CLOUD_PROFILE_IMG_RESPONSE = 'common/UPLOAD_CURRENT_USER_CLOUD_PROFILE_IMG_RESPONSE';
export const UPLOAD_CURRENT_USER_CLOUD_PROFILE_IMG_FAILURE = 'common/UPLOAD_CURRENT_USER_CLOUD_PROFILE_IMG_FAILURE';

// COMMON STATE UPDATES
export const FILTER_STATUS = 'common/FILTER_STATUS';

export const TOGGLE_LEFT_NAV = 'common/TOGGLE_LEFT_NAV';
export const TOGGLE_FILTER = 'common/TOGGLE_FILTER';
export const SHOW_HIDE_FILTER_BUTTON = 'common/SHOW_HIDE_FILTER_BUTTON';

// DATA LOADING
export const IS_DATA_LOADING = 'common/IS_DATA_LOADING';

export const SET_SALES_PAGES = 'common/SET_SALES_PAGES';

// CREATOR - SET COMPONENT NAME
export const CREATOR_SET_COMPONENT_NAME = 'common/CREATOR_SET_COMPONENT_NAME';

export const COMMON_SET_CONTENT_MODIFIED_STATUS = 'common/COMMON_SET_CONTENT_MODIFIED_STATUS';

// SET CURRENT USER CLOUDINARY PROFILE IMG
export const SET_CURRENT_USER_CLOUD_PROFILE_IMG = 'common/SET_CURRENT_USER_CLOUD_PROFILE_IMG';

// SET EDIT CURRENT USER
export const SET_IS_EDIT_CURRENT_USER = 'common/SET_IS_EDIT_CURRENT_USER';

// SET CURRENT USER ACTIVE TAB
export const SET_CURRENT_USER_ACTIVE_TAB = 'common/SET_CURRENT_USER_ACTIVE_TAB';

// SET CURRENT USER SEARCH
export const SET_CURRENT_USER_SEARCH = 'common/SET_CURRENT_USER_SEARCH';

// SHOW PAGE NOT FOUND
export const IS_SHOW_PAGE_NOT_FOUND = 'common/IS_SHOW_PAGE_NOT_FOUND';

// CURRENT USER DETAILS STATUS RESET
export const CURRENT_USER_DETAIL_STATUS_RESET = 'common/CURRENT_USER_DETAIL_STATUS_RESET';

// CURRENT USER DETAILS RESET
export const CURRENT_USER_DETAILS_RESET = 'common/CURRENT_USER_DETAILS_RESET';

// DISABLE STATE HANDLE - IN CREATOR
export const SET_CREATOR_TEXT_ALERT_MSG = 'common/SET_CREATOR_TEXT_ALERT_MSG';

export const COMMON_SET_CONTENT_WARNING_OPTION = 'common/COMMON_SET_CONTENT_WARNING_OPTION';
