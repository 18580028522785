import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  aiCreateSimilarPostRequest,
  aiUpdateLimitationRequest,
  copyPostsRequest,
  getPostsRequest,
  setAISubscriptionDetailsModal,
  setCsLocationSubscriptionModalType,
  setMultiplePostsToCampaign
} from 'actions';
import { AICoachMark } from 'components/Common/Modal/AICoachMark';
import { IStore, postsApiTypes, postsContainerTypes } from 'types';
import { useAccountSwitcherData, useAllowAccess, useParamsDeconstructor, useSiteRestriction, useSSOIdentifiersForAccount } from 'utils/hooks';
import {
  AI_COACHMARK,
  AI_ICON_BG_IMG,
  ICSSubscriptionPlans,
  POSTS_DETAIL_TAB_NAMES,
  POSTS_INIT_FILTER_DATA,
  POSTS_PACKAGE_EXPIRED_MSG,
  POSTS_STATUS_TYPE,
  POSTS_TOOLTIP_NAMES,
  POST_DETAIL_TYPE,
  SETTINGS_CUSTOMER_PERSONA_USAGE_LEVELS,
  SIDE_NAV_NAME,
  TOOLTIP_PLACEMENT,
  USER_OWNERSHIP,
  VALID_BRAND_CATEGORY_REGEX
} from 'utils/constants';
import { atLeastApproved, canApprove, canBeScheduled, generateCustomerPersonaFieldData, handleLinkNavigation } from 'utils/helpers';
import { ImageValidation } from 'widgets/Media';
import { CustomTooltip } from 'widgets/Tooltip';

export const PostCardOverlay = ({
  listItem,
  type,
  brandCategory,
  handleGetContentPostData,
  handleGetSavedPostData,
  postId,
  isCoachMark,
  isAISubscribed,
  isCampaignPostSelected,
  hoverSelectedPostId,
  setEditOrScheduleObj,
  dispatch
}: postsContainerTypes.IPostCardOverlayProps) => {
  const navigate = useNavigate();

  const { id, userOwnership, currentRoute } = useAccountSwitcherData();
  const { isCSLocation } = useSSOIdentifiersForAccount();
  const { postsRestriction, aiRestriction } = useSiteRestriction();
  const { queryParams } = useParamsDeconstructor(POSTS_INIT_FILTER_DATA);
  const { franchisorFullAccess } = useAllowAccess();

  const topLevelFranchisorId = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorId);
  const isRallioai = useSelector((state: IStore) => state.accountSwitcher?.rallio_ai || false);
  const campaignPostIds = useSelector((state: IStore) => state.campaigns.postsList.map((it) => it?.saved_post_id || it?.content_id || 0));
  const isCopyPostFetching = useSelector((state: IStore) => state.posts.isCopyPostFetching);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);
  const { generalDocuments, ai_playbook } = useSelector((state: IStore) => state.aiPlayBook);
  const customerPersonaText = useSelector((state: IStore) =>
    userOwnership === USER_OWNERSHIP.ACCOUNT ? state.accounts.accountDetails?.account?.customer_persona_text : state.aiPlayBook.ai_playbook.customer_persona_text
  );
  const isHashtagsAndEmojis = useSelector((state: IStore) => state.aiContentCreator.isHashtagsAndEmojis);
  const location = useSelector((state: IStore) => state.accounts.accountDetails?.account?.city || '');
  const aiLimitation = useSelector((state: IStore) => state.aiContentCreator.aiLimitation);
  const aiSubscription = useSelector((state: IStore) => state.aiContentCreator.aiSubscription);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails?.account);
  const franchisorDetails = useSelector((state: IStore) => state.franchisors.franchisorDetails);

  const activeSubscription = aiSubscription.find((subscription: any) => subscription.account_id === id);
  const isAILimitation = activeSubscription?.account_id && +activeSubscription.franchisor_subscription_type_id === 7 && activeSubscription?.subscription_plan === ICSSubscriptionPlans.FREE;

  const routePush = (path: string, searchParams: { [key: string]: string } = {}) => {
    navigate({ pathname: path, search: searchParams ? `?${new URLSearchParams(searchParams).toString()}` : `` });
  };

  const handleCSSubscription = () => {
    dispatch(setAISubscriptionDetailsModal({ type: 'CSLocationAISubscription', isOpen: true }));
    dispatch(setCsLocationSubscriptionModalType('ai_caption'));
  };

  const handleAiClick = (postId?: number) => {
    if (!isAISubscribed) {
      if (!isCSLocation) {
        dispatch(setAISubscriptionDetailsModal({ type: 'trialPeriod', isOpen: true }));
      } else {
        handleCSSubscription();
      }
    } else if (Boolean(aiLimitation && +aiLimitation?.ai_caption_limit <= 0)) {
      handleCSSubscription();
    } else if (id && userOwnership && postId) {
      const customerPersonaFieldData = generateCustomerPersonaFieldData(customerPersonaText || '');
      dispatch(
        aiCreateSimilarPostRequest({
          postsDataId: postId,
          id,
          userOwnership,
          brand_name: ai_playbook.name,
          top_level_franchisor_id: topLevelFranchisorId,
          brandCategory: brandCategory.replace(VALID_BRAND_CATEGORY_REGEX, '').trim(),
          documentUrls: generalDocuments.map((it: any) => it.url) ?? [],
          // compliance_document_urls: complianceDocuments.map((it: any) => it.url) ?? [],
          compliance_summary: ai_playbook.ai_compliance_summary || '',
          // ai_compliance_description: ai_playbook.ai_compliance_description || '',
          isHashtags: isHashtagsAndEmojis?.isHashtags,
          isEmojis: isHashtagsAndEmojis?.isEmojis,
          location,
          websiteUrl: '',
          customer_persona_description: customerPersonaText || '',
          customer_persona_usage_level: SETTINGS_CUSTOMER_PERSONA_USAGE_LEVELS[0].value,
          customer_persona_name: customerPersonaFieldData.name,
          customer_persona_occupation: customerPersonaFieldData.occupation,
          customerAlias: ai_playbook.questions.customer_alias,
          businessSearch: ai_playbook.questions.business_search,
          rallio_ai: isRallioai && isAISubscribed,
          onNavigateToCreatorPage: (postData) => handleNavigateToCreatorPage(postData),
          preferred_language: userOwnership === USER_OWNERSHIP.ACCOUNT ? accountDetails?.preferred_language || '' : franchisorDetails?.preferred_language || ''
        })
      );
    }
  };

  const handleNavigateToCreatorPage = (postData: postsApiTypes.IPostDetailResponse) => {
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      handleGetContentPostData(
        {
          currentPostDetail: postData.content,
          postLinks: postData.links || [],
          postMedia: postData.videos?.length ? postData.videos || [] : postData.photos || []
        },
        true,
        POST_DETAIL_TYPE.AI_POST
      );
    } else {
      handleGetSavedPostData(
        {
          currentPostDetail: postData.saved_post
        },
        true,
        POST_DETAIL_TYPE.AI_POST
      );
      if (isAILimitation) dispatch(aiUpdateLimitationRequest({ account_id: id, type: 'ai_caption' }));
    }
  };

  const handleSwitchMainPostPage = (postId: number, detailTabName: string) => {
    routePush(
      `/${userOwnership}/${id.toString()}/${currentRoute}/${
        userOwnership === USER_OWNERSHIP.FRANCHISOR && queryParams?.status !== 'aa_location_content' ? `preview/${postId.toString()}/${detailTabName}` : `${postId.toString()}/${detailTabName}`
      }`,
      queryParams
    );
  };

  const handleAddPostsToCampaign = (postId: number) => {
    dispatch(setMultiplePostsToCampaign(postId));
  };

  const renderCampaignSelectButton = (isCampaignPostSelected: boolean, postId: number, isDisabled: boolean = false) => {
    return (
      <button className={`gnrc-btn white-gnr ac-btn${isDisabled ? ` button-opacity` : ''}`} onClick={() => (isDisabled ? false : handleAddPostsToCampaign(postId))}>
        <span>{isCampaignPostSelected ? (postId === hoverSelectedPostId ? 'Un-select' : 'Selected') : 'Select'}</span>
      </button>
    );
  };

  const handlePostsCopy = (postsCopyDetail: postsApiTypes.IPostsCopyReqParams) => {
    if (postsCopyDetail.contentId) {
      dispatch(copyPostsRequest({ contentId: postsCopyDetail.contentId }));
    } else {
      dispatch(copyPostsRequest({ savedPostId: postsCopyDetail.savedPostId }));
    }
  };

  const handleEditOrSchedulePost = (postDetail: postsApiTypes.IPostListSavedPostSummaries | postsApiTypes.IPostListContentPostSummaries) => {
    if (id && userOwnership) {
      setEditOrScheduleObj({ isModalOpen: false, isEdit: true, isAccountScheduleModalOpen: userOwnership === USER_OWNERSHIP.ACCOUNT && postDetail?.status_id !== 1 ? true : false });
      dispatch(
        getPostsRequest({
          postsDataId: userOwnership === USER_OWNERSHIP.ACCOUNT ? postDetail.saved_post_id : postDetail.content_id,
          id,
          userOwnership
        })
      );
    }
  };

  return (
    <div className="mi-hover postlist-view-tooltipwrp post-new-list--wrp">
      {type === SIDE_NAV_NAME.CONTENT_CAMPAIGNS
        ? null
        : isRallioai &&
          listItem.status_id === 3 &&
          !aiRestriction.button &&
          isAISubscribed && (
            <AICoachMark coachMarkDetails={AI_COACHMARK.find((it) => it.value === 'post')} isCoachMark={isCoachMark}>
              <div className="eai-wrap ai-tag" onClick={() => handleAiClick(postId)}>
                <CustomTooltip
                  customPlacement={TOOLTIP_PLACEMENT.TOP}
                  customTooltipText={POSTS_TOOLTIP_NAMES.AI_POST}
                  customClassname="r-post-content dv-tp"
                  customInput={() => (
                    <div className="ai-btn ai-creator-btn ai-anim-btn">
                      <ImageValidation isImgValid defaultImg={AI_ICON_BG_IMG.LIGHT_BLUE} isNotSvgFormat customName="AI" customClassname="aib" />
                    </div>
                  )}
                />
              </div>
            </AICoachMark>
          )}
      <div className="mih-expand">
        {type === SIDE_NAV_NAME.CONTENT_CAMPAIGNS ? (
          campaignPostIds.includes(postId) ? (
            <CustomTooltip
              customPlacement={TOOLTIP_PLACEMENT.TOP}
              customTooltipText={POSTS_TOOLTIP_NAMES.CAMP_POST_ALREADY_EXISTED}
              customClassname="r-post-content dv-tp"
              customInput={() => renderCampaignSelectButton(isCampaignPostSelected, postId, true)}
            />
          ) : (
            renderCampaignSelectButton(isCampaignPostSelected, postId)
          )
        ) : (
          <CustomTooltip
            customPlacement={TOOLTIP_PLACEMENT.TOP}
            customTooltipText={POSTS_TOOLTIP_NAMES.VIEW}
            customClassname="r-post-content dv-tp"
            customInput={() => (
              <span>
                <div className="post-expand-c">
                  <ImageValidation isImgValid defaultImg={'expand'} customName={'Expand'} onClick={() => postId && handleSwitchMainPostPage(postId, POSTS_DETAIL_TAB_NAMES.DETAILS)} />
                </div>
              </span>
            )}
          />
        )}
      </div>
      <div className="mi-cntrls">
        {type === SIDE_NAV_NAME.CONTENT_CAMPAIGNS ? null : userOwnership === USER_OWNERSHIP.FRANCHISOR ? (
          <>
            {listItem.link_url && (
              <div className="mic" onClick={() => handleLinkNavigation(listItem.link_url)}>
                <CustomTooltip
                  customPlacement={TOOLTIP_PLACEMENT.TOP}
                  customTooltipText={POSTS_TOOLTIP_NAMES.LINK}
                  customClassname="r-post-content"
                  customInput={() => (
                    <span>
                      <ImageValidation isImgValid defaultImg="link-white" customName="Link" />
                    </span>
                  )}
                />
              </div>
            )}
            {!postsRestriction.internal_note && (
              <div className="mic" onClick={() => postId && handleSwitchMainPostPage(postId, POSTS_DETAIL_TAB_NAMES.INTERNAL_NOTE)}>
                <CustomTooltip
                  customPlacement={TOOLTIP_PLACEMENT.TOP}
                  customClassname="r-post-content"
                  customTooltipText={
                    <span className="cr-txt">
                      {POSTS_TOOLTIP_NAMES.INTERNAL_NOTES} ({listItem.internal_comments_count || 0})
                    </span>
                  }
                  customInput={() => (
                    <span>
                      <ImageValidation isImgValid defaultImg="comment-white" customName="Internal Notes" />
                      <span className={listItem.internal_comments_count ? 'unread-item' : ''} />
                    </span>
                  )}
                />
              </div>
            )}
            {queryParams?.status !== POSTS_STATUS_TYPE.AA_LOCATION_CONTENT && !listItem?.disabled && !postsRestriction.copy && (
              <>
                <div className="mic" onClick={() => !isCopyPostFetching && handlePostsCopy({ contentId: listItem.content_id })}>
                  <CustomTooltip
                    customClassname="r-post-content"
                    customPlacement={TOOLTIP_PLACEMENT.TOP}
                    customTooltipText={POSTS_TOOLTIP_NAMES.COPY}
                    customInput={() => (
                      <span>
                        <ImageValidation isImgValid defaultImg="copy-white" customName="Copy" />
                      </span>
                    )}
                  />
                </div>
                {![1, 2, 5].includes(listItem.status_id) && canApprove(listItem, franchisorFullAccess) && !postsRestriction.schedule && !isContentSupplier && (
                  <div className="mic" onClick={() => handleEditOrSchedulePost(listItem)}>
                    <CustomTooltip
                      customClassname="r-post-content"
                      customPlacement={TOOLTIP_PLACEMENT.TOP}
                      customTooltipText={POSTS_TOOLTIP_NAMES.SCHEDULE}
                      customInput={() => (
                        <span>
                          <ImageValidation isImgValid defaultImg="time-white" customName="Time" />
                        </span>
                      )}
                    />
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {!postsRestriction.internal_note && (
              <div className="mic" onClick={() => postId && handleSwitchMainPostPage(postId, POSTS_DETAIL_TAB_NAMES.INTERNAL_NOTE)}>
                <CustomTooltip
                  customClassname="r-post-content"
                  customPlacement={TOOLTIP_PLACEMENT.TOP}
                  customTooltipText={
                    <span className="cr-txt">
                      {POSTS_TOOLTIP_NAMES.INTERNAL_NOTES} ({listItem.internal_comments_count || 0})
                    </span>
                  }
                  customInput={() => (
                    <span className="internal-notes">
                      <ImageValidation isImgValid defaultImg="comment-white" customName="Internal Notes" />
                      <span className={listItem.internal_comments_count ? 'unread-item' : ''} />
                    </span>
                  )}
                />
              </div>
            )}

            {!listItem?.rejected && !postsRestriction.copy && (
              <div className="mic" onClick={() => !isCopyPostFetching && handlePostsCopy({ savedPostId: listItem.saved_post_id })}>
                <CustomTooltip
                  customClassname="r-post-content"
                  customPlacement={TOOLTIP_PLACEMENT.TOP}
                  customTooltipText={POSTS_TOOLTIP_NAMES.COPY}
                  customInput={() => (
                    <span>
                      <ImageValidation isImgValid defaultImg="copy-white" customName="Copy" />
                    </span>
                  )}
                />
              </div>
            )}
            {listItem?.has_boost_offer && (
              <div className="mic" onClick={() => postId && handleSwitchMainPostPage(postId, POSTS_DETAIL_TAB_NAMES.BOOST_OFFER)}>
                <CustomTooltip
                  customClassname="r-post-content"
                  customPlacement={TOOLTIP_PLACEMENT.TOP}
                  customTooltipText={POSTS_TOOLTIP_NAMES.BOOST_OFFER}
                  customInput={() => (
                    <span>
                      {' '}
                      <ImageValidation isImgValid defaultImg="boost-white" customName="Boost" />
                    </span>
                  )}
                />
              </div>
            )}
            {listItem?.rejected && listItem?.status_id === 1 ? (
              <div className="mic" onClick={() => handleEditOrSchedulePost(listItem)}>
                <CustomTooltip
                  customClassname="r-post-content"
                  customPlacement={TOOLTIP_PLACEMENT.TOP}
                  customTooltipText={POSTS_TOOLTIP_NAMES.EDIT}
                  customInput={() => (
                    <span>
                      <ImageValidation isImgValid defaultImg="time-white" customName="Time" />
                    </span>
                  )}
                />
              </div>
            ) : listItem.status_id === 1 ? (
              <div className="mic" onClick={() => handleEditOrSchedulePost(listItem)}>
                <CustomTooltip
                  customClassname="r-post-content"
                  customPlacement={TOOLTIP_PLACEMENT.TOP}
                  customTooltipText={POSTS_TOOLTIP_NAMES.EDIT}
                  customInput={() => (
                    <span>
                      <ImageValidation isImgValid defaultImg="time-white" customName="Time" />
                    </span>
                  )}
                />
              </div>
            ) : ![2, 5].includes(listItem?.status_id) && atLeastApproved(listItem?.status_id) && !postsRestriction.schedule ? (
              canBeScheduled(listItem, userOwnership) ? (
                <div className="mic" onClick={() => handleEditOrSchedulePost(listItem)}>
                  <CustomTooltip
                    customClassname="r-post-content"
                    customPlacement={TOOLTIP_PLACEMENT.TOP}
                    customTooltipText={POSTS_TOOLTIP_NAMES.SCHEDULE}
                    customInput={() => (
                      <span>
                        <ImageValidation isImgValid defaultImg="time-white" customName="Time" />
                      </span>
                    )}
                  />
                </div>
              ) : (
                <div className="mic">
                  <CustomTooltip
                    customClassname="r-post-content"
                    customPlacement={TOOLTIP_PLACEMENT.TOP}
                    customTooltipText={POSTS_PACKAGE_EXPIRED_MSG}
                    customInput={() => (
                      <span>
                        <ImageValidation isImgValid defaultImg="time-white" customName="Time" />
                      </span>
                    )}
                  />
                </div>
              )
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};
