import { settingsAIPlayBookContainerTypes } from 'types/';

export const AI_PLAYBOOK_TAB_FIELDS: settingsAIPlayBookContainerTypes.IAIPlayBookTabFields = {
  BUSINESS_URL: 'business_url',
  NAME: 'name',
  BUSINESS_DESCRIPTION: 'business_description',
  VERTICAL_ID: 'vertical_id',
  QUESTIONS: 'questions',
  CUSTOMER_PERSONA_USAGE_LEVEL: 'customer_persona_usage_level',
  AI_COMPLIANCE_WORDS: 'ai_compliance_words',
  AI_COMPLIANCE_CATEGORIES: 'ai_compliance_categories',
  AI_COMPLIANCE_TONE_STYLES: 'ai_compliance_tone_styles',
  AI_COMPLIANCE_TERMINOLOGY_LANGUAGE: 'ai_compliance_terminology_language',
  AI_COMPLIANCE_FORMATTING_GUIDELINES: 'ai_compliance_formatting_guidelines',
  AI_COMPLIANCE_IMAGE_RESTRICTIONS: 'ai_compliance_image_restrictions',
  // AI_COMPLIANCE_DESCRIPTION: 'ai_compliance_description',
  AI_AUTO_GENERATE_POST: 'auto_generate_post',
  AI_COMPLIANCE_ADDITIONAL_FEEDBACK: 'ai_compliance_additional_feedback'
};

export const AI_PLAYBOOK_TAB_LABELS = {
  CUSTOMER_ALIAS: 'What do you refer to your customers as? (e.g., hotels call them guests, doctors call them patients, etc.)',
  BUSINESS_SEARCH: `What phrases or words would potential customers use to find your business? (e.g., "Best Mexican restaurant," "Children's haircut," etc.)`,
  BRAND_DESCRIPTION: 'What sets your business apart? Share any special details or facts that make your business unique.'
};

export const SETTINGS_AI_PLAYBOOK_CHECKFILEDS = {
  [AI_PLAYBOOK_TAB_FIELDS.BUSINESS_URL]: false,
  [AI_PLAYBOOK_TAB_FIELDS.NAME]: false,
  [AI_PLAYBOOK_TAB_FIELDS.VERTICAL_ID]: false,
  [AI_PLAYBOOK_TAB_FIELDS.BUSINESS_DESCRIPTION]: false,
  [AI_PLAYBOOK_TAB_FIELDS.CUSTOMER_PERSONA_USAGE_LEVEL]: false
};

export const SETTINGS_AI_PLAYBOOK_QUESTION_PARAMS = {
  CUSTOMER_ALIAS: 'customer_alias',
  BUSINESS_SEARCH: 'business_search'
};

export const AI_PLAYBOOK_ACTIVITY_GOALS_FREQUENCY_LABELS = {
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly'
};
export const AI_PLAYBOOK_ACTIVITY_GOALS_FREQUENCY_VALUES = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly'
};

export const SETTINGS_CUSTOMER_PERSONA_USAGE_LEVELS = [
  { label: 'Subtle', value: 'subtle' },
  { label: 'Default', value: 'balanced' },
  { label: 'Focused', value: 'focused' }
];

export const AI_PLAYBOOK_ACTIVITY_GOALS_FREQUENCY = [
  { label: AI_PLAYBOOK_ACTIVITY_GOALS_FREQUENCY_LABELS.WEEKLY, value: AI_PLAYBOOK_ACTIVITY_GOALS_FREQUENCY_VALUES.WEEKLY },
  { label: AI_PLAYBOOK_ACTIVITY_GOALS_FREQUENCY_LABELS.MONTHLY, value: AI_PLAYBOOK_ACTIVITY_GOALS_FREQUENCY_VALUES.MONTHLY }
];

export const AI_PLAYBOOK_ACTIVITY_GOALS_TAB_FIELDS: Record<string, keyof settingsAIPlayBookContainerTypes.IActivityGoalsInitialValues> = {
  ACTIVITY_NAME: 'activity_name',
  FREQUENCY: 'frequency', // value should on weekly and monthly
  GOAL_COUNT: 'goal_count',
  FRANCHISOR_ID: 'franchisor_id',
  ACTIVITY_GOAL_TYPE_ID: 'activity_goal_type_id',
  ACCOUNT_IDS: 'account_ids'
};

export const AI_PLAYBOOK_DOCS_TYPE = {
  GENERAL: 'general',
  COMPLIANCE: 'compliance'
};

export enum GenerateCaptionComplianceSteps {
  WORDS = 'words',
  CATEGORIES = 'categories',
  STYLES = 'styles',
  LANGUAGE = 'language',
  GUIDELINES = 'guidelines',
  RESULT = 'result'
}
export enum GenerateImageRestrictionSteps {
  STEP1 = 'image',
  STEP2 = 'result'
}

export const COMPLIANCE_MODEL_TYPE = {
  CAPTION: 'caption_compliance',
  IMAGE: 'image_compliance'
};

export enum ComplianceType {
  CAPTION = 'caption',
  IMAGE = 'image'
}

export const TEXT_COMPLIANCE_SEARCH = { words: '', categories: '', styles: '', language: '', guidelines: '', result: '' };

export const CONTENT_WARN_INIT_OBJ = { message: '', disableNavigation: false, showOnlyContinueBtn: false };

export const AI_PLAYBOOK_ACCORDION_ITEMS = {
  ZERO: 'business_info',
  ONE: 'general_docs',
  TWO: 'customer_persona',
  THREE: 'activity_goals',
  FOUR: 'text_compliance',
  FIVE: 'image_compliance',
  SIX: 'auto_generate_ai_posts'
};
export const AI_PLAYBOOK_ACCORDION_ITEMS_TITLE = {
  ZERO: 'Business Info',
  ONE: 'General Docs',
  TWO: 'Customer Persona',
  THREE: 'Activity Goals',
  FOUR: 'Text Compliance',
  FIVE: 'Image Compliance',
  SIX: 'Auto Generate AI Posts'
};
