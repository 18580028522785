import { takeEvery, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import * as types from 'actions/action-types';
import {
  loginResponse,
  loginRequestFailure,
  logoutResponse,
  exceptionHandlerRequest,
  userUploadCloudProfileImgResponse,
  userUploadCloudProfileImgFailure,
  getLoginUserDataResponse,
  getLoginUserDataFailure,
  logoutRequest,
  updateUserDetailResponse,
  updateUserDetailFailure,
  impersonateUserResponse,
  impersonateUserFailure,
  setAuthenticate,
  revvLoginRequest,
  oauthUserValidateResponse,
  oauthUserValidateFailure,
  getOauthSignOnTokensResponse,
  getOauthSignOnTokensFailure
} from 'actions';
import { apiCall, defaultHeader, defaultOAuthHeader, API, cloudinaryHeader, CLOUDINARY_API, OUTH_PUBLIC_API } from 'utils/helpers';
import {
  API_TYPES,
  LOGIN_EXCEPTION_ERROR_MSG,
  MG_UPLOAD_MEDIA_IN_CLOUDINARY_FAILED,
  GET_LOGIN_USER_DATA_FAILED,
  UPDATE_USER_DETAIL_FAILED,
  ALERT_BOX_TYPES,
  USER_OWNERSHIP,
  IMPERSONATE_USER_FAILED,
  INVALID_ACCOUNT_FOR_IMPERSONATED_USER_MSG,
  IMPERSONATE_RESPONSE_USER_KEY,
  SAVE_PREFERENCES_SUCCESS,
  SAVE_PREFERENCES_FAILURE
} from 'utils/constants';
import { config } from 'config';
import { store } from 'store';
import { commonApiTypes } from 'types';
import { alertBoxCall } from 'components';
import { logout } from 'revv/actions';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendLoginRequest(requestDetails: ISagaPayload): any {
  const headers = defaultHeader();
  const { type, responseUser, activeUser, ...payload } = requestDetails.payload;
  const data = payload;
  const response = yield apiCall({ headers, data, ...API.login });
  try {
    if (response.status === 200) {
      yield put(loginResponse(response.data));
    } else {
      yield put(loginRequestFailure(response.data.errors));
    }
  } catch (error) {
    const errorMsg = response.data && response.data.errors ? response.data.errors : LOGIN_EXCEPTION_ERROR_MSG;
    yield put(exceptionHandlerRequest(errorMsg));
  }
}

function* sendOauthUserValidateRequest(requestDetails: ISagaPayload): any {
  const headers = defaultOAuthHeader();
  const data = {
    user: {
      email: requestDetails.payload
    }
  };
  const response = yield apiCall({ headers, data, ...OUTH_PUBLIC_API.validateUser, type: API_TYPES.OAUTH_API });
  try {
    if (response.status === 200) {
      yield put(oauthUserValidateResponse(response.data));
    } else {
      // yield put(oauthUserValidateFailure(response.data.errors));
      yield put(oauthUserValidateFailure('Permission Denied. Please contact support@rallio.com'));
    }
  } catch (error) {
    const errorMsg = response.data && response.data.errors ? response.data.errors : LOGIN_EXCEPTION_ERROR_MSG;
    yield put(exceptionHandlerRequest(errorMsg));
  }
}

function* getOauthSignOnTokensRequest({ payload }: ISagaPayload): any {
  const headers = defaultOAuthHeader();
  const path = OUTH_PUBLIC_API.getUserSignOnToken.apiPath.replace(':userId', payload);
  const response = yield apiCall({ headers, apiPath: path, action: OUTH_PUBLIC_API.getUserSignOnToken.action, type: API_TYPES.OAUTH_API });
  try {
    if (response.status === 200) {
      yield put(getOauthSignOnTokensResponse(response.data));
    } else {
      yield put(getOauthSignOnTokensFailure(response.data.errors));
    }
  } catch (error) {
    const errorMsg = response.data && response.data.errors ? response.data.errors : LOGIN_EXCEPTION_ERROR_MSG;
    yield put(exceptionHandlerRequest(errorMsg));
  }
}

function* sendLogOutRequest() {
  const headers = defaultHeader();
  const path = API.logout.apiPath.replace('token', headers['X-Rallio-API-Key']);
  yield apiCall({ headers, apiPath: path, action: API.logout.action });
  const { encodedUserInfo } = store.getState().login;
  yield put(logoutResponse(encodedUserInfo));
}

function* sendUserUploadCloudProfileImgRequest({ payload }: ISagaPayload): any {
  const { file, signature, apiKey, timestamp, eager, uploadProgressCallback, successCallback } = payload;
  try {
    const resourceType = '/auto';
    const headers = cloudinaryHeader();
    const bodyFormData = new FormData();
    bodyFormData.append('api_key', apiKey);
    bodyFormData.append('file', file);
    bodyFormData.append('signature', signature);
    bodyFormData.append('timestamp', timestamp);
    bodyFormData.append('eager', eager);

    const url = `${config.cloudinary.baseUrl}${config.cloudinary.name}${resourceType}${CLOUDINARY_API.cloudinaryImageUpload.apiPath}`;

    const requestConfig: any = {
      url,
      method: CLOUDINARY_API.cloudinaryImageUpload.action,
      data: bodyFormData,
      headers,
      timeout: 0,
      validateStatus(status: number) {
        return status >= 200 && status <= 500;
      },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        if (uploadProgressCallback) {
          uploadProgressCallback(progressEvent);
        }
      }
    };
    const response = yield axios(requestConfig);
    if (successCallback) {
      successCallback(response.data);
    }
    if (response.status === 200) {
      yield put(
        userUploadCloudProfileImgResponse({
          cloudinaryId: response.data.public_id,
          photoUrl: response.data.secure_url
        })
      );
    } else {
      if (response.data?.error?.message) alertBoxCall(ALERT_BOX_TYPES.ERROR, response.data.error.message);
      else alertBoxCall(ALERT_BOX_TYPES.ERROR, MG_UPLOAD_MEDIA_IN_CLOUDINARY_FAILED);
      yield put(
        userUploadCloudProfileImgResponse({
          cloudinaryId: null,
          photoUrl: null
        })
      );
    }
  } catch (error) {
    yield put(userUploadCloudProfileImgFailure(MG_UPLOAD_MEDIA_IN_CLOUDINARY_FAILED));
  }
}

function* sendGetLoginUserDataRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.getUserDetail.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, apiPath: path, action: API.getUserDetail.action });
    if (response.status === 200) {
      if (!response.data.user?.revv_reference?.sso_token) {
        const data = {
          email: response.data.user?.email,
          rallio_access: 1,
          rallio_user_id: response.data.user?.id
        };
        yield put(revvLoginRequest(data));
      }
      yield put(getLoginUserDataResponse(response.data.user));
    } else {
      yield put(getLoginUserDataFailure(GET_LOGIN_USER_DATA_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(LOGIN_EXCEPTION_ERROR_MSG));
  }
}

function* sendUpdateUserDetailRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.updateUserDetail.apiPath.replace(':id', payload.userId);
    const { id, token, impersonated_user_id, impersonated_user_name, content_writers, franchisors_managed, accounts_managed, ...reqPayload } = payload.reqPayload;
    const data = {
      user: reqPayload
    };
    const response = yield apiCall({ headers, data, apiPath: path, action: Object.keys(reqPayload)?.length === 1 ? API.updateUserDetail.action : API.saveNotificationSettings.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (payload?.isNotificationSettings) alertBoxCall(ALERT_BOX_TYPES.SUCCESS, SAVE_PREFERENCES_SUCCESS);
      yield put(updateUserDetailResponse(response.data.user));
      yield put(logout());
    } else {
      if (payload?.isNotificationSettings) alertBoxCall(ALERT_BOX_TYPES.ERROR, SAVE_PREFERENCES_FAILURE);
      yield put(updateUserDetailFailure(UPDATE_USER_DETAIL_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(LOGIN_EXCEPTION_ERROR_MSG));
  }
}

function* sendImpersonateUserRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.impersonateUser.apiPath.replace(':id', payload.userId);
    const response = yield apiCall({ headers, apiPath: path, action: API.impersonateUser.action });
    if (response.status === 401) {
      const responseUser = store.getState().login.responseUser;
      localStorage?.setItem(IMPERSONATE_RESPONSE_USER_KEY, JSON.stringify(responseUser));
      if (response.data.errors) alertBoxCall(ALERT_BOX_TYPES.ERROR, response.data.errors);
      yield put(setAuthenticate(false));
      payload.onNavigate({ isLogout: true });
      localStorage?.removeItem('pathname');
      localStorage?.removeItem('search');
    } else if (response.status === 200) {
      if (!response.data.user?.revv_reference?.sso_token) {
        const data = {
          email: response.data.user?.email,
          rallio_access: 1,
          rallio_user_id: response.data.user?.id
        };
        yield put(revvLoginRequest(data));
      }
      yield put(impersonateUserResponse(response.data));
      if (response.data.user.admin) {
        payload.onNavigate({ isAdmin: response.data.user.admin });
      } else if (response.data.franchisor) {
        payload.onNavigate({ id: response.data.franchisor.id, userOwnership: USER_OWNERSHIP.FRANCHISOR });
      } else if (response.data.brand) {
        payload.onNavigate({ id: response.data.brand.id, userOwnership: USER_OWNERSHIP.FRANCHISOR });
      } else if (response.data.account) {
        payload.onNavigate({ id: response.data.account.id, userOwnership: USER_OWNERSHIP.ACCOUNT });
      } else {
        alertBoxCall(ALERT_BOX_TYPES.ERROR, INVALID_ACCOUNT_FOR_IMPERSONATED_USER_MSG);
        yield put(logoutRequest());
      }
    } else {
      yield put(impersonateUserFailure(IMPERSONATE_USER_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(LOGIN_EXCEPTION_ERROR_MSG));
  }
}

export function* takeLoginRequest() {
  yield takeEvery(types.LOGIN_REQUEST, sendLoginRequest);
  yield takeEvery(types.LOGOUT_REQUEST, sendLogOutRequest);
  yield takeLatest(types.OAUTH_USER_VALIDATE_REQUEST, sendOauthUserValidateRequest);
  yield takeLatest(types.GET_OAUTH_SIGN_ON_TOKENS_REQUEST, getOauthSignOnTokensRequest);
  yield takeEvery(types.USER_CLOUD_PROFILE_IMG_UPLOAD_REQUEST, sendUserUploadCloudProfileImgRequest);
  yield takeEvery(types.GET_LOGIN_USER_DATA_REQUEST, sendGetLoginUserDataRequest);
  yield takeLatest(types.UPDATE_USER_DETAIL_REQUEST, sendUpdateUserDetailRequest);
  yield takeLatest(types.IMPERSONATE_USER_REQUEST, sendImpersonateUserRequest);
}
