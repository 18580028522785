import { useState } from 'react';

import { addPostMassUpdateForScheduledPostRequest, editPostDetailsRequest, updatePostDetailRequest } from 'actions';
import { postsContainerTypes } from 'types';
import {
  USER_OWNERSHIP,
  MONTH_DATE_FORMAT,
  AP_MEDIA_EXPIRATION,
  ICommonDateTypes,
  CALENDAR_YR_MN_DATE_FORMAT,
  DATEPICKER_DATE_ONLY_FORMAT,
  RIPPLE_COLOR,
  AddMediaExpirationValues
  // FranchisorSyndicationMediaType
} from 'utils/constants';
import { getFormattedDate, convertStringToDate, getUTCDateAndTimeForSelectedData, getCurrentDate } from 'utils/helpers';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { CustomDatePicker } from 'widgets/DatePicker';

export const PostDetailAvailability = ({ postsDetails, userOwnership, isContentSupplierFranchisor, editedPostDetails, dispatch }: postsContainerTypes.IPostDetailAvailabilityProps) => {
  const [mediaExpiration, setMediaExpiration] = useState<string>(postsDetails?.end_date ? AddMediaExpirationValues.EXPIRE : AddMediaExpirationValues.NEVER);

  const isSaveDisabled = mediaExpiration === AddMediaExpirationValues.EXPIRE ? (editedPostDetails?.start_date && editedPostDetails?.end_date ? false : true) : false;
  const handleExpireDate = (startDate: string | null, endDate: string | null) => {
    if (editedPostDetails) {
      dispatch(
        editPostDetailsRequest({
          ...editedPostDetails,
          start_date: startDate,
          end_date: endDate
        })
      );
    }
    // dispatch(setPostsSyndicateExpireDate({ start_date: startDate, end_date: endDate }));
  };

  const handleUpdatePostDetails = () => {
    if (editedPostDetails?.id) {
      dispatch(
        updatePostDetailRequest({
          contentId: editedPostDetails.id,
          // actionType: FranchisorSyndicationMediaType.CONTENT,
          reqBody: {
            start_date: editedPostDetails?.start_date,
            end_date: editedPostDetails?.end_date
          }
        })
      );
      dispatch(addPostMassUpdateForScheduledPostRequest(editedPostDetails?.id));
    }
  };

  return postsDetails ? (
    <div className="st-tc-item pd-fields pd-items pdi-availability">
      <div className="wb-top post__white--box bradius-none">
        <div className="pd-field-group">
          <div className="wb-label">
            <div className="wbl-head">Availability</div>
          </div>
          <div className="wb-f-main">
            <div className="wb-fields">
              <div className="wb-fields bExp smExpiration pa-se-datesWrap">
                <div className="wbf-item">
                  {/* <div className="wbf-label-main">
                      <span className="wbf-label">Availability</span>
                    </div> */}
                  {userOwnership === USER_OWNERSHIP.ACCOUNT ||
                  postsDetails?.account?.id ||
                  (isContentSupplierFranchisor && postsDetails?.content_supplier?.is_content_supplier) ||
                  (postsDetails?.status_id === 2 && postsDetails?.account?.name) ||
                  editedPostDetails?.content_origins ? (
                    <>
                      {postsDetails.start_date && postsDetails.end_date ? (
                        <>
                          {postsDetails.start_date === postsDetails.end_date ? (
                            <span className="wbfi-txt">{getFormattedDate(postsDetails.end_date, MONTH_DATE_FORMAT)}</span>
                          ) : (
                            <div className="pa-se-mDates">
                              <span className="wbfi-txt">{getFormattedDate(postsDetails.start_date, MONTH_DATE_FORMAT)}</span>
                              <span className="sep-text">to</span>
                              <span className="wbfi-txt">{getFormattedDate(postsDetails.end_date, MONTH_DATE_FORMAT)}</span>
                            </div>
                          )}
                        </>
                      ) : (
                        <span className="wbfi-txt">Always Available</span>
                      )}
                    </>
                  ) : (
                    <div className={`wbf-inputs ${editedPostDetails?.content_origins && editedPostDetails?.locked ? 'pointer-events-none' : ''}`}>
                      <div className="input-fields">
                        <div className="if-main if-h avail-radio__group">
                          {AP_MEDIA_EXPIRATION.map((item, idx: number) => {
                            return (
                              <label className={`${mediaExpiration === item.name ? `active` : `in-active`} ${AP_MEDIA_EXPIRATION.length - 1 === idx ? `r-ml0` : ``}`} key={`mg-media-avail-${idx}`}>
                                <input
                                  type="radio"
                                  className="option-input radio"
                                  value={item.name}
                                  checked={mediaExpiration === item.name}
                                  onChange={(event) => {
                                    setMediaExpiration(event.target.value);
                                    if (event.target.value === AddMediaExpirationValues.NEVER) {
                                      handleExpireDate(null, null);
                                    }
                                  }}
                                />
                                <span className="labelText">{item.label}</span>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                      <div className="mExpBase">
                        {mediaExpiration === AddMediaExpirationValues.EXPIRE ? (
                          <>
                            {mediaExpiration === AddMediaExpirationValues.EXPIRE ? (
                              <div className="sc-cdtWrap">
                                <div className="wdt-item da-date">
                                  <div className={`dp-item dp-from`}>
                                    <CustomDatePicker
                                      selectedDate={convertStringToDate((editedPostDetails?.start_date || postsDetails?.start_date) ?? null)}
                                      dateRangeType={ICommonDateTypes.FROM_DATE}
                                      onChange={(date) => {
                                        handleExpireDate(getFormattedDate(getUTCDateAndTimeForSelectedData(date), CALENDAR_YR_MN_DATE_FORMAT), editedPostDetails?.end_date || postsDetails?.end_date);
                                      }}
                                      customClassname={`fltr-date-item fltr-from-date`}
                                      minDate={getCurrentDate()}
                                      autoComplete="off"
                                      placeholderText={''}
                                      dateFormat={DATEPICKER_DATE_ONLY_FORMAT}
                                    />
                                    <span className="fltlabels start">Start Date</span>
                                  </div>
                                  <div className={`dp-item dp-to`}>
                                    <CustomDatePicker
                                      selectedDate={convertStringToDate((editedPostDetails?.end_date || postsDetails?.end_date) ?? null)}
                                      dateRangeType={ICommonDateTypes.TO_DATE}
                                      onChange={(date) => {
                                        handleExpireDate(
                                          editedPostDetails?.start_date || postsDetails?.start_date,
                                          getFormattedDate(getUTCDateAndTimeForSelectedData(date), CALENDAR_YR_MN_DATE_FORMAT)
                                        );
                                      }}
                                      customClassname={`fltr-date-item fltr-from-date`}
                                      minDate={convertStringToDate(editedPostDetails?.start_date || postsDetails?.start_date) || null}
                                      autoComplete="off"
                                      placeholderText={''}
                                      dateFormat={DATEPICKER_DATE_ONLY_FORMAT}
                                    />
                                    <span className="fltlabels end">End Date</span>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </>
                        ) : null}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {postsDetails?.start_date !== editedPostDetails?.start_date || postsDetails?.end_date !== editedPostDetails?.end_date ? (
              <div className="btn-wrp">
                <div>
                  <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                    <button
                      className="ac-btn ac-secondary-white ac-outline size-xs border-0"
                      onClick={() => {
                        handleExpireDate(postsDetails?.start_date, postsDetails?.end_date);
                        setMediaExpiration(postsDetails?.end_date ? AddMediaExpirationValues.EXPIRE : AddMediaExpirationValues.NEVER);
                      }}
                    >
                      Cancel
                    </button>
                  </CustomRippleButton>
                  <CustomRippleButton rippleClass="ripple-unset ac-primary-box r-ml2" custColor={RIPPLE_COLOR.primary}>
                    <button className={`ac-btn ac-primary size-xs ${isSaveDisabled ? 'pointer-events-none' : ''}`} onClick={handleUpdatePostDetails}>
                      Save
                    </button>
                  </CustomRippleButton>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
